// couponActions.js

import {
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAILURE,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  EDIT_COUPON,
  EDIT_COUPON_SUCCESS,
  EDIT_COUPON_FAILURE,
  GET_ALL_COUPONS,
  GET_ALL_COUPONS_SUCCESS,
  GET_ALL_COUPONS_FAILURE,
  GET_COUPON,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAILURE,
  TOGGLE_COUPON,
  TOGGLE_COUPON_SUCCESS,
  TOGGLE_COUPON_FAILURE,
} from './actionTypes';

export const getAllCoupons = (payload) => ({
  type: GET_ALL_COUPONS,
  payload,
});

export const getAllCouponsSuccess = (payload) => ({
  type: GET_ALL_COUPONS_SUCCESS,
  payload,
});

export const getAllCouponsFailure = (error) => ({
  type: GET_ALL_COUPONS_FAILURE,
  payload: error,
});

export const addCoupon = (payload) => ({
  type: ADD_COUPON,
  payload,
});

export const addCouponSuccess = (payload) => ({
  type: ADD_COUPON_SUCCESS,
  payload,
});

export const addCouponFailure = (error) => ({
  type: ADD_COUPON_FAILURE,
  payload: error,
});

export const getCoupon = (payload) => ({
  type: GET_COUPON,
  payload,
});

export const getCouponSuccess = (payload) => ({
  type: GET_COUPON_SUCCESS,
  payload,
});

export const getCouponFailure = (error) => ({
  type: GET_COUPON_FAILURE,
  payload: error,
});

export const editCoupon = (payload) => ({
  type: EDIT_COUPON,
  payload,
});

export const editCouponSuccess = (payload) => ({
  type: EDIT_COUPON_SUCCESS,
  payload,
});

export const editCouponFailure = (error) => ({
  type: EDIT_COUPON_FAILURE,
  payload: error,
});

export const toggleCoupon = (payload) => ({
  type: TOGGLE_COUPON,
  payload,
});

export const toggleCouponSuccess = (payload) => ({
  type: TOGGLE_COUPON_SUCCESS,
  payload,
});

export const toggleCouponFailure = (error) => ({
  type: TOGGLE_COUPON_FAILURE,
  payload: error,
});

export const deleteCoupon = (payload) => ({
  type: DELETE_COUPON,
  payload,
});

export const deleteCouponSuccess = (payload) => ({
  type: DELETE_COUPON_SUCCESS,
  payload,
});

export const deleteCouponFailure = (error) => ({
  type: DELETE_COUPON_FAILURE,
  payload: error,
});
