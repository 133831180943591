import React, { useMemo, useState, forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown, Image, Row } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { deleteSubscription, getAllSubscriptions } from "store/actions";
import { checkPermissions } from "helpers/authentication/checkPermissions";
import corrcetImg from "../../assets/images/correct.png";
import wrongImg from "../../assets/images/wrong.png";
import ToggleSwitch from "components/shared/ToggleSwitch/ToggleSwitch";

const Table = (props) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEdit = (item) => {
    navigate(`/subscriptions/${item.id}?specialization=${item.specialization}`);
  };

  const handleDelete = (id) => {
    dispatch(deleteSubscription(id));
  };

  const handleToggle = () => {
    console.log("first-----------");
    if (props.checked === props?.specializations[0]?.id) {
      props.setChecked(props?.specializations[1]?.id);
      dispatch(
        getAllSubscriptions({
          params: { specialization: props?.specializations[1]?.id },
        })
      );
    } else {
      console.log("second-----------");
      props.setChecked(props?.specializations[0]?.id);
      dispatch(
        getAllSubscriptions({
          params: { specialization: props?.specializations[0]?.id },
        })
      );
    }
  };

  const columns = [
    {
      name: <FormattedMessage id={"plan"} />,
      selector: (row) => {
        const monthsMessage = row?.plan > 2 ? "months" : "month";

        return `${row?.plan} ${formatMessage({ id: monthsMessage })}`;
      },
      sortable: true,
    },
    {
      name: <FormattedMessage id={"price"} />,
      selector: (row) => `${row?.price} ${formatMessage({ id: "SAR" })}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"hasDiscount"} />,
      selector: (row) =>
        row?.hasDiscount === true ? (
          <Image src={corrcetImg} width={15} height={15} />
        ) : (
          <Image src={wrongImg} width={15} height={15} />
        ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"discount"} />,
      selector: (row) => `%${row?.discount}` || "-",
      sortable: true,
    },
    {
      name: <FormattedMessage id={"priceAfterDiscount"} />,
      selector: (row) =>
        `${row?.priceAfterDiscount || row?.price} ${formatMessage({
          id: "SAR",
        })}`,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          {checkPermissions([
            "subscriptions-update",
            "subscriptions-delete",
          ]) && (
            <div className="global-dropdown">
              <Dropdown>
                <Dropdown.Toggle>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-three-dots-vertical"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={(e) =>
                      navigate(`/coupons?subscriptions=${row?.id}`)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="#fff"
                      className="bi bi-pencil edit"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                    <FormattedMessage id={"coupons"} />
                  </Dropdown.Item>
                  {checkPermissions(["subscriptions-update"]) && (
                    <Dropdown.Item onClick={(e) => handleEdit(row)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="#fff"
                        className="bi bi-pencil edit"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                      </svg>
                      <FormattedMessage id={"edit"} />
                    </Dropdown.Item>
                  )}

                  {checkPermissions(["subscriptions-delete"]) && (
                    <Dropdown.Item onClick={(e) => handleDelete(row?.id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="#fff"
                        className="bi bi-trash delete"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path
                          fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                      <FormattedMessage id={"delete"} />
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  console.log("props.specializations", props?.specializations);
  console.log("props.checked", props?.checked);

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Row className="justify-content-between">
            <Col xl={6} md={12} xs={12}>
              <div className="card-head">
                <div>
                  <h4>
                    <FormattedMessage id={"subscriptionsTableShow"} />{" "}
                  </h4>
                  <p>
                    {" "}
                    <FormattedMessage id={"subscriptionsTableShowAll"} />
                  </p>
                </div>
              </div>
              {props?.specializations && (
                // <Col xl={4} md={12} xs={12}>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <div
                    className={`d-flex gap-1 align-items-center ${
                      props.checked === props?.specializations[0]?.id &&
                      "active_type"
                    }`}
                  >
                    <figure className="img_block__ plan_type_img"></figure>
                    <span className="user-type-name">
                      {props?.specializations[0]?.subTitle?.[locale]}
                    </span>
                  </div>
                  <div className="order_middle">
                    <ToggleSwitch
                      id={"specializaton"}
                      checked={props.checked === props?.specializations[0]?.id}
                      onChange={handleToggle}
                    />
                  </div>
                  <div
                    className={`d-flex gap-1 align-items-center order_first ${
                      props.checked === props?.specializations[1]?.id &&
                      "active_type"
                    }`}
                  >
                    <figure className="img_block__ plan_type_img">
                      {/* <Image width={20} height={30} src={props?.specializations[1]?.image} /> */}
                    </figure>
                    <span className="user-type-name">
                      {props?.specializations[1]?.subTitle?.[locale]}
                    </span>
                  </div>
                </div>
                // </Col>
              )}
            </Col>
          </Row>

          <div className="card-body mt-5">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
