import server from "api/server";
import React, { useState } from "react";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import removeImg from "../../assets/svgs/close.svg";
import { FormattedMessage } from "react-intl";

const ImageUpdate = ({ item, sections, setSections }) => {

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });


  const imageChange = async (e) => {

    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);

      const { data } = await server().post("/upload", formData);


      setSelectedImage({
        preview: e.target.files[0],
        path: data?.data?.files?.[0].path,
      });

      const newSections = sections.map((section) => {
        if (section.id !== item.id) return section;
        return {
          ...section,
          image: `${process.env.REACT_APP_URL}${data?.data?.files?.[0].path}`,
        };
      });

      setSections(() => newSections);

      e.target.value = null;
    }
  };

  const removeSelectedImage = (e) => {
    e.preventDefault();

    setSelectedImage("");
    const newSections = sections.map((section) => {
      if (section.id !== item.id) return section;
      return {
        ...section,
        image: ``,
      };
    });

    setSections(() => newSections);
  };

  return (
    <div className="form-group required">
      <h5> <FormattedMessage id={"image"} /></h5>

      <div className="image-uploader image-contain">
        <div className="img-pat">
          <img src={imageUpload} width={46} height={46} alt="" />
        </div>
        <label>
          {(selectedImage.preview || item?.image) && (
            <div>

              <img
                src={
                  selectedImage && selectedImage.preview
                    ? URL.createObjectURL(selectedImage.preview)
                    : item?.image
                }
                width={160}
                height={160}
                alt=""
              />
              <button className="removeImg" onClick={removeSelectedImage}>
                <img src={removeImg} alt="" />
              </button>
            </div>
          )}
          <input accept="image/*" type="file" onChange={imageChange} />
        </label>
      </div>
    </div>
  );
};

export default ImageUpdate;

