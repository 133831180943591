import server from './server';

export const getAllSubscriptionsApi = async (payload) => {
  console.log('payload', payload.params);
  const response = await server().get(`/subscriptions`, {
    params: { ...payload.params },
  });
  return response.data;
};

export const addSubscriptionApi = async (data) => {
  const response = await server().post('/subscriptions', data);

  return response.data;
};

export const getSubscriptionApi = async (id) => {
  const response = await server().get(`/subscriptions/${id}`);

  return response.data;
};

export const editSubscriptionApi = async ({ id, data }) => {
  const response = await server().put(`/subscriptions/${id}`, data);

  return response.data;
};

export const deleteSubscriptionApi = async (id) => {
  const response = await server().delete(`/subscriptions/${id}`);

  return response.data;
};
