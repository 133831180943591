import {
  GET_ALL_USERS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from "./actionTypes";


export const getAllUsers = (payload) => {
  return {
    type: GET_ALL_USERS,
    payload: payload,
  };
}
export const getAllUsersSuccess = (payload) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: payload,
  };
}

export const getAllUsersFailure = (error) => {
  return {
    type: GET_ALL_USERS_FAILURE,
    payload: error,
  };
}

// add user 
export const addUser = (payload) => {
  return {
    type: ADD_USER,
    payload: payload
  }
}

export const addUserSuccess = (payload) => {
  return {
    type: ADD_USER_SUCCESS,
    payload: payload
  }
}

export const addUserFailure = (error) => {
  return {
    type: ADD_USER_FAILURE,
    payload: error
  }
}

// get user 
export const getUser = (payload) => {
  return {
    type: GET_USER,
    payload: payload
  }
}

export const getUserSuccess = (payload) => {
  return {
    type: GET_USER_SUCCESS,
    payload: payload
  }
}

export const getUserFailure = (error) => {
  return {
    type: GET_USER_FAILURE,
    payload: error
  }
}

// edit user 
export const editUser = (payload) => {
  return {
    type: EDIT_USER,
    payload: payload
  }
}

export const editUserSuccess = (payload) => {
  return {
    type: EDIT_USER_SUCCESS,
    payload: payload
  }
}

export const editUserFailure = (error) => {
  return {
    type: EDIT_USER_FAILURE,
    payload: error
  }
}

// delete user
export const deleteUser = (payload) => {
  return {
    type: DELETE_USER,
    payload: payload
  }
}

export const deleteUserSuccess = (payload) => {

  return {
    type: DELETE_USER_SUCCESS,
    payload: payload
  }
}

export const deleteUserFailure = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: error
  }
} 