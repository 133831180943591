import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_BLOG,
  DELETE_BLOG,
  DELETE_BLOG_COMMENT,
  EDIT_BLOG,
  GET_ALL_BLOGS,
  GET_BLOG,
} from "./actionTypes";
import {
  addBlogApi,
  getAllBlogsApi,
  getBlogApi,
  editBlogApi,
  deleteBlogApi,
  deleteBlogCommentApi,
} from "api/blogs";
import {
  addBlogFailure,
  addBlogSuccess,
  deleteBlogCommentSuccess,
  deleteBlogFailure,
  deleteBlogSuccess,
  editBlogFailure,
  editBlogSuccess,
  getAllBlogsFailure,
  getAllBlogsSuccess,
  getBlogFailure,
  getBlogSuccess,
} from "./actions";

function* getAllBlogs({ payload }) {
  try {
    const { data } = yield call(getAllBlogsApi, payload);

    yield put(getAllBlogsSuccess(data));
  } catch (error) {
    yield put(getAllBlogsFailure(error?.response?.data || error || error));
  }
}

function* addBlog({ payload }) {
  try {
    const { data } = yield call(addBlogApi, payload.data);

    yield put(addBlogSuccess(data));

    payload.navigate("/blogs");
  } catch (error) {
    console.log("error", error);
    yield put(addBlogFailure(error?.response?.data || error || error));
  }
}

function* getBlog({ payload }) {
  try {
    const { data } = yield call(getBlogApi, payload);

    yield put(getBlogSuccess(data));
  } catch (error) {
    yield put(getBlogFailure(error?.response?.data || error || error));
  }
}

function* editBlog({ payload }) {
  try {
    const { data } = yield call(editBlogApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editBlogSuccess(data));
    payload.navigate("/blogs");
  } catch (error) {
    yield put(editBlogFailure(error?.response?.data || error || error));
  }
}

function* deleteBlog({ payload }) {
  try {
    yield call(deleteBlogApi, payload);
    yield put(deleteBlogSuccess(payload));
  } catch (error) {
    yield put(deleteBlogFailure(error?.response?.data || error || error));
  }
}

function* deleteBlogComment({ payload }) {
  try {
    yield call(deleteBlogCommentApi, payload);
    yield put(deleteBlogCommentSuccess(payload));
  } catch (error) {
    yield put(deleteBlogFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllBlogs() {
  yield takeEvery(GET_ALL_BLOGS, getAllBlogs);
}

export function* watchAddBlog() {
  yield takeEvery(ADD_BLOG, addBlog);
}

export function* watchGetBlog() {
  yield takeEvery(GET_BLOG, getBlog);
}

export function* watchEditBlog() {
  yield takeEvery(EDIT_BLOG, editBlog);
}

export function* watchDeleteBlog() {
  yield takeEvery(DELETE_BLOG, deleteBlog);
}

export function* watchDeleteBlogComment() {
  yield takeEvery(DELETE_BLOG_COMMENT, deleteBlogComment);
}

function* blogsSaga() {
  yield all([
    fork(watchGetAllBlogs),
    fork(watchAddBlog),
    fork(watchGetBlog),
    fork(watchEditBlog),
    fork(watchDeleteBlog),
    fork(watchDeleteBlogComment),
  ]);
}

export default blogsSaga;
