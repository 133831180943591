import FsLightbox from 'fslightbox-react'
import React, { useState } from 'react'

const LightBox = ({ imagesSrcs }) => {
  const [toggler, setToggler] = useState(false);

  const handleQuestionImageClick = () => {
    setToggler(prevToggler => {
      return !prevToggler;
    })
  }

  return (
    <>
      {imagesSrcs ?
        <>
          <div className='questions_images'>
            {imagesSrcs?.map((imgsrc, index) => {
              return <div key={`image_${index}`} className='col__' style={{width :'100%'}}>
                <div className="img_lazy_block_">

                  <img src={imgsrc} alt="question" onClick={handleQuestionImageClick} />
                </div>
              </div>
            })}

          </div>
          < FsLightbox
            toggler={toggler}
            sources={imagesSrcs}

          />
        </>
        : ""
      }

    </>
  )
}

export default LightBox