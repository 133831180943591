import ControlArea from 'components/Layout/ControlArea';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllCities } from 'store/actions';
import Icon from "assets/svgs/cities.svg";
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';

const Citys = () => {

  const { cities, loading, error } = useSelector((state) => state.cities);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false)

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllCities());
  }, [dispatch]);


  // error 
  useEffect(() => {

    if (error && isDispatched) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className="cities-wrap">
        {checkPermissions(["cities-store"]) && <ControlArea
          btnTxt={<FormattedMessage id={"addCity"} />}
          cardTxt={<FormattedMessage id={"cities"} />}
          icon={Icon}
          url="/cities/add"
        />}

        <Table data={cities} loading={loading} />
      </div>


    </>
  )
}

export default Citys

