import { addFaqApi } from 'api/faqs';
import AddButton from 'components/shared/AddButton';
import EditorUploadImage from 'components/shared/EditorUploadImage';
import { EditorComponent, Textarea } from 'components/shared/FormComponents';
import Loader from 'components/shared/Loader';
import SaveChangesButton from 'components/shared/SaveChangesButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addFaq, editFaq, getFaq } from 'store/actions';

const EditFaq = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, singleFaq } = useSelector((state) => state.faqs);
  const { id } = useParams();
  //   const [faq, setFaq] = useState();

  const [answerAr, setAnswerAr] = useState('');
  const [answerEn, setAnswerEn] = useState('');

  const questionEditors = [
    {
      id: 0,
      lang: 'ar',
      name: 'question.ar',
    },
    {
      id: 1,
      lang: 'en',
      name: 'question.en',
    },
  ];

  const answerEditors = [
    {
      id: 0,
      name: 'answer.ar',
      lang: 'ar',
      setData: setAnswerAr,
      initialValue: singleFaq?.answer?.ar || '',
    },
    {
      id: 1,
      name: 'answer.en',
      lang: 'en',
      setData: setAnswerEn,
      initialValue: singleFaq?.answer?.en || '',
    },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    // defaultValues: {
    //   "question.ar": singleFaq?.question?.ar,
    //   "question.en": singleFaq?.question?.en,
    // },
  });

  useEffect(() => {
    dispatch(getFaq(id));
  }, [dispatch]);

  useEffect(() => {
    setValue('question.ar', singleFaq?.question?.ar);
    setValue('question.en', singleFaq?.question?.en);
    setAnswerAr(singleFaq?.answer?.ar);
    setAnswerEn(singleFaq?.answer?.en);
  }, [singleFaq]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
      navigate('/faqs');
    }
  }, [error, formatMessage, locale, navigate]);

  const onSubmit = (data) => {
    console.log(data);
    const faqData = { ...data, answer: { ar: answerAr, en: answerEn } };
    dispatch(editFaq({ id, data: faqData, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <div className='acc-form'>
      <div className='card'>
        {/* Intro */}
        <div className='card-head'>
          <h4>
            <FormattedMessage id={'addFaq'} />
          </h4>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          {/* question */}
          <Row>
            {questionEditors.map(({ id, name, lang }) => (
              <Col sm={12} lg={6}>
                <div
                  className='edit_question_sec__ edit_question_editor_sec__'
                  key={id}
                >
                  <h3 className='edit_question_main_head_'>
                    <figure className='shape__'>
                      <svg
                        width='24'
                        height='25'
                        viewBox='0 0 24 25'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M17 19.1113H13L8.55 22.0713C8.39982 22.1714 8.22528 22.2289 8.045 22.2376C7.86472 22.2463 7.68546 22.2059 7.52635 22.1207C7.36724 22.0355 7.23424 21.9087 7.14154 21.7538C7.04884 21.5989 6.99992 21.4218 7 21.2413V19.1113C4 19.1113 2 17.1113 2 14.1113V8.11133C2 5.11133 4 3.11133 7 3.11133H17C20 3.11133 22 5.11133 22 8.11133V14.1113C22 17.1113 20 19.1113 17 19.1113Z'
                          stroke='#12C8CB'
                          stroke-width='1.5'
                          strokeMiterlimit='10'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M11.9998 12.042V11.832C11.9998 11.152 12.4198 10.792 12.8398 10.502C13.2498 10.222 13.6598 9.86199 13.6598 9.20199C13.6598 8.28199 12.9198 7.54199 11.9998 7.54199C11.0798 7.54199 10.3398 8.28199 10.3398 9.20199M11.9948 14.432H12.0048'
                          stroke='#12C8CB'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </figure>
                    <FormattedMessage id={'question'} /> (
                    <FormattedMessage
                      id={
                        lang === 'ar' ? 'arabic-language' : 'english-language'
                      }
                    />
                    )
                  </h3>

                  <div className='editor_wrapper__ '>
                    <h5 className='input_head_'>
                      <FormattedMessage id='questionText' />
                    </h5>

                    <Textarea
                      langId='question'
                      name={name}
                      register={register}
                      errors={errors}
                      showHeading={false}
                      lang={lang}
                    />
                  </div>
                </div>
              </Col>
            ))}

            {/* Answer */}
            {answerEditors.map(({ id, name, lang, setData, initialValue }) => (
              <Col sm={12} lg={6}>
                <div
                  className='edit_question_sec__ edit_question_editor_sec__'
                  key={id}
                >
                  <h5 className='edit_question_main_head_'>
                    <figure className='shape__'>
                      <svg
                        width='24'
                        height='25'
                        viewBox='0 0 24 25'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.5 19.6816H8C4 19.6816 2 18.6816 2 13.6816V8.68164C2 4.68164 4 2.68164 8 2.68164H16C20 2.68164 22 4.68164 22 8.68164V13.6816C22 17.6816 20 19.6816 16 19.6816H15.5C15.19 19.6816 14.89 19.8316 14.7 20.0816L13.2 22.0816C12.54 22.9616 11.46 22.9616 10.8 22.0816L9.3 20.0816C9.14 19.8616 8.77 19.6816 8.5 19.6816V19.6816Z'
                          stroke='#12C8CB'
                          stroke-width='1.5'
                          strokeMiterlimit='10'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M7 8.68164H17M7 13.6816H13'
                          stroke='#12C8CB'
                          strokeWidth='1.5'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </figure>
                    <FormattedMessage id={'answer'} />
                    (
                    <FormattedMessage
                      id={
                        lang === 'ar' ? 'arabic-language' : 'english-language'
                      }
                    />
                    )
                  </h5>

                  <div className='editor_wrapper__ '>
                    <h2 className='input_head_'>
                      <FormattedMessage id='answerText' />
                    </h2>
                    <Controller
                      control={control}
                      name={name}
                      render={({ field: { value } }) => (
                        <EditorComponent
                          name={name}
                          setData={setData}
                          lang={lang}
                          initialValue={initialValue}
                          editorHeight={350}
                        />
                      )}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {/* Edit Button */}

          <SaveChangesButton />
        </form>
      </div>
    </div>
  );
};

export default EditFaq;
