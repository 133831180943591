import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Import Api files
import {
  editMyProfileApi,
  getCurrentUser,
  postUserLogin,
} from '../../api/users';

// Login Redux States
import {
  CURRENT_USER,
  EDIT_MY_PROFILE,
  LOGIN_USER,
  LOGOUT_USER,
} from './actionTypes';
import {
  apiError,
  editMyProfileFailure,
  editMyProfileSuccess,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  loginSuccess,
  logoutUserSuccess,
} from './actions';
import Cookies from 'js-cookie';

function* loginUser({ payload }) {
  try {
    // const { data } = yield call(postUserLogin, payload.data);

    // const dashboardPermission = data?.user?.role?.permissions?.find((permission) => {
    //   return permission === "dashboard-access"
    // });

    // // if the user has  permission to access the dashboard
    // if (dashboardPermission) {
    //   localStorage.setItem("token", data.token);
    //   yield put(loginSuccess(data));

    // } else {
    //   yield put(apiError("Access Denied"));
    // }

    const { data } = yield call(postUserLogin, payload.data);

    yield put(loginSuccess(data));
    localStorage.setItem("super-token", data.token);
    // Cookies.set('super-token', data.token, {
    //   // domain: ".examist.sa",
    //   domain: process.env.REACT_APP_COOKIES_DOMAIN,
    //   expires: 365,
    // });
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    yield put(logoutUserSuccess());
    localStorage.removeItem('super-token');
    localStorage.removeItem('store');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* currentUser() {
  try {
    const { data } = yield call(getCurrentUser);

    yield put(getCurrentUserSuccess(data));
  } catch (error) {
    yield put(apiError(error));
    yield put(getCurrentUserFailure(error?.response?.data?.error || error));
  }
}

function* editMyProfile({ payload }) {
  try {
    const { data } = yield call(editMyProfileApi, payload);
    yield put(editMyProfileSuccess(data));
  } catch (error) {
    yield put(editMyProfileFailure(error?.response?.data || error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchCurrentUser() {
  yield takeEvery(CURRENT_USER, currentUser);
}
export function* watchEditMyProfile() {
  yield takeEvery(EDIT_MY_PROFILE, editMyProfile);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchCurrentUser),
    fork(watchEditMyProfile),
  ]);
}

export default authSaga;
