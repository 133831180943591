import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_MEDIA,
  DELETE_MEDIA,
  EDIT_MEDIA,
  GET_ALL_MEDIAS,
  GET_MEDIA,
} from "./actionTypes";
import {
  addMediaApi,
  getAllMediasApi,
  getMediaApi,
  editMediaApi,
  deleteMediaApi,
} from "api/medias";
import {
  addMediaFailure,
  addMediaSuccess,
  deleteMediaFailure,
  deleteMediaSuccess,
  editMediaFailure,
  editMediaSuccess,
  getAllMediasFailure,
  getAllMediasSuccess,
  getMediaFailure,
  getMediaSuccess,
} from "./actions";

function* getAllMedias() {
  try {
    const { data } = yield call(getAllMediasApi);
    yield put(getAllMediasSuccess(data));
  } catch (error) {
    yield put(getAllMediasFailure(error?.response?.data || error || error));
  }
}

function* addMedia({ payload }) {
  try {
    const { data } = yield call(addMediaApi, payload.data);

    yield put(addMediaSuccess(data));
    payload.navigate("/medias");
  } catch (error) {
    yield put(addMediaFailure(error?.response?.data || error || error));
  }
}

function* getMedia({ payload }) {
  try {
    const { data } = yield call(getMediaApi, payload);

    yield put(getMediaSuccess(data));
  } catch (error) {
    yield put(getMediaFailure(error?.response?.data || error || error));
  }
}

function* editMedia({ payload }) {
  try {
    const { data } = yield call(editMediaApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editMediaSuccess(data));
    payload.navigate("/medias");
  } catch (error) {
    yield put(editMediaFailure(error?.response?.data || error || error));
  }
}

function* deleteMedia({ payload }) {
  try {
    yield call(deleteMediaApi, payload);
    yield put(deleteMediaSuccess(payload));
  } catch (error) {
    yield put(deleteMediaFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllMedias() {
  yield takeEvery(GET_ALL_MEDIAS, getAllMedias);
}

export function* watchAddMedia() {
  yield takeEvery(ADD_MEDIA, addMedia);
}

export function* watchGetMedia() {
  yield takeEvery(GET_MEDIA, getMedia);
}

export function* watchEditMedia() {
  yield takeEvery(EDIT_MEDIA, editMedia);
}

export function* watchDeleteMedia() {
  yield takeEvery(DELETE_MEDIA, deleteMedia);
}

function* mediasSaga() {
  yield all([
    fork(watchGetAllMedias),
    fork(watchAddMedia),
    fork(watchGetMedia),
    fork(watchEditMedia),
    fork(watchDeleteMedia),
  ]);
}

export default mediasSaga;
