import { getId } from "helpers/functions";
import {
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_SUCCESS,
  EDIT_ANNOUNCEMENT,
  EDIT_ANNOUNCEMENT_FAILURE,
  EDIT_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT_FAILURE,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAILURE,
  GET_SINGLE_ANNOUNCEMENT,
  GET_SINGLE_ANNOUNCEMENT_SUCCESS,
  GET_SINGLE_ANNOUNCEMENT_FAILURE,
} from "./actionTypes";

const initialState = {
  announcements: [],
  loading: false,
  error: "",
  singleAnnouncement: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ANNOUNCEMENTS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        announcements: action.payload.announcements,
      };
      break;

    case GET_ANNOUNCEMENTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // -----------------------------------------
    case GET_SINGLE_ANNOUNCEMENT:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_SINGLE_ANNOUNCEMENT_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAnnouncement: action.payload.announcement,
      };
      break;

    case GET_SINGLE_ANNOUNCEMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // -----------------------------------------

    case ADD_ANNOUNCEMENT:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case ADD_ANNOUNCEMENT_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        announcements: action.payload.announcements,
      };
      break;

    case ADD_ANNOUNCEMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ------------------------------------

    case EDIT_ANNOUNCEMENT:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case EDIT_ANNOUNCEMENT_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAnnouncement: action.payload.announcement,
      };
      break;

    case EDIT_ANNOUNCEMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // --------------------------------------

    case DELETE_ANNOUNCEMENT:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case DELETE_ANNOUNCEMENT_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        announcements: state?.announcements?.filter(
          (e) => getId(e) !== action.payload
        ),
      };
      break;

    case DELETE_ANNOUNCEMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
