import server from "./server";

export const sendAlertEmailToUser = async (id) => {

  const response = await server().post(`/auth/userSubscriptions/SendAlertEmail/${id}`);

  return response.data;
}

export const sendCustomMessagesToUsers = async (data) => {

  const response = await server().post(`/auth/sendSpecializedEmail/`, data);

  return response.data;
}
