import React from 'react';
import imageUpload from 'assets/svgs/imageUpload.svg';
import server from 'api/server';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const EditorUploadImage = ({ setEditor, hide }) => {
  const { locale, formatMessage } = useIntl();
  const [selectedImage, setSelectedImage] = useState('');

  const imageChange = async (e) => {
    setSelectedImage('');

    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { data } = await server().post('/upload', formData);

      setSelectedImage(data.data.files[0].fullpath);
    }
  };
  const renderTooltip = (props) => (
    <Tooltip {...props}>
      <FormattedMessage id='copyUrl' />
    </Tooltip>
  );

  return (
    <div className='attatch_an_image_wraper__ '>
      <h5 className='input_head_'>
        <FormattedMessage id={'attatchAnImage'} />
      </h5>
      <div>
        <div className='image-uploader image-contain'>
          <div className='img-pat'>
            <img src={imageUpload} width={46} height={46} alt='' />
          </div>
          <label>
            <input accept='image/*' type='file' onChange={imageChange} />
          </label>
        </div>
          <div className={`url_input__ ${!selectedImage ? 'disabled__' : ''}`}>
            <input
              className='form-control form-outline'
              placeholder='image url'
              type='text'
              value={selectedImage}
            />

            <OverlayTrigger placement='bottom' overlay={renderTooltip}>
              <button
                className='copy_button__'
                onClick={(e) => {
                  e.preventDefault();
                  navigator?.clipboard?.writeText(
                    e.target
                      ?.closest('.url_input__')
                      ?.querySelector('.form-control').value
                  );

                  toast.success(formatMessage({ id: 'copied' }), {
                    position: locale === 'ar' ? 'bottom-left' : 'bottom-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={23}
                  height={23}
                  fill='currentColor'
                  viewBox='0 0 512 512'
                >
                  <path d='M224 0c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224zM64 160c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64H64V224h64V160H64z' />
                </svg>
              </button>
            </OverlayTrigger>
          </div>
      </div>
    </div>
  );
};

export default EditorUploadImage;
