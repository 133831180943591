import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAILURE,
  EDIT_MY_PROFILE,
  EDIT_MY_PROFILE_SUCCESS,
  EDIT_MY_PROFILE_FAILURE,
} from "./actionTypes";

const initialState = {
  user: {},
  isLoggedIn: null,
  loading: false,
  error: "",
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        user: action.payload,
        isLoggedIn: true,
      };
      break;
    case LOGOUT_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: {},
        error: "",
        isLoggedIn: false,
      };
      break;
    case CURRENT_USER:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case CURRENT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        user: action.payload.user,
        isLoggedIn: true,
      };
      break;

    case CURRENT_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        user: {},
        // isLoggedIn: false,
      };
      break;

    case EDIT_MY_PROFILE:
      state = {
        ...state,
        error: "",
        loading: true,
        user: {},
      };
      break;

    case EDIT_MY_PROFILE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        user: action.payload.user,
      };
      break;

    case EDIT_MY_PROFILE_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    case API_ERROR:
      const error = { ...(action.payload || {}) };
      let statusCode = error?.response?.status || error?.status,
        errorMessage = error?.response?.data?.errors?.[0]?.msg;

      switch (statusCode) {
        case 400:
          // Handle specific status code 400 (Bad Request) here
          break;
        case 401:
          localStorage.removeItem("token");
          if (!window?.location?.href?.includes("login"))
            window.location.href = "/login";
          errorMessage = "Your credentials has expired";

          // Handle specific status code 401 (Unauthorized) here
          break;
        case 404:
          // Handle specific status code 404 (Not Found) here
          break;
        // Add more cases for other status codes as needed

        default:
          console.log("Request failed:", error);
          errorMessage = "Request failed due to a network or server error";
          if (!window?.location?.href?.includes("server"))
            window.location.href = "/server-down";
          state.isLoggedIn = false;

        // Handle other status codes here
      }

      state = { ...state, error: errorMessage, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default authentication;
