import React from "react";
import removeImg from "assets/svgs/close.svg";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const StatisticsArray = ({ fields, append, remove, register, errors }) => {

  return (
    <div className="form-group branches features">
      <h5>
        <FormattedMessage id={"features"} />
        <button
          type="button"
          className="btn btn-green"
          onClick={() => {
            append({});
          }}
        >
          <FormattedMessage id={"addFeature"} />
        </button>
      </h5>
      <Row>
        {fields.map((item, index) => {

          return (
            <Col xl={6} lg={6} md={6} xs={12} key={index}>
              <div key={item?.id} className="add-multi-component">
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="removeImg"
                >
                  <img src={removeImg} alt="" />
                </button>
                <Row>

                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5><FormattedMessage id={"name"} /> ( <FormattedMessage id={"arabic-language"} /> )</h5>
                      <input
                        type="text"
                        className="form-control form-outline"

                        {...register(`features.${index}.name.ar`, {
                          required: true,
                        })}
                      />
                      <p className="error-hint">

                        {errors?.features?.[index]?.["name"]?.ar?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5><FormattedMessage id={"name"} /> ( <FormattedMessage id={"english-language"} /> )</h5>
                      <input
                        type="text"
                        className="form-control form-outline"

                        {...register(`features.${index}.name.en`, {
                          required: true,
                        })}
                      />
                      <p className="error-hint">
                        {errors?.features?.[index]?.["name"]?.en?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default StatisticsArray;
