import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { getUser } from "store/actions";
import Loader from "components/shared/Loader";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import NotFound from "views/NotFound";
import Sessions from "./Sessions"
import Subscriptions from "./Subscriptions"

const ShowUser = () => {
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { singleUser, loading, error } = useSelector((state) => state.users);

  // effects
  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  // error 
  useEffect(() => {

    if (error && error?.errors) {

      error?.errors.forEach(error => {
        toast.error(error?.msg, {
          position: locale === "ar" ? "bottom-left" : "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    }
  }, [error, locale, navigate]);


  const renderMainUserInfo = () => {
    return <div className="card">
      <div className="card-body user-details">
        <Row>
          {singleUser?.fullName &&
            <Col lg={4} md={6} sm={12} className="m-b-15" >
              <div className="content__block__">
                <h5 className="content_block_head__"> <FormattedMessage id={"name"} /> : </h5>
                <p className="content_block_details__">{singleUser?.fullName}</p>
              </div>
            </Col>
          }

          {singleUser?.city?.name &&
            <Col lg={4} md={6} sm={12} className="m-b-15" >
              <div className="content__block__">
                <h5 className="content_block_head__"> <FormattedMessage id={"city"} /> : </h5>
                <p className="content_block_details__">{singleUser?.city?.name?.[locale]}</p>
              </div>
            </Col>
          }
          {
            <Col lg={4} md={6} sm={12} className="m-b-15" >
              <div className="content__block__">
                <h5 className="content_block_head__"> <FormattedMessage id={"university"} /> : </h5>
                <p className="content_block_details__">{singleUser?.university?.name || singleUser?.otherUnveristy}</p>
              </div>
            </Col>
          }

          {singleUser?.phone &&
            <Col lg={4} md={6} sm={12} className="m-b-15" >
              <div className="content__block__">
                <h5 className="content_block_head__"> <FormattedMessage id={"phone"} /> : </h5>
                <p className="content_block_details__">{singleUser?.phone}</p>
              </div>
            </Col>
          }

          {singleUser?.email &&
            <Col lg={4} md={6} sm={12} className="m-b-15" >
              <div className="content__block__">
                <h5 className="content_block_head__"> <FormattedMessage id={"email"} /> : </h5>
                <p className="content_block_details__">{singleUser?.email}</p>
              </div>
            </Col>
          }

          <Col lg={4} md={6} sm={12} className="m-b-15" >
            <div className="content__block__">
              <h5 className="content_block_head__"> <FormattedMessage id={"accountType"} /> : </h5>
              <p className="content_block_details__">{singleUser?.type}</p>
            </div>
          </Col>


          <Col lg={4} md={6} sm={12} className="m-b-15" >
            <div className="content__block__">
              <h5 className="content_block_head__"> <FormattedMessage id={"totalAnswersResult"} /> : </h5>
              <p className="content_block_details__">{singleUser?.totalAnswersResult} </p>
            </div>
          </Col>


          <Col lg={4} md={6} sm={12} className="m-b-15" >
            <div className="content__block__">
              <h5 className="content_block_head__"> <FormattedMessage id={"correctAnswersResult"} /> : </h5>
              <p className="content_block_details__">{singleUser?.correctAnswersResult} </p>
            </div>
          </Col>

          {<Col lg={4} md={6} sm={12} className="m-b-15" >
            <div className="content__block__">
              <h5 className="content_block_head__"> <FormattedMessage id={"inCorrectAnswers"} /> : </h5>
              <p className="content_block_details__">{singleUser?.inCorrectAnswers} </p>
            </div>
          </Col>}

        </Row>
      </div>
    </div >
  }


  if (loading) return <Loader />;

  if (error) return <NotFound />

  return (
    <div className="acc-form">
      {renderMainUserInfo()}


      <div className="settings_tabs">

        <Tabs
          defaultActiveKey="sessions"
          id="home_page_tabs"
          className="home_page_tabs__"
        >
          <Tab eventKey="sessions" title={formatMessage({ id: "sessions" })}   >
            <Sessions data={singleUser?.sessions} title="sessions" />
          </Tab>
          <Tab eventKey="ArchiveSessions" title={formatMessage({ id: "ArchiveSessions" })}   >
            <Sessions data={singleUser?.archivedsessions} title="ArchiveSessions" />
          </Tab>
          <Tab eventKey="subscriptions" title={formatMessage({ id: "subscriptions" })}   >
            <Subscriptions data={singleUser?.usersubscriptions} />
          </Tab>
        </Tabs>
      </div>
      {/* sessions */}

      {/* archive sessions */}


    </div>
  );
};

export default ShowUser;
