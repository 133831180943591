import React from 'react';
import MailSettings from './MailSettings';

import { useIntl } from 'react-intl';
import { Tab, Tabs } from 'react-bootstrap';
import ReminderMessages from './ReminderMessages';
import CustomMessages from './CustomMessages';
const MessagesCenter = () => {

  const { formatMessage } = useIntl()



  return (
    <>
      <div className='acc-form settings_tabs'>

        <Tabs
          defaultActiveKey="reminderMessages"
          id="home_page_tabs"
          className="home_page_tabs__"
        >
          <Tab eventKey="reminderMessages" title={formatMessage({ id: "reminderMessages" })}   >
            <ReminderMessages />
          </Tab>
          <Tab eventKey="customMessages" title={formatMessage({ id: "customMessages" })}   >
            <CustomMessages />
          </Tab>
          <Tab eventKey="mailSettings" title={formatMessage({ id: "mailSettings" })}   >
            <MailSettings />
          </Tab>
        </Tabs>
      </div>

    </>
  )
}

export default MessagesCenter