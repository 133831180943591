import {
  ADD_BLOG,
  ADD_BLOG_FAILURE,
  ADD_BLOG_SUCCESS,
  DELETE_BLOG,
  DELETE_BLOG_FAILURE,
  DELETE_BLOG_SUCCESS,
  EDIT_BLOG,
  EDIT_BLOG_FAILURE,
  EDIT_BLOG_SUCCESS,
  GET_ALL_BLOGS,
  GET_ALL_BLOGS_FAILURE,
  GET_ALL_BLOGS_SUCCESS,
  GET_BLOG,
  GET_BLOG_FAILURE,
  GET_BLOG_SUCCESS,
  DELETE_BLOG_COMMENT,
  DELETE_BLOG_COMMENT_FAILURE,
  DELETE_BLOG_COMMENT_SUCCESS,
} from "./actionTypes";

// Action Creators for getting all blogs
export const getAllBlogs = (payload) => ({
  type: GET_ALL_BLOGS,
  payload: payload,
});

export const getAllBlogsSuccess = (payload) => ({
  type: GET_ALL_BLOGS_SUCCESS,
  payload: payload,
});

export const getAllBlogsFailure = (error) => ({
  type: GET_ALL_BLOGS_FAILURE,
  payload: error,
});

// Action Creators for adding a blog
export const addBlog = (payload) => ({
  type: ADD_BLOG,
  payload: payload,
});

export const addBlogSuccess = (payload) => ({
  type: ADD_BLOG_SUCCESS,
  payload: payload,
});

export const addBlogFailure = (error) => ({
  type: ADD_BLOG_FAILURE,
  payload: error,
});

// Action Creators for getting a single blog
export const getBlog = (payload) => ({
  type: GET_BLOG,
  payload: payload,
});

export const getBlogSuccess = (payload) => ({
  type: GET_BLOG_SUCCESS,
  payload: payload,
});

export const getBlogFailure = (error) => ({
  type: GET_BLOG_FAILURE,
  payload: error,
});

// Action Creators for editing a blog
export const editBlog = (payload) => ({
  type: EDIT_BLOG,
  payload: payload,
});

export const editBlogSuccess = (payload) => ({
  type: EDIT_BLOG_SUCCESS,
  payload: payload,
});

export const editBlogFailure = (error) => ({
  type: EDIT_BLOG_FAILURE,
  payload: error,
});

// Action Creators for deleting a blog
export const deleteBlog = (payload) => ({
  type: DELETE_BLOG,
  payload: payload,
});

export const deleteBlogSuccess = (payload) => ({
  type: DELETE_BLOG_SUCCESS,
  payload: payload,
});

export const deleteBlogFailure = (error) => ({
  type: DELETE_BLOG_FAILURE,
  payload: error,
});

// Action Creators for deleting a blog comment
export const deleteBlogComment = (payload) => ({
  type: DELETE_BLOG_COMMENT,
  payload: payload,
});

export const deleteBlogCommentSuccess = (payload) => ({
  type: DELETE_BLOG_COMMENT_SUCCESS,
  payload: payload,
});

export const deleteBlogCommentFailure = (error) => ({
  type: DELETE_BLOG_COMMENT_FAILURE,
  payload: error,
});
