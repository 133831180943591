export const GET_ALL_ETHICS = "GET_ALL_ETHICS";
export const GET_ALL_ETHICS_SUCCESS = "GET_ALL_ETHICS_SUCCESS";
export const GET_ALL_ETHICS_FAILURE = "GET_ALL_ETHICS_FAILURE";

export const GET_ETHIC = "GET_ETHIC";
export const GET_ETHIC_SUCCESS = "GET_ETHIC_SUCCESS";
export const GET_ETHIC_FAILURE = "GET_ETHIC_FAILURE";

export const ADD_ETHIC = "ADD_ETHIC";
export const ADD_ETHIC_SUCCESS = "ADD_ETHIC_SUCCESS";
export const ADD_ETHIC_FAILURE = "ADD_ETHIC_FAILURE";

export const EDIT_ETHIC = "EDIT_ETHIC";
export const EDIT_ETHIC_SUCCESS = "EDIT_ETHIC_SUCCESS";
export const EDIT_ETHIC_FAILURE = "EDIT_ETHIC_FAILURE";

export const DELETE_ETHIC = "DELETE_ETHIC";
export const DELETE_ETHIC_SUCCESS = "DELETE_ETHIC_SUCCESS";
export const DELETE_ETHIC_FAILURE = "DELETE_ETHIC_FAILURE";