import {
  ADD_PAGE, ADD_PAGE_FAILURE, ADD_PAGE_SUCCESS,
  DELETE_PAGE, DELETE_PAGE_FAILURE, DELETE_PAGE_SUCCESS,
  EDIT_PAGE, EDIT_PAGE_FAILURE, EDIT_PAGE_SUCCESS,
  GET_ALL_PAGES, GET_ALL_PAGES_FAILURE, GET_ALL_PAGES_SUCCESS,
  GET_PAGE, GET_PAGE_FAILURE, GET_PAGE_SUCCESS
} from "./actionTypes";


const initialState = {
  pages: [],
  loading: false,
  error: "",
  singlePage: {}
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all  pages  
    case GET_ALL_PAGES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_PAGES_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        pages: action.payload.pages,
        metadata: action.payload.metadata
      };
      break;

    case GET_ALL_PAGES_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add page  
    case ADD_PAGE:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_PAGE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singlePage: action.payload.page,
      };
      break;

    case ADD_PAGE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get page  
    case GET_PAGE:
      state = {
        ...state,
        error: "",
        loading: true,
        singlePage: {}
      };
      break;

    case GET_PAGE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singlePage: action.payload.page,
      };
      break;

    case GET_PAGE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit page  
    case EDIT_PAGE:
      state = {
        ...state,
        error: "",
        loading: true,
        singlePage: {}
      };
      break;

    case EDIT_PAGE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singlePage: action.payload.page,
      };
      break;

    case EDIT_PAGE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete page  
    case DELETE_PAGE:
      state = {
        ...state,
        error: "",
        loading: true,
        singlePage: {}
      };
      break;

    case DELETE_PAGE_SUCCESS:
      const pagesAfterDeleteing = [
        ...state?.pages?.filter((type) => type?.slug !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        pages: pagesAfterDeleteing,
      };
      break;

    case DELETE_PAGE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
