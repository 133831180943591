import {
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_SUCCESS,
  GET_ALL_SETTINGS_FAILURE,

  EDIT_MAIN_SETTINGS,
  EDIT_MAIN_SETTINGS_SUCCESS,
  EDIT_MAIN_SETTINGS_FAILURE,

  EDIT_SOCIALS_SETTINGS,
  EDIT_SOCIALS_SETTINGS_SUCCESS,
  EDIT_SOCIALS_SETTINGS_FAILURE,

  EDIT_MAIL_SETTINGS,
  EDIT_MAIL_SETTINGS_SUCCESS,
  EDIT_MAIL_SETTINGS_FAILURE,

} from "./actionTypes";


const initialState = {
  settings: [],
  loading: false,
  error: "",
  mainSettings: [],
  socialSettings: [],
  mailSettings: []
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all settings  
    case GET_ALL_SETTINGS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_SETTINGS_SUCCESS:

      state = {
        ...state,
        loading: false,
        settings: action.payload.settings,
        mainSettings: action.payload.mainSettings,
        socialSettings: action.payload.socialSettings,
        mailSettings: action.payload.mailSettings,
      };
      break;

    case GET_ALL_SETTINGS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;

    // edit MAIN Settings 
    case EDIT_MAIN_SETTINGS:
      state = { ...state, loading: true }

      break;

    case EDIT_MAIN_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        mainSettings: action.payload
      }
      break;

    case EDIT_MAIN_SETTINGS_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;

    // edit socials Settings 
    case EDIT_SOCIALS_SETTINGS:
      state = { ...state, loading: true }
      break;

    case EDIT_SOCIALS_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        socialSettings: action.payload
      }
      break;

    case EDIT_SOCIALS_SETTINGS_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;

    // edit socials Settings 
    case EDIT_MAIL_SETTINGS:
      state = { ...state, loading: true }
      break;

    case EDIT_MAIL_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        mailSettings: action.payload
      }
      break;

    case EDIT_MAIL_SETTINGS_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
