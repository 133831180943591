import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_UNIVERSITY,
  DELETE_UNIVERSITY,
  EDIT_UNIVERSITY,
  GET_ALL_UNIVERSITIES,
  GET_UNIVERSITY,
} from "./actionTypes";
import {
  addUniversityApi,
  getAllUniversitiesApi,
  getUniversityApi,
  editUniversityApi,
  deleteUniversityApi,
} from "api/universities";
import {
  addUniversityFailure,
  addUniversitySuccess,
  deleteUniversityFailure,
  deleteUniversitySuccess,
  editUniversityFailure,
  editUniversitySuccess,
  getAllUniversitiesFailure,
  getAllUniversitiesSuccess,
  getUniversityFailure,
  getUniversitySuccess,
} from "./actions";

function* getAllUniversities() {
  try {
    const { data } = yield call(getAllUniversitiesApi);

    yield put(getAllUniversitiesSuccess(data));
  } catch (error) {
    yield put(
      getAllUniversitiesFailure(error?.response?.data || error || error)
    );
  }
}

function* addUniversity({ payload }) {
  try {
    const { data } = yield call(addUniversityApi, payload.data);

    yield put(addUniversitySuccess(data));
    payload.navigate("/universities");
  } catch (error) {
    yield put(addUniversityFailure(error?.response?.data || error || error));
  }
}

function* getUniversity({ payload }) {
  try {
    const { data } = yield call(getUniversityApi, payload);

    yield put(getUniversitySuccess(data));
  } catch (error) {
    yield put(getUniversityFailure(error?.response?.data || error || error));
  }
}

function* editUniversity({ payload }) {
  try {
    const { data } = yield call(editUniversityApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editUniversitySuccess(data));
  } catch (error) {
    yield put(editUniversityFailure(error?.response?.data || error || error));
  }
}

function* deleteUniversity({ payload }) {
  try {
    yield call(deleteUniversityApi, payload);
    yield put(deleteUniversitySuccess(payload));
  } catch (error) {
    yield put(deleteUniversityFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllUniversities() {
  yield takeEvery(GET_ALL_UNIVERSITIES, getAllUniversities);
}

export function* watchAddUniversity() {
  yield takeEvery(ADD_UNIVERSITY, addUniversity);
}

export function* watchGetUniversity() {
  yield takeEvery(GET_UNIVERSITY, getUniversity);
}

export function* watchEditUniversity() {
  yield takeEvery(EDIT_UNIVERSITY, editUniversity);
}

export function* watchDeleteUniversity() {
  yield takeEvery(DELETE_UNIVERSITY, deleteUniversity);
}

function* universitiesSaga() {
  yield all([
    fork(watchGetAllUniversities),
    fork(watchAddUniversity),
    fork(watchGetUniversity),
    fork(watchEditUniversity),
    fork(watchDeleteUniversity),
  ]);
}

export default universitiesSaga;
