import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { editEthic, getAllCategories, getEthic } from "store/actions";
import Loader from "components/shared/Loader";
import { Input, Textarea } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import Select from "react-select";
import { useState } from "react";
import { getId } from "helpers/functions";

const EditEthic = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const { loading, error, singleEthic } = useSelector((state) => state.ethics);
  const { categories } = useSelector((state) => state.categories);
  const [subCategories, setSubCategories] = useState([]);
  const mainCategories = categories?.filter((category) => !category?.category);
  const [subCategoriesChildren, setSubCategoriesChildren] = useState();
  const [defaultSubCategoryVal, setDefaultSubCategoryVal] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    dispatch(getEthic(id));
    dispatch(getAllCategories(""));
  }, [dispatch, id]);

  useEffect(() => {
    let subCat = singleEthic?.category?.category?.category
      ? {
          id: getId(singleEthic?.category?.category),
          label: singleEthic?.category?.category?.name?.[locale],
        }
      : {
          id: getId(singleEthic?.category),
          label: singleEthic?.category?.name?.[locale],
        };
    setDefaultSubCategoryVal(subCat);

    reset({
      category: getId(singleEthic?.category),
      mainCategory: {
        value: singleEthic?.category?.category?.id,
        label: singleEthic?.category?.category?.name?.[locale],
      },
      description: singleEthic?.description,
      title: singleEthic?.title,
    });

    const subCategories = categories?.filter(
      (category) =>
        getId(category?.category) ===
        getId(
          singleEthic?.category?.category?.category ||
            singleEthic?.category?.category
        )
    );

    setSubCategories(subCategories);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, reset, singleEthic]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      navigate("/ethics");
    }
  }, [error, formatMessage, locale, navigate]);

  const handleSelectOptions = (options) => {
    if (options.length < 0) return [];

    return options.map((option) => {
      return {
        value: option?.id,
        label: option?.name?.[locale],
      };
    });
  };

  const onSubmit = (data) => {
    delete data.mainCategory;

    dispatch(editEthic({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"editEthic"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <Input
                      langId="title"
                      name="title"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} xs={12}>
                    <Textarea
                      langId="description"
                      name="description"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* mainCategory */}
                {
                  <Row>
                    <Col lg={12} xs={12}>
                      <div className="form-group ">
                        <h5>
                          {" "}
                          <FormattedMessage id={"mainCategory"} />
                        </h5>
                        <div>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              let options = handleSelectOptions(mainCategories);
                              return (
                                <Select
                                  inputRef={ref}
                                  name={name}
                                  placeholder={
                                    <FormattedMessage id={"mainCategory"} />
                                  }
                                  options={options}
                                  onChange={(selectedOption) => {
                                    setDefaultSubCategoryVal({
                                      value: "",
                                      label: "",
                                    });
                                    setSubCategories([]);

                                    const subCategories = categories?.filter(
                                      (category) =>
                                        category?.category?._id ===
                                        selectedOption?.value
                                    );

                                    setSubCategories(subCategories);
                                    onChange(selectedOption.value);
                                  }}
                                  isRtl={true}
                                  isSearchable={false}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  defaultValue={options?.find(
                                    (e) =>
                                      getId(e) ==
                                      getId(
                                        singleEthic?.category?.category
                                          ?.category ||
                                          singleEthic?.category?.category
                                      )
                                  )}
                                />
                              );
                            }}
                            name={"mainCategory"}
                            rules={{ required: true }}
                          />
                        </div>

                        <p className="error-hint">
                          {errors?.mainCategory && (
                            <FormattedMessage id={"requiredField"} />
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                }

                {/* sub category */}
                {typeof subCategories !== "undefined" &&
                  subCategories.length > 0 && (
                    <Row>
                      <Col lg={12} xs={12}>
                        <div className="form-group ">
                          <h5>
                            {" "}
                            <FormattedMessage id={"subCategory"} />{" "}
                          </h5>
                          <div>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <Select
                                  inputRef={ref}
                                  name={name}
                                  placeholder={
                                    <FormattedMessage id={"subCategory"} />
                                  }
                                  options={handleSelectOptions(subCategories)}
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption.value);
                                    // --------------------------------------------
                                    setSubCategoriesChildren(
                                      categories?.filter(
                                        (category) =>
                                          getId(category?.category) ===
                                          selectedOption?.value
                                      )
                                    );
                                    // ------------------------------------
                                    setDefaultSubCategoryVal(selectedOption);
                                  }}
                                  isRtl={true}
                                  isSearchable={false}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  value={defaultSubCategoryVal}
                                />
                              )}
                              name={"category"}
                              rules={{ required: true }}
                            />
                          </div>

                          <p className="error-hint">
                            {errors?.category && (
                              <FormattedMessage id={"requiredField"} />
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
                {/* {((typeof subCategoriesChildren !== "undefined" &&
                  subCategoriesChildren.length > 0) ||
                  singleEthic?.category?.category?.category) && (
                  <Row>
                    <Col lg={12} xs={12}>
                      <div className="form-group ">
                        <h5>
                          {" "}
                          <FormattedMessage id={"subCategory"} />{" "}
                        </h5>
                        <div>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => {
                              let options = handleSelectOptions(
                                subCategoriesChildren ||
                                  categories?.filter(
                                    (category) =>
                                      getId(category?.category) ===
                                      getId(singleEthic?.category?.category)
                                  )
                              );
                              return (
                                <Select
                                  inputRef={ref}
                                  name={name}
                                  placeholder={
                                    <FormattedMessage id={"subCategory"} />
                                  }
                                  options={options}
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption.value);

                                    setDefaultSubCategoryVal(selectedOption);
                                  }}
                                  isRtl={true}
                                  isSearchable={false}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  value={options?.find(
                                    (e) => getId(e) == value
                                  )}
                                />
                              );
                            }}
                            name={"category"}
                            rules={{ required: true }}
                          />
                        </div>

                        <p className="error-hint">
                          {errors?.category && (
                            <FormattedMessage id={"requiredField"} />
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                )} */}
                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEthic;
