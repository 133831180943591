import {
  ADD_CITY,
  ADD_CITY_FAILURE,
  ADD_CITY_SUCCESS,

  DELETE_CITY,
  DELETE_CITY_FAILURE,
  DELETE_CITY_SUCCESS,

  EDIT_CITY,
  EDIT_CITY_FAILURE,
  EDIT_CITY_SUCCESS,

  GET_ALL_CITIES,
  GET_ALL_CITIES_FAILURE,
  GET_ALL_CITIES_SUCCESS,

  GET_CITY,
  GET_CITY_FAILURE,
  GET_CITY_SUCCESS
} from "./actionTypes";


export const getAllCities = (payload) => {
  return {
    type: GET_ALL_CITIES,
    payload: payload,
  };
}
export const getAllCitiesSuccess = (payload) => {
  return {
    type: GET_ALL_CITIES_SUCCESS,
    payload: payload,
  };
}

export const getAllCitiesFailure = (error) => {
  return {
    type: GET_ALL_CITIES_FAILURE,
    payload: error,
  };
}

export const addCity = (payload) => {
  return {
    type: ADD_CITY,
    payload: payload,
  };
}
export const addCitySuccess = (payload) => {
  return {
    type: ADD_CITY_SUCCESS,
    payload: payload,
  };
}

export const addCityFailure = (error) => {
  return {
    type: ADD_CITY_FAILURE,
    payload: error,
  };
}

export const getCity = (payload) => {
  return {
    type: GET_CITY,
    payload: payload,
  };
}
export const getCitySuccess = (payload) => {
  return {
    type: GET_CITY_SUCCESS,
    payload: payload,
  };
}

export const getCityFailure = (error) => {
  return {
    type: GET_CITY_FAILURE,
    payload: error,
  };
}

export const editCity = (payload) => {
  return {
    type: EDIT_CITY,
    payload: payload,
  };
}
export const editCitySuccess = (payload) => {
  return {
    type: EDIT_CITY_SUCCESS,
    payload: payload,
  };
}

export const editCityFailure = (error) => {
  return {
    type: EDIT_CITY_FAILURE,
    payload: error,
  };
}

export const deleteCity = (payload) => {
  return {
    type: DELETE_CITY,
    payload: payload,
  };
}

export const deleteCitySuccess = (payload) => {
  return {
    type: DELETE_CITY_SUCCESS,
    payload: payload,
  };
}

export const deleteCityFailure = (error) => {
  return {
    type: DELETE_CITY_FAILURE,
    payload: error,
  };
}