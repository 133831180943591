import ControlArea from 'components/Layout/ControlArea';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCategories,
  getAllQuestions,
  getAllSpecializations,
} from 'store/actions';
import Icon from 'assets/svgs/questions.svg';
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import BootyPagination from 'components/shared/Pagination';
import { useSearchParams } from 'react-router-dom';
import CategoryFilter from './CategoryFilter';
import Loader from 'components/shared/Loader';

const Questions = () => {
  const { questions, metadata, loading, error } = useSelector(
    (state) => state.questions
  );
  const {
    specializations,
    loading: specializationsLoading,
    error: specializationsError,
  } = useSelector((state) => state.specializations);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  const [searchParams] = useSearchParams();
  const [checked, setChecked] = useState(null);

  const [filterText, setFilterText] = useState('');
  useEffect(() => {
    dispatch(getAllSpecializations());
  }, [dispatch]);

  const filteredItems = questions;
  //   ?.filter(
  //   (item) =>
  //     JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
  //     -1
  // );


  useEffect(() => {
    if (specializations && specializations.length > 0) {
      if (!checked) {
        setChecked(specializations[0]?.id);
        dispatch(getAllCategories({ specialization: specializations[0]?.id }));

        dispatch(
          getAllQuestions({
            searchParams,
            specialization: specializations[0]?.id,
          })
        );
      } else {
        dispatch(getAllCategories({ specialization: checked }));

        dispatch(getAllQuestions({ searchParams, specialization: checked }));
      }
      setIsDispatched(true);
    }
  }, [searchParams, checked, dispatch, specializations]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);

  // if (loading) {
  //   return <Loader />;
  // }
  return (
    <>
      <div className='questions-wrap'>
        {checkPermissions(['questions-store']) && (
          <ControlArea
            btnTxt={<FormattedMessage id={'addQuestion'} />}
            cardTxt={<FormattedMessage id={'questions'} />}
            icon={Icon}
            url='/questions/add'
          />
        )}

        <CategoryFilter
          specializations={specializations}
          checked={checked}
          setChecked={setChecked}
          // setSearchFilter={setFilterText}
          // searchText={filterText}
        />

        <Table data={filteredItems} loading={loading} />
        <BootyPagination metaData={metadata} />
        {loading && <div className='lds-dual-ring'></div>}
      </div>
    </>
  );
};

export default Questions;
