import {
  ADD_UNIVERSITY,
  ADD_UNIVERSITY_FAILURE,
  ADD_UNIVERSITY_SUCCESS,

  DELETE_UNIVERSITY,
  DELETE_UNIVERSITY_FAILURE,
  DELETE_UNIVERSITY_SUCCESS,

  EDIT_UNIVERSITY,
  EDIT_UNIVERSITY_FAILURE,
  EDIT_UNIVERSITY_SUCCESS,

  GET_ALL_UNIVERSITIES,
  GET_ALL_UNIVERSITIES_FAILURE,
  GET_ALL_UNIVERSITIES_SUCCESS,

  GET_UNIVERSITY,
  GET_UNIVERSITY_FAILURE,
  GET_UNIVERSITY_SUCCESS
} from "./actionTypes";


export const getAllUniversities = (payload) => {
  return {
    type: GET_ALL_UNIVERSITIES,
    payload: payload,
  };
}
export const getAllUniversitiesSuccess = (payload) => {
  return {
    type: GET_ALL_UNIVERSITIES_SUCCESS,
    payload: payload,
  };
}

export const getAllUniversitiesFailure = (error) => {
  return {
    type: GET_ALL_UNIVERSITIES_FAILURE,
    payload: error,
  };
}

export const addUniversity = (payload) => {
  return {
    type: ADD_UNIVERSITY,
    payload: payload,
  };
}
export const addUniversitySuccess = (payload) => {
  return {
    type: ADD_UNIVERSITY_SUCCESS,
    payload: payload,
  };
}

export const addUniversityFailure = (error) => {
  return {
    type: ADD_UNIVERSITY_FAILURE,
    payload: error,
  };
}

export const getUniversity = (payload) => {
  return {
    type: GET_UNIVERSITY,
    payload: payload,
  };
}
export const getUniversitySuccess = (payload) => {
  return {
    type: GET_UNIVERSITY_SUCCESS,
    payload: payload,
  };
}

export const getUniversityFailure = (error) => {
  return {
    type: GET_UNIVERSITY_FAILURE,
    payload: error,
  };
}

export const editUniversity = (payload) => {
  return {
    type: EDIT_UNIVERSITY,
    payload: payload,
  };
}
export const editUniversitySuccess = (payload) => {
  return {
    type: EDIT_UNIVERSITY_SUCCESS,
    payload: payload,
  };
}

export const editUniversityFailure = (error) => {
  return {
    type: EDIT_UNIVERSITY_FAILURE,
    payload: error,
  };
}

export const deleteUniversity = (payload) => {
  return {
    type: DELETE_UNIVERSITY,
    payload: payload,
  };
}

export const deleteUniversitySuccess = (payload) => {
  return {
    type: DELETE_UNIVERSITY_SUCCESS,
    payload: payload,
  };
}

export const deleteUniversityFailure = (error) => {
  return {
    type: DELETE_UNIVERSITY_FAILURE,
    payload: error,
  };
}