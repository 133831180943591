import {
  ADD_ROLE, ADD_ROLE_FAILURE, ADD_ROLE_SUCCESS,
  DELETE_ROLE, DELETE_ROLE_FAILURE, DELETE_ROLE_SUCCESS,
  EDIT_ROLE, EDIT_ROLE_FAILURE, EDIT_ROLE_SUCCESS,
  GET_ALL_ROLES, GET_ALL_ROLES_FAILURE, GET_ALL_ROLES_SUCCESS,
  GET_ROLE, GET_ROLE_FAILURE, GET_ROLE_SUCCESS
} from "./actionTypes";



const initialState = {
  roles: [],
  loading: false,
  error: "",
  singleRole: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all roles  
    case GET_ALL_ROLES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_ROLES_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        roles: action.payload.roles,
      };
      break;

    case GET_ALL_ROLES_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add role  
    case ADD_ROLE:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_ROLE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleRole: action.payload.role,
      };
      break;

    case ADD_ROLE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get role  
    case GET_ROLE:
      state = {
        ...state,
        error: "",
        loading: true,
        singleRole: {}
      };
      break;

    case GET_ROLE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleRole: action.payload.role,
      };
      break;

    case GET_ROLE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit role  
    case EDIT_ROLE:
      state = {
        ...state,
        error: "",
        loading: true,
        singleRole: {}
      };
      break;

    case EDIT_ROLE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleRole: action.payload.role,
      };
      break;

    case EDIT_ROLE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete role  
    case DELETE_ROLE:
      state = {
        ...state,
        error: "",
        loading: true,
        singleRole: {}
      };
      break;

    case DELETE_ROLE_SUCCESS:
      const rolesAfterDeleteing = [
        ...state?.roles?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        roles: rolesAfterDeleteing,
      };
      break;

    case DELETE_ROLE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
