import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { addCity } from "store/actions";
import Loader from "components/shared/Loader";
import AddButton from "components/shared/AddButton";
import { Input } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";



const AddCity = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.cities);



  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  // error 
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);

      navigate("/cities");

    }

  }, [error, formatMessage, locale, navigate]);


  const onSubmit = (data) => {

    dispatch(addCity({ data, navigate }));
  };



  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"addCity"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>

                {/* name  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="name" name="name.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="name" name="name.en" register={register} errors={errors} />
                  </Col>
                </Row>

                <AddButton />

              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCity;
