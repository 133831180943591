import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editMailSettings, getAllSettings } from "store/actions";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { toast } from "react-toastify";
import { useEffect } from "react";

const EmailSettings = ({ title }) => {
  const dispatch = useDispatch();
  const { loading, error, mailSettings } = useSelector(
    (state) => state.settings
  );
  const { locale } = useIntl();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(<FormattedMessage id={"sendingErrorMessage"} />, {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error]);

  // reset the settings value
  useEffect(() => {
    mailSettings?.forEach((setting) => {
      setValue(setting?.title, setting.value);
    });
  }, []);
  // mailSettings?.forEach(setting => {
  //   setValue(setting?.title, setting.value);
  // });

  const onSubmit = (data) => {
    let updatedMainSettings = [];

    for (const [key, value] of Object.entries(data)) {
      updatedMainSettings.push({
        title: key,
        value: value,
      });
    }

    dispatch(editMailSettings(updatedMainSettings));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form mail_settings__">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="mailSettings" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="add-multi-component">
                      <div className="form-group">
                        <h5>{mailSettings?.[0].name?.[locale]}</h5>
                        <div>
                          <textarea
                            className="form-control form-outline"
                            placeholder={mailSettings?.[0]?.name[locale]}
                            {...register(mailSettings?.[0]?.title, {
                              required: false,
                            })}
                          />
                        </div>
                        <p className="error-hint">
                          {errors?.mailSettings?.[0]?.title && (
                            <FormattedMessage id={"requiredField"} />
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="add-multi-component">
                      <div className="form-group">
                        <h5>{mailSettings?.[1]?.name?.[locale]}</h5>
                        <div>
                          <textarea
                            className="form-control form-outline"
                            placeholder={mailSettings?.[1]?.name[locale]}
                            {...register(mailSettings?.[1]?.title, {
                              required: false,
                            })}
                          />
                        </div>
                        <p className="error-hint">
                          {errors?.mailSettings?.[1]?.title && (
                            <FormattedMessage id={"requiredField"} />
                          )}
                        </p>
                      </div>
                      <div className="form-group">
                        <h5>{mailSettings?.[2]?.name?.[locale]}</h5>
                        <div>
                          <input
                            type="text"
                            className="form-control form-outline"
                            placeholder={mailSettings?.[2]?.name?.[locale]}
                            {...register(mailSettings?.[2]?.title, {
                              required: false,
                            })}
                          />
                        </div>
                        <p className="error-hint">
                          {errors?.mailSettings?.[2]?.title && (
                            <FormattedMessage id={"requiredField"} />
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"saveChanges"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSettings;
