import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { addPage } from "store/actions";
import Loader from "components/shared/Loader";
import PageContent from "./pageContent";

const AddPage = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.pages);
  const [description, setDescription] = useState("");


  const { register, handleSubmit, control, formState: { errors }, } = useForm();



  // error 
  useEffect(() => {

    if (error) {
      let errorMessage = formatMessage({ id: "sendingErrorMessage" });

      if (error?.statusCode === 422) {
        errorMessage = formatMessage({ id: "validationAddPageErrorMessage" });
      }

      toast.error(errorMessage, {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale]);


  const onSubmit = (data) => {
    data.content = {
      description: description,
      subTitle: data?.subTitle
    };

    delete data.description;
    delete data.subTitle;


    dispatch(addPage({ data, navigate }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"addPage"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>

                <PageContent Controller={Controller} control={control} setDescription={setDescription} register={register} errors={errors} />

                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"add"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPage;
