import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_ANNOUNCEMENTS,
  EDIT_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  GET_SINGLE_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT,
} from "./actionTypes";
import {
  getAnnouncementsSuccess,
  getAnnouncementsFailure,
  editAnnouncementSuccess,
  editAnnouncementFailure,
  deleteAnnouncementSuccess,
  deleteAnnouncementFailure,
  getSingleAnnouncementSuccess,
  getSingleAnnouncementFailure,
  addAnnouncementSuccess,
  addAnnouncementFailure,
} from "./actions";
import {
  addAnnouncementApi,
  deleteAnnouncementApi,
  editAnnouncementApi,
  getAnnouncementsApi,
  getSingleAnnouncementApi,
} from "api/announcement";

function* getAnnouncementsSaga() {
  try {
    const { data } = yield call(getAnnouncementsApi);

    yield put(getAnnouncementsSuccess(data));
  } catch (error) {
    yield put(getAnnouncementsFailure(error?.response?.data || error));
  }
}

// -----------------------------------

function* addAnnouncementSaga({ payload }) {
  try {
    const { data } = yield call(addAnnouncementApi, payload?.data);

    yield put(addAnnouncementSuccess(data));
    if (payload?.navigate) payload.navigate("/announcements");
  } catch (error) {
    yield put(addAnnouncementFailure(error?.response?.data || error));
  }
}

// -----------------------------------

function* getSingleAnnouncementSaga({ payload }) {
  try {
    const { data } = yield call(getSingleAnnouncementApi, payload);

    yield put(getSingleAnnouncementSuccess(data));
  } catch (error) {
    yield put(getSingleAnnouncementFailure(error?.response?.data || error));
  }
}

function* editAnnouncementSaga({ payload }) {
  try {
    const { data } = yield call(editAnnouncementApi, payload);

    yield put(editAnnouncementSuccess(data));

    if (payload?.navigate) yield payload?.navigate("/announcements");
  } catch (error) {
    yield put(editAnnouncementFailure(error?.response?.data || error));
  }
}

function* deleteAnnouncementSaga({ payload }) {
  try {
    yield call(deleteAnnouncementApi, payload);

    yield put(deleteAnnouncementSuccess(payload));
  } catch (error) {
    yield put(deleteAnnouncementFailure(error?.response?.data || error));
  }
}

export function* watchGetAnnouncements() {
  yield takeEvery(GET_ANNOUNCEMENTS, getAnnouncementsSaga);
}

export function* watchAddAnnouncement() {
  yield takeEvery(ADD_ANNOUNCEMENT, addAnnouncementSaga);
}

export function* watchGetSingleAnnouncement() {
  yield takeEvery(GET_SINGLE_ANNOUNCEMENT, getSingleAnnouncementSaga);
}

export function* watchEditAnnouncement() {
  yield takeEvery(EDIT_ANNOUNCEMENT, editAnnouncementSaga);
}

export function* watchDeleteAnnouncement() {
  yield takeEvery(DELETE_ANNOUNCEMENT, deleteAnnouncementSaga);
}

function* categoriesSaga() {
  yield all([fork(watchGetAnnouncements)]);
  yield all([fork(watchAddAnnouncement)]);
  yield all([fork(watchGetSingleAnnouncement)]);
  yield all([fork(watchEditAnnouncement)]);
  yield all([fork(watchDeleteAnnouncement)]);
}

export default categoriesSaga;
