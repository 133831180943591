import {
  ADD_REVIEW,
  ADD_REVIEW_FAILURE,
  ADD_REVIEW_SUCCESS,
  DELETE_REVIEW,
  DELETE_REVIEW_FAILURE,
  DELETE_REVIEW_SUCCESS,
  EDIT_REVIEW,
  EDIT_REVIEW_FAILURE,
  EDIT_REVIEW_SUCCESS,
  GET_ALL_REVIEWS,
  GET_ALL_REVIEWS_FAILURE,
  GET_ALL_REVIEWS_SUCCESS,
  GET_REVIEW,
  GET_REVIEW_FAILURE,
  GET_REVIEW_SUCCESS,
} from './actionTypes';

const initialState = {
  reviews: [],
  loading: false,
  error: '',
  singleReview: {},
};

const reviews = (state = initialState, action) => {
  switch (action.type) {
    // get all reviews
    case GET_ALL_REVIEWS:
      state = {
        ...state,
        error: '',
        loading: true,
      };
      break;

    case GET_ALL_REVIEWS_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        reviews: action.payload.reviews,
      };
      break;

    case GET_ALL_REVIEWS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // add review
    case ADD_REVIEW:
      state = {
        ...state,
        error: '',
        loading: true,
      };
      break;

    case ADD_REVIEW_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleReview: action.payload.review,
      };
      break;

    case ADD_REVIEW_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get review
    case GET_REVIEW:
      state = {
        ...state,
        error: '',
        loading: true,
        singleReview: {},
      };
      break;

    case GET_REVIEW_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleReview: action.payload.review,
      };
      break;

    case GET_REVIEW_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit review
    case EDIT_REVIEW:
      state = {
        ...state,
        error: '',
        loading: true,
        singleReview: {},
      };
      break;

    case EDIT_REVIEW_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleReview: action.payload.review,
      };
      break;

    case EDIT_REVIEW_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete review
    case DELETE_REVIEW:
      state = {
        ...state,
        error: '',
        loading: true,
        singleReview: {},
      };
      break;

    case DELETE_REVIEW_SUCCESS:
      const reviewsAfterDeleting = [
        ...state?.reviews?.filter((review) => review?.id !== action.payload),
      ];
      state = {
        ...state,
        error: '',
        loading: false,
        reviews: reviewsAfterDeleting,
      };
      break;

    case DELETE_REVIEW_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reviews;
