import BlocksTop from "components/Home/BlocksTop";
import React from "react";

const Dashboard = () => {
  return (
    <div>
      {/* الرئيسية */}
      <BlocksTop />
    </div>
  );
};

export default Dashboard;
