import {
  ADD_SPECIALIZATION,
  ADD_SPECIALIZATION_FAILURE,
  ADD_SPECIALIZATION_SUCCESS,
  DELETE_SPECIALIZATION,
  DELETE_SPECIALIZATION_FAILURE,
  DELETE_SPECIALIZATION_SUCCESS,
  EDIT_SPECIALIZATION,
  EDIT_SPECIALIZATION_FAILURE,
  EDIT_SPECIALIZATION_SUCCESS,
  GET_ALL_SPECIALIZATIONS,
  GET_ALL_SPECIALIZATIONS_FAILURE,
  GET_ALL_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATION,
  GET_SPECIALIZATION_FAILURE,
  GET_SPECIALIZATION_SUCCESS,
} from './actionTypes';

const initialState = {
  specializations: [],
  loading: false,
  error: '',
  singleSpecialization: {},
};

const specializations = (state = initialState, action) => {
  switch (action.type) {
    // get all specializations
    case GET_ALL_SPECIALIZATIONS:
      state = {
        ...state,
        error: '',
        loading: true,
      };
      break;

    case GET_ALL_SPECIALIZATIONS_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        specializations: action.payload.specializations,
      };
      break;

    case GET_ALL_SPECIALIZATIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // add specialization
    case ADD_SPECIALIZATION:
      state = {
        ...state,
        error: '',
        loading: true,
      };
      break;

    case ADD_SPECIALIZATION_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleSpecialization: action.payload.specialization,
      };
      break;

    case ADD_SPECIALIZATION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get specialization
    case GET_SPECIALIZATION:
      state = {
        ...state,
        error: '',
        loading: true,
        singleSpecialization: {},
      };
      break;

    case GET_SPECIALIZATION_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleSpecialization: action.payload.specialization,
      };
      break;

    case GET_SPECIALIZATION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit specialization
    case EDIT_SPECIALIZATION:
      state = {
        ...state,
        error: '',
        loading: true,
        singleSpecialization: {},
      };
      break;

    case EDIT_SPECIALIZATION_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleSpecialization: action.payload.specialization,
      };
      break;

    case EDIT_SPECIALIZATION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete specialization
    case DELETE_SPECIALIZATION:
      state = {
        ...state,
        error: '',
        loading: true,
        singleSpecialization: {},
      };
      break;

    case DELETE_SPECIALIZATION_SUCCESS:
      const specializationsAfterDeleting = [
        ...state?.specializations?.filter(
          (specialization) => specialization?.id !== action.payload
        ),
      ];
      state = {
        ...state,
        error: '',
        loading: false,
        specializations: specializationsAfterDeleting,
      };
      break;

    case DELETE_SPECIALIZATION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default specializations;
