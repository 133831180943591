import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_ROLE,
  DELETE_ROLE,
  EDIT_ROLE,
  GET_ALL_ROLES,
  GET_ROLE,
} from "./actionTypes";
import {
  addRoleApi,
  getAllRolesApi,
  getRoleApi,
  editRoleApi,
  deleteRoleApi,
} from "api/roles";
import {
  addRoleFailure,
  addRoleSuccess,
  deleteRoleFailure,
  deleteRoleSuccess,
  editRoleFailure,
  editRoleSuccess,
  getAllRolesFailure,
  getAllRolesSuccess,
  getRoleFailure,
  getRoleSuccess,
} from "./actions";

function* getAllRoles() {
  try {
    const { data } = yield call(getAllRolesApi);
    yield put(getAllRolesSuccess(data));
  } catch (error) {
    yield put(getAllRolesFailure(error?.response?.data || error || error));
  }
}

function* addRole({ payload }) {
  try {
    const { data } = yield call(addRoleApi, payload.data);

    yield put(addRoleSuccess(data));
    payload.navigate("/roles");
  } catch (error) {
    yield put(addRoleFailure(error?.response?.data || error || error));
  }
}

function* getRole({ payload }) {
  try {
    const { data } = yield call(getRoleApi, payload);

    yield put(getRoleSuccess(data));
  } catch (error) {
    yield put(getRoleFailure(error?.response?.data || error || error));
  }
}

function* editRole({ payload }) {
  try {
    const { data } = yield call(editRoleApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editRoleSuccess(data));
  } catch (error) {
    yield put(editRoleFailure(error?.response?.data || error || error));
  }
}

function* deleteRole({ payload }) {
  try {
    yield call(deleteRoleApi, payload);
    yield put(deleteRoleSuccess(payload));
  } catch (error) {
    yield put(deleteRoleFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllRoles() {
  yield takeEvery(GET_ALL_ROLES, getAllRoles);
}

export function* watchAddRole() {
  yield takeEvery(ADD_ROLE, addRole);
}

export function* watchGetRole() {
  yield takeEvery(GET_ROLE, getRole);
}

export function* watchEditRole() {
  yield takeEvery(EDIT_ROLE, editRole);
}

export function* watchDeleteRole() {
  yield takeEvery(DELETE_ROLE, deleteRole);
}

function* rolesSaga() {
  yield all([
    fork(watchGetAllRoles),
    fork(watchAddRole),
    fork(watchGetRole),
    fork(watchEditRole),
    fork(watchDeleteRole),
  ]);
}

export default rolesSaga;
