import { useEffect, useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from 'store/actions';
import Select from 'react-select';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getId, handleSearchParamsChange } from 'helpers/functions';
import Filter from 'components/shared/Filter';
import filterImg from '../../assets/images/filter.png';

let timeoutHolder = null;

const CategoryFilter = ({
  specializations,
  setChecked,
  checked,
  // setSearchFilter, searchText
}) => {
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { categories } = useSelector((state) => state.categories);
  const [childrenSubCategories, setChildrenSubCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [searchText, setSearchFilter] = useState('');

  const mainCategories = [
    {
      _id: 'all',
      id: 'all',
      name: {
        ar: 'الكل',
        en: 'All',
      },
    },
    ...categories?.filter((category) => !category?.category),
  ];
  const [defaultSpecialization, setDefaultSpecialization] = useState(null);

  const [childSubCategoriesValue, setChildSubCategoriesValue] = useState({
    value: '',
    label: '',
  });

  const [subCategoriesValue, setSubCategoriesValue] = useState({
    value: '',
    label: '',
  });

  const [mainCategoriesValue, setMainCategoriesValue] = useState({
    value: '',
    label: '',
  });


  useEffect(() => {
    // Assuming you have a default specialization value, set it here
    const defaultSpecializationValue = specializations.find(
      (spec) => spec.id === checked
    );

    if (defaultSpecializationValue) {
      setDefaultSpecialization({
        value: defaultSpecializationValue?.id,
        label: defaultSpecializationValue?.title?.[locale],
      });
    }
  }, [checked, locale, specializations]);

  useEffect(() => {
    const search = Object.fromEntries([...searchParams]);
    const searchCategory = categories?.find((category) => {
      return search?.category === category?.id;
    });
    let subCategory = searchCategory;

    let mainCategory = categories?.find((category) => {
      return category?._id === searchCategory?.category?._id;
    });

    if (mainCategoriesValue?.value) {
      const subCategories = categories?.filter(
        (category) => category?.category?._id === mainCategoriesValue?.value
      );

      setSubCategories(subCategories);
    }
    // ================| check for child cat of sub |=================
    if (searchCategory?.category?.category) {
      mainCategory = categories?.find((category) => {
        return getId(category) === getId(searchCategory?.category?.category);
      });

      const subCategories = categories?.filter(
        (category) => getId(category?.category) === getId(mainCategory)
      );

      setSubCategories(subCategories);

      setChildSubCategoriesValue({
        value: searchCategory?.id,
        label: searchCategory?.name?.[locale],
      });

      subCategory = categories?.find((category) => {
        return getId(category) === getId(searchCategory?.category);
      });

      // ------------------------------------------

      setChildrenSubCategories(
        categories?.filter(
          (category) => getId(category?.category) === getId(subCategory)
        )
      );
    }
    // ========================================

    setSubCategoriesValue({
      value: subCategory?.id,
      label: subCategory?.name?.[locale],
    });

    setMainCategoriesValue({
      value: mainCategory?.id,
      label: mainCategory?.name?.[locale],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, categories, locale]);

  const handleSelectOptions = (options) => {
    if (options?.length < 0) return [];

    return options?.map((option) => {
      return {
        value: option?.id || option?._id,
        label: option?.name?.[locale] || option?.title?.[locale],
      };
    });
  };

  // useEffect(() => {
  // }, [dispatch]);

  useEffect(() => {
    if (timeoutHolder != null) clearTimeout(timeoutHolder);
    timeoutHolder = setTimeout(() => {
      const searchParamsObj = Object.fromEntries([...searchParams]);

      const params = {};

      if (searchText) params['textFilter'] = searchText;
      else if (searchParamsObj.textFilter) delete searchParamsObj.textFilter;

      const newSearch = handleSearchParamsChange(searchParamsObj, params);

      navigate(window.location.pathname + newSearch, {
        replace: true,
      });
    }, 600);
  }, [searchText]);

  const handleClear = () => {
    if (searchText) {
      setSearchFilter('');
    }
  };
  const handleSearchChange = ({ target: { value } }) => {
    setSearchFilter(value);
  };
  return (
    <div className='category_filter__ card '>
      <div className='card-head justify-content-start align-items-center gap-2'>
        <div className='category_img d-flex justify-content-center align-items-center'>
          <Image src={filterImg} alt={filterImg} />
        </div>
        <h4 className='mb-0'>
          <FormattedMessage id={'categoriesFilter'} />{' '}
        </h4>
      </div>
      <Row className='mt-4'>
        <Col lg={4}>
          {/* mainCategory */}
          <div className='form-group '>
            <h5>
              {' '}
              <FormattedMessage id={'specializationType'} />
            </h5>
            <div>
              <Select
                placeholder={<FormattedMessage id={'specializationType'} />}
                options={handleSelectOptions(specializations)}
                onChange={(selectedOption) => {
                  // setMainCategoriesValue(selectedOption);
                  setMainCategoriesValue([]);
                  setChildrenSubCategories([]);
                  setChecked(selectedOption?.value);

                  // dispatch(
                  //   getAllCategories({ specialization: selectedOption?.value })
                  // );

                  const categories = mainCategories?.filter(
                    (category) =>
                      category?.category?.specialization ===
                      selectedOption?.value
                  );

                  navigate(window.location.pathname);
                  setMainCategoriesValue(categories);
                }}
                isRtl={true}
                isSearchable={false}
                className='basic-single'
                classNamePrefix='select'
                value={defaultSpecialization} // Set the default value here
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          {/* mainCategory */}
          <div className='form-group '>
            <h5>
              {' '}
              <FormattedMessage id={'mainCategory'} />
            </h5>
            <div>
              <Select
                placeholder={<FormattedMessage id={'mainCategory'} />}
                options={handleSelectOptions(mainCategories)}
                onChange={(selectedOption) => {
                  setMainCategoriesValue(selectedOption);
                  setSubCategoriesValue({
                    value: '',
                    label: '',
                  });
                  setChildrenSubCategories([]);

                  if (selectedOption?.value === 'all') {
                    setSubCategories([
                      {
                        _id: 'all',
                        id: 'all',
                        name: {
                          ar: 'الكل',
                          en: 'All',
                        },
                      },
                    ]);
                    navigate(window.location.pathname, {
                      replace: true,
                    });
                    return;
                  }

                  const subCategories = categories?.filter(
                    (category) =>
                      category?.category?._id === selectedOption?.value
                  );

                  setSubCategories(subCategories);
                }}
                isRtl={true}
                isSearchable={false}
                className='basic-single'
                classNamePrefix='select'
                value={mainCategoriesValue}
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          {/* sub category  typeof subCategories !== 'undefined' && subCategories.length > 0 && */}
          {
            <div className='form-group '>
              <h5>
                <FormattedMessage id={'subCategory'} />
              </h5>
              <Select
                placeholder={<FormattedMessage id={'subCategory'} />}
                options={handleSelectOptions(subCategories)}
                onChange={(selectedOption) => {
                  setChildrenSubCategories(
                    categories?.filter(
                      (e) => getId(e?.category) === selectedOption?.value
                    )
                  );

                  if (selectedOption?.value === 'all') {
                    navigate(window.location.pathname, { replace: true });
                    return;
                  }
                  setSubCategoriesValue(selectedOption);
                  setChildSubCategoriesValue({});

                  const search = Object.fromEntries([...searchParams]);

                  const params = {};

                  if (selectedOption?.value)
                    params['category'] = selectedOption?.value;
                  const newSearch = handleSearchParamsChange(search, params, 1);

                  navigate(window.location.pathname + newSearch, {
                    replace: true,
                  });
                }}
                isDisabled={
                  typeof subCategories !== 'undefined' &&
                  subCategories.length > 0
                    ? false
                    : true
                }
                isRtl={true}
                isSearchable={false}
                className='basic-single'
                classNamePrefix='select'
                value={subCategoriesValue}
              />
            </div>
          }
        </Col>
        {childrenSubCategories?.length > 0 && (
          <Col lg={4}>
            <div className='form-group '>
              <h5>
                <FormattedMessage id={'subCategoryChild'} />
              </h5>
              <Select
                placeholder={<FormattedMessage id={'subCategoryChild'} />}
                options={handleSelectOptions(childrenSubCategories)}
                onChange={(selectedOption) => {
                  const childrenSubs = categories?.filter(
                    (e) => getId(e?.category) === selectedOption?.value
                  );
                  if (childrenSubs?.length > 0)
                    setChildrenSubCategories(childrenSubs);

                  if (selectedOption?.value === 'all') {
                    navigate(window.location.pathname, { replace: true });
                    return;
                  }
                  setChildSubCategoriesValue(selectedOption);

                  const search = Object.fromEntries([...searchParams]);

                  const params = {};

                  if (selectedOption?.value)
                    params['category'] = selectedOption?.value;
                  const newSearch = handleSearchParamsChange(search, params, 1);

                  navigate(window.location.pathname + newSearch, {
                    replace: true,
                  });
                }}
                // isDisabled={
                //   typeof subCategories !== "undefined" &&
                //   subCategories.length > 0
                //     ? false
                //     : true
                // }
                isRtl={true}
                isSearchable={false}
                className='basic-single'
                classNamePrefix='select'
                value={childSubCategoriesValue}
              />
            </div>
          </Col>
        )}
        <Col>
          <div className='form-group '>
            <h5>
              <FormattedMessage id={'search'} />
            </h5>
            <Filter
              onFilter={handleSearchChange}
              onClear={handleClear}
              filterText={searchText}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CategoryFilter;
