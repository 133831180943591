import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { handleIsoDate } from "helpers/functions";

const Table = (props) => {
  const { formatMessage } = useIntl();

  const columns = [

    {
      name: <FormattedMessage id={"numberOfQuestions"} />,
      selector: (row) => `${row?.numberOfQuestions} `,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"duration"} />,
      selector: (row) => `${row?.duration} ${formatMessage({ id: "min" })}`,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"totalAnswers"} />,
      selector: (row) => row?.totalAnswers,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"correctAnswers"} />,
      selector: (row) => row?.correctAnswers,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"inCorrectAnswers"} />,
      selector: (row) => row?.inCorrectAnswers,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"startAt"} />,
      selector: (row) => {
        return handleIsoDate(row?.startAt)
      },
      sortable: true,
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4><FormattedMessage id={props?.title} /> </h4>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={props?.data}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
