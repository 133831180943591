import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import {
  addQuestion,
  getAllCategories,
  getAllSpecializations,
} from 'store/actions';
import Loader from 'components/shared/Loader';
import AddButton from 'components/shared/AddButton';
// import { Textarea } from "components/shared/FormComponents";
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import AnswersArray from './AnswersArray';
import Select from 'react-select';
// import { questionType } from "data";
import './questions.css';

import EditorUploadImage from 'components/shared/EditorUploadImage';
import { EditorComponent, Input } from 'components/shared/FormComponents';
import { getId } from 'helpers/functions';

const AddQuestion = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.questions);
  const { categories, loading: categoriesLoading } = useSelector(
    (state) => state.categories
  );
  const { specializations, loading: specializationLoading } = useSelector(
    (state) => state.specializations
  );

  const [title, setTitle] = useState('');
  const [specializationId, setSpecializationId] = useState('');

  // const [titleImage, setTitleImage] = useState({ preview: null, path: "" });
  const [explanation, setExplanation] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesChildren, setSubCategoriesChildren] = useState([]);
  const [subCategoriesChild, setSubCategoriesChild] = useState([]);
  const mainCategories = categories?.filter((category) => !category?.category);
  const [defaultSubCategoryVal, setDefaultSubCategoryVal] = useState({
    value: '',
    label: '',
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: 'answers',
    rules: { minLength: 4, maxLength: 4 },
  });

  useEffect(() => {
    reset({
      answers: [
        {
          index: 'A',
          answer: '',
          isCorrect: false,
        },
        {
          index: 'B',
          answer: '',
          isCorrect: false,
        },
        {
          index: 'C',
          answer: '',
          isCorrect: false,
        },
        {
          index: 'D',
          answer: '',
          isCorrect: false,
        },
      ],
    });
  }, [reset]);

  const handleChangeSpecialization = (id) => {
    console.log(id);
    setSpecializationId(id);
    dispatch(getAllCategories({ specialization: id }));
  };

  useEffect(() => {
    dispatch(getAllSpecializations(''));
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(
        formatMessage({ id: error?.message || 'sendingErrorMessage' }),
        locale
      );
      navigate('/questions');
    }
  }, [error, formatMessage, locale, navigate]);

  const handleSelectOptions = (options) => {
    if (options.length < 0) return [];

    return options.map((option) => {
      return {
        value: option?.id || option?._id,
        label: option?.name?.[locale] || option?.subTitle?.[locale],
      };
    });
  };

  console.log('categories', categories);
  const onSubmit = (data) => {
    // should be the select options
    data.type = 'checkbox';

    data.explanation = explanation;
    data.title = title;

    console.log('data', data);
    data.answers = data?.answers?.map((answer) => {
      return {
        ...answer,
        isCorrect: answer.isCorrect ? true : false,
      };
    });

    delete data.mainCategory;

    if (!data?.category)
      return toastErrorMessage(formatMessage({ id: 'subCatError' }), locale);

    dispatch(addQuestion({ data, navigate }));
  };

  const thirdCatClass =
    subCategoriesChildren?.length > 0 ? { xl: '4', md: '4' } : {};

  if (loading) return <Loader />;

  return (
    <>
      <div className='acc-form edit_quesion___'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'addQuestion'} />
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col xl={12} md={12}>
              <div className='edit_question_sec__'>
                {/* categories */}
                <Row>
                  {/* mainCategory */}
                  <Col lg={4} xs={12} {...thirdCatClass}>
                    <div className='form-group '>
                      <h5 className='input_head_'>
                        {' '}
                        <FormattedMessage id={'specializationType'} />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              inputRef={ref}
                              name={name}
                              placeholder={
                                <FormattedMessage id={'specializationType'} />
                              }
                              options={handleSelectOptions(specializations)}
                              onChange={(selectedOption) => {
                                setDefaultSubCategoryVal({
                                  value: '',
                                  label: '',
                                });
                                setSubCategories([]);
                                setSubCategoriesChildren([]);

                                // const subCategories = categories?.filter(
                                //   (category) =>
                                //     category?.category?._id ===
                                //     selectedOption?.value
                                // );
                                // if (!subCategories?.length > 0)
                                //   toastErrorMessage(
                                //     formatMessage({ id: 'noSubsMainCat' }),
                                //     locale
                                //   );
                                handleChangeSpecialization(
                                  selectedOption.value
                                );
                                // setSubCategories(subCategories);
                                onChange(selectedOption.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className='basic-single'
                              classNamePrefix='select'
                            />
                          )}
                          name={'specialization'}
                          rules={{ required: true }}
                        />
                      </div>

                      <p className='error-hint'>
                        {errors?.specialization && (
                          <FormattedMessage id={'requiredField'} />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} xs={12} {...thirdCatClass}>
                    <div className='form-group '>
                      <h5 className='input_head_'>
                        {' '}
                        <FormattedMessage id={'mainCategory'} />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              isDisabled={
                                !(
                                  typeof categories !== 'undefined' &&
                                  categories.length > 0
                                )
                              }
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              inputRef={ref}
                              name={name}
                              placeholder={
                                <FormattedMessage id={'mainCategory'} />
                              }
                              options={handleSelectOptions(mainCategories)}
                              onChange={(selectedOption) => {
                                setDefaultSubCategoryVal({
                                  value: '',
                                  label: '',
                                });
                                setSubCategories([]);
                                setSubCategoriesChildren([]);

                                const subCategories = categories?.filter(
                                  (category) =>
                                    category?.category?._id ===
                                    selectedOption?.value
                                );
                                if (!subCategories?.length > 0)
                                  toastErrorMessage(
                                    formatMessage({ id: 'noSubsMainCat' }),
                                    locale
                                  );

                                setSubCategories(subCategories);
                                onChange(selectedOption.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className='basic-single'
                              classNamePrefix='select'
                            />
                          )}
                          name={'mainCategory'}
                          rules={{ required: true }}
                        />
                      </div>

                      <p className='error-hint'>
                        {errors?.mainCategory && (
                          <FormattedMessage id={'requiredField'} />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} xs={12} {...thirdCatClass}>
                    <div className='form-group '>
                      <h5 className='input_head_'>
                        {' '}
                        <FormattedMessage id={'subCategory'} />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              isDisabled={
                                !(
                                  typeof subCategories !== 'undefined' &&
                                  subCategories.length > 0
                                )
                              }
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                              }}
                              inputRef={ref}
                              name={name}
                              placeholder={
                                <FormattedMessage id={'subCategory'} />
                              }
                              options={handleSelectOptions(subCategories)}
                              onChange={(selectedOption) => {
                                onChange(selectedOption.value);

                                // --------------------------------------------
                                setSubCategoriesChildren(
                                  categories?.filter(
                                    (category) =>
                                      getId(category?.category) ===
                                      selectedOption?.value
                                  )
                                );
                                // ------------------------------------

                                setDefaultSubCategoryVal(selectedOption);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className='basic-single'
                              classNamePrefix='select'
                              value={defaultSubCategoryVal}
                            />
                          )}
                          name={'category'}
                          rules={{ required: true }}
                        />
                      </div>

                      <p className='error-hint'>
                        {errors?.category && (
                          <FormattedMessage id={'requiredField'} />
                        )}
                      </p>
                    </div>
                  </Col>
                  {/* )} */}
                  {/* sub category */}
                  {typeof subCategoriesChildren !== 'undefined' &&
                    subCategoriesChildren.length > 0 && (
                      <Col lg={4} xs={12} {...thirdCatClass}>
                        <div className='form-group '>
                          <h5 className='input_head_'>
                            {' '}
                            <FormattedMessage id={'subCategoryChild'} />
                          </h5>
                          <div>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <Select
                                  styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  inputRef={ref}
                                  name={name}
                                  placeholder={
                                    <FormattedMessage id={'subCategoryChild'} />
                                  }
                                  options={handleSelectOptions(
                                    subCategoriesChildren
                                  )}
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption.value);

                                    setSubCategoriesChild(selectedOption);
                                  }}
                                  isRtl={true}
                                  isSearchable={false}
                                  className='basic-single'
                                  classNamePrefix='select'
                                  value={subCategoriesChild}
                                />
                              )}
                              name={'category'}
                              rules={{ required: true }}
                            />
                          </div>

                          <p className='error-hint'>
                            {errors?.category && (
                              <FormattedMessage id={'requiredField'} />
                            )}
                          </p>
                        </div>
                      </Col>
                    )}
                </Row>
              </div>

              {/* question */}
              <div className='edit_question_sec__ edit_question_editor_sec__'>
                <h3 className='edit_question_main_head_'>
                  <figure className='shape__'>
                    <svg
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M17 19.1113H13L8.55 22.0713C8.39982 22.1714 8.22528 22.2289 8.045 22.2376C7.86472 22.2463 7.68546 22.2059 7.52635 22.1207C7.36724 22.0355 7.23424 21.9087 7.14154 21.7538C7.04884 21.5989 6.99992 21.4218 7 21.2413V19.1113C4 19.1113 2 17.1113 2 14.1113V8.11133C2 5.11133 4 3.11133 7 3.11133H17C20 3.11133 22 5.11133 22 8.11133V14.1113C22 17.1113 20 19.1113 17 19.1113Z'
                        stroke='#12C8CB'
                        stroke-width='1.5'
                        strokeMiterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M11.9998 12.042V11.832C11.9998 11.152 12.4198 10.792 12.8398 10.502C13.2498 10.222 13.6598 9.86199 13.6598 9.20199C13.6598 8.28199 12.9198 7.54199 11.9998 7.54199C11.0798 7.54199 10.3398 8.28199 10.3398 9.20199M11.9948 14.432H12.0048'
                        stroke='#12C8CB'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </figure>
                  <FormattedMessage id={'question'} />
                </h3>
                <Row className='mt-2'>
                  {/* <Col lg={6} xs={12}>
                    <Input
                      langId='questionNumber'
                      name='questionNumber.ar'
                      register={register}
                      errors={errors}
                      hint={<FormattedMessage id={'optional'} />}
                      rules={{ required: false }}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='questionNumber'
                      name='questionNumber.en'
                      register={register}
                      errors={errors}
                      hint={<FormattedMessage id={'optional'} />}
                      rules={{ required: false }}
                    />
                  </Col> */}
                  <Col lg={9} sm={12}>
                    <div className='editor_wrapper__ '>
                      <h5 className='input_head_'>
                        <FormattedMessage id='questionText' />
                      </h5>
                      <Controller
                        control={control}
                        name='title'
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name='title'
                            setData={setTitle}
                            editorHeight={350}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col lg={3} sm={12}>
                    <div className='edtor_iamge_wrapper__'>
                      <EditorUploadImage setEditor={setTitle} />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className='edit_question_sec__'>
                <AnswersArray
                  fields={fields}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>

              {/* explanation */}
              <div className='edit_question_sec__ edit_question_editor_sec__'>
                <h5 className='edit_question_main_head_'>
                  <figure className='shape__'>
                    <svg
                      width='24'
                      height='25'
                      viewBox='0 0 24 25'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M8.5 19.6816H8C4 19.6816 2 18.6816 2 13.6816V8.68164C2 4.68164 4 2.68164 8 2.68164H16C20 2.68164 22 4.68164 22 8.68164V13.6816C22 17.6816 20 19.6816 16 19.6816H15.5C15.19 19.6816 14.89 19.8316 14.7 20.0816L13.2 22.0816C12.54 22.9616 11.46 22.9616 10.8 22.0816L9.3 20.0816C9.14 19.8616 8.77 19.6816 8.5 19.6816V19.6816Z'
                        stroke='#12C8CB'
                        stroke-width='1.5'
                        strokeMiterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M7 8.68164H17M7 13.6816H13'
                        stroke='#12C8CB'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </figure>
                  <FormattedMessage id={'explanation'} />
                </h5>
                <Row>
                  <Col lg={9} sm={12}>
                    <div className='editor_wrapper__ '>
                      <h2 className='input_head_'>
                        <FormattedMessage id='explanationText' />
                      </h2>
                      <Controller
                        control={control}
                        name='title'
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name='title'
                            setData={setExplanation}
                            editorHeight={350}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col lg={3} sm={12}>
                    <div className='edtor_iamge_wrapper__'>
                      <EditorUploadImage />
                    </div>
                  </Col>
                </Row>
              </div>

              <AddButton />
            </Col>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddQuestion;
