import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_SPECIALIZATION,
  DELETE_SPECIALIZATION,
  EDIT_SPECIALIZATION,
  GET_ALL_SPECIALIZATIONS,
  GET_SPECIALIZATION,
} from './actionTypes';
import {
  addSpecializationApi,
  getAllSpecializationsApi,
  getSpecializationApi,
  editSpecializationApi,
  deleteSpecializationApi,
} from 'api/specialization'; // Update the import path
import {
  addSpecializationFailure,
  addSpecializationSuccess,
  deleteSpecializationFailure,
  deleteSpecializationSuccess,
  editSpecializationFailure,
  editSpecializationSuccess,
  getAllSpecializationsFailure,
  getAllSpecializationsSuccess,
  getSpecializationFailure,
  getSpecializationSuccess,
} from './actions';

function* getAllSpecializations() {
  try {
    const { data } = yield call(getAllSpecializationsApi);

    yield put(getAllSpecializationsSuccess(data));
  } catch (error) {
    yield put(
      getAllSpecializationsFailure(error?.response?.data || error || error)
    );
  }
}

function* addSpecialization({ payload }) {
  try {
    const { data } = yield call(addSpecializationApi, payload.data);

    yield put(addSpecializationSuccess(data));
    payload.navigate('/specializations');

    // Add any additional logic based on your application's requirements
  } catch (error) {
    yield put(
      addSpecializationFailure(error?.response?.data || error || error)
    );
  }
}

function* getSpecialization({ payload }) {
  try {
    const { data } = yield call(getSpecializationApi, payload);

    yield put(getSpecializationSuccess(data));
  } catch (error) {
    yield put(
      getSpecializationFailure(error?.response?.data || error || error)
    );
  }
}

function* editSpecialization({ payload }) {
  try {
    const { data } = yield call(editSpecializationApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editSpecializationSuccess(data));
    payload.navigate('/specializations');
  } catch (error) {
    yield put(
      editSpecializationFailure(error?.response?.data || error || error)
    );
  }
}

function* deleteSpecialization({ payload }) {
  try {
    yield call(deleteSpecializationApi, payload);
    yield put(deleteSpecializationSuccess(payload));
  } catch (error) {
    yield put(
      deleteSpecializationFailure(error?.response?.data || error || error)
    );
  }
}

export function* watchGetAllSpecializations() {
  yield takeEvery(GET_ALL_SPECIALIZATIONS, getAllSpecializations);
}

export function* watchAddSpecialization() {
  yield takeEvery(ADD_SPECIALIZATION, addSpecialization);
}

export function* watchGetSpecialization() {
  yield takeEvery(GET_SPECIALIZATION, getSpecialization);
}

export function* watchEditSpecialization() {
  yield takeEvery(EDIT_SPECIALIZATION, editSpecialization);
}

export function* watchDeleteSpecialization() {
  yield takeEvery(DELETE_SPECIALIZATION, deleteSpecialization);
}

function* specializationsSaga() {
  yield all([
    fork(watchGetAllSpecializations),
    fork(watchAddSpecialization),
    fork(watchGetSpecialization),
    fork(watchEditSpecialization),
    fork(watchDeleteSpecialization),
  ]);
}

export default specializationsSaga;
