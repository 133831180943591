import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { GET_ALL_PERMISSIONS } from "./actionTypes";
import { getAllPermissionsApi } from "api/permissions";
import { getAllPermissionsFailure, getAllPermissionsSuccess } from "./actions";

function* getAllPermissions() {
  try {
    const { data } = yield call(getAllPermissionsApi);
    yield put(getAllPermissionsSuccess(data));
  } catch (error) {
    yield put(
      getAllPermissionsFailure(error?.response?.data || error || error)
    );
  }
}

export function* watchGetAllPermissions() {
  yield takeEvery(GET_ALL_PERMISSIONS, getAllPermissions);
}

function* permissionsSaga() {
  yield all([fork(watchGetAllPermissions)]);
}

export default permissionsSaga;
