import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";
import { getAllSettings, getCurrentUser } from "store/actions";
import Loader from "./components/shared/Loader";

import Categories from "views/categories";
import AddCategory from "views/categories/AddCategory";
import EditCategory from "views/categories/EditCategory";
import Reviews from "views/Reviews";

import Settings from "views/Settings";
import Pages from "views/pages/index";
import EditPage from "views/pages/EditPage";
import AddPage from "views/pages/AddPage";
import Users from "views/Users";
import AddUser from "views/Users/AddUser";
import EditUser from "views/Users/EditUser";
// import Roles from "views/roles";
// import AddRole from "views/roles/AddRole";
// import EditRole from "views/roles/EditRole";

import ShowUser from "views/Users/ShowUser";
import ContactUs from "views/ContactUs";

import Cities from "views/Cities";
import AddCity from "views/Cities/AddCity";
import EditCity from "views/Cities/EditCity";
import Universities from "views/Universities";
import AddUniversity from "views/Universities/AddUniversy";
import EditUniversity from "views/Universities/EditUniversity";
import Subscriptions from "views/Subscriptions";
import AddSubscription from "views/Subscriptions/AddSubscription";
import EditSubscription from "views/Subscriptions/EditSubscription";
import Questions from "views/Questions";
import AddQuestion from "views/Questions/AddQuestion";
import EditQuestion from "views/Questions/EditQuestion";
import Medias from "views/Medias";
import AddMedia from "views/Medias/AddMedia";
import EditMedia from "views/Medias/EditMedia";
import Ethics from "views/Ethics";
import AddEthic from "views/Ethics/AddEthic";
import EditEthic from "views/Ethics/EditEthics";
import ShowQuestion from "views/Questions/ShowQuestion";
import Admins from "views/Admins";
import AddAdmin from "views/Admins/AddAdmin";
import EditAdmin from "views/Admins/EditAdmin";
import EditMyProfile from "views/MyProfile/EditMyProfile";
import ChangePassword from "views/MyProfile/ChangePassword";
import ShowContact from "views/ContactUs/ShowContact";
// import SubscribedUsers from "views/SubscribedUsers";
import MessagesCenter from "views/MessagesCenter";
import { toast } from "react-toastify";
import ServerDownPage from "views/ServerDown";
import Announcements from "views/Announcements";
import AddAnnouncement from "views/Announcements/Add";
import EditAnnouncement from "views/Announcements/Edit";
import EditReview from "views/Reviews/EditReview";
import AddReview from "views/Reviews/AddReview";
import Specializations from "views/specialization";
import AddSpecialization from "views/specialization/AddSpecialization";
import Coupons from "views/coupons";
import AddCoupon from "views/coupons/AddCoupon";
import EditCoupon from "views/coupons/EditCoupon";
import Faqs from "views/Faqs";
import Blogs from "views/Blogs";
import AddFaq from "views/Faqs/AddFaq";
import AddBlog from "views/Blogs/AddBlog";
import EditFaq from "views/Faqs/EditFaq";
import EditBlog from "views/Blogs/EditBlog";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("super-token");

  //selectors
  const { isLoggedIn, user, error, loading } = useSelector(
    (state) => state?.authentication
  );

  useEffect(() => {
    if (error) {
      toast(error, { toastId: error });
    }
  }, [error]);

  const { mainSettings } = useSelector((state) => state.settings);
  const logo = mainSettings?.find((element) => {
    return element.title === "appLogo";
  });

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  useEffect(() => {
    if (token) dispatch(getCurrentUser());
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout user={user} logo={logo} />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />

              <Route
                path="/Profile/edit"
                element={
                  <AuthRoute>
                    <EditMyProfile
                      user={user}
                      error={error}
                      loading={loading}
                    />
                  </AuthRoute>
                }
              />
              <Route
                path="/Profile/changePassword"
                element={
                  <AuthRoute>
                    <ChangePassword />
                  </AuthRoute>
                }
              />

              {/* pages */}
              <Route
                path="/pages"
                element={
                  <AuthRoute>
                    <Pages />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/addPage"
                element={
                  <AuthRoute>
                    <AddPage />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/:id"
                element={
                  <AuthRoute>
                    <EditPage />
                  </AuthRoute>
                }
              />

              {/* main and sub categories  */}
              <Route
                path={"/categories/"}
                element={
                  <AuthRoute>
                    <Categories />
                  </AuthRoute>
                }
              />

              <Route
                path={`/categories/main/add`}
                element={
                  <AuthRoute>
                    <AddCategory />
                  </AuthRoute>
                }
              />

              <Route
                path={`/categories/main/:id`}
                element={
                  <AuthRoute>
                    <EditCategory />
                  </AuthRoute>
                }
              />
              {/* <Route
                path={"/categories/sub"}
                element={
                  <AuthRoute>
                    <Categories />
                  </AuthRoute>
                }
              /> */}

              <Route
                path={`/categories/sub/add`}
                element={
                  <AuthRoute>
                    <AddCategory />
                  </AuthRoute>
                }
              />

              <Route
                path={`/categories/sub/:id`}
                element={
                  <AuthRoute>
                    <EditCategory />
                  </AuthRoute>
                }
              />
              {/*  */}
              {/* <Route
                path={"/categories/sub-child"}
                element={
                  <AuthRoute>
                    <Categories />
                  </AuthRoute>
                }
              /> */}

              <Route
                path={`/categories/sub-child/add`}
                element={
                  <AuthRoute>
                    <AddCategory />
                  </AuthRoute>
                }
              />

              <Route
                path={`/categories/sub-child/:id`}
                element={
                  <AuthRoute>
                    <EditCategory />
                  </AuthRoute>
                }
              />

              {/* Reviews  */}
              <Route
                path="/reviews"
                element={
                  <AuthRoute>
                    <Reviews />
                  </AuthRoute>
                }
              />
              <Route
                path="/reviews/:id"
                element={
                  <AuthRoute>
                    <EditReview />
                  </AuthRoute>
                }
              />
              <Route
                path="/reviews/add"
                element={
                  <AuthRoute>
                    <AddReview />
                  </AuthRoute>
                }
              />
              {/* specializations */}
              {/* <Route
                path='/specializations'
                element={
                  <AuthRoute>
                    <Specializations />
                  </AuthRoute>
                }
              />
              <Route
                path='/specializations/add'
                element={
                  <AuthRoute>
                    <AddSpecialization />
                  </AuthRoute>
                }
              /> */}
              {/* medias */}
              <Route
                path="/medias"
                element={
                  <AuthRoute>
                    <Medias />
                  </AuthRoute>
                }
              />

              <Route
                path="/medias/add"
                element={
                  <AuthRoute>
                    <AddMedia />
                  </AuthRoute>
                }
              />

              <Route
                path="/medias/:id"
                element={
                  <AuthRoute>
                    <EditMedia />
                  </AuthRoute>
                }
              />

              {/* ethics */}
              <Route
                path="/ethics"
                element={
                  <AuthRoute>
                    <Ethics />
                  </AuthRoute>
                }
              />

              <Route
                path="/ethics/add"
                element={
                  <AuthRoute>
                    <AddEthic />
                  </AuthRoute>
                }
              />

              <Route
                path="/ethics/:id"
                element={
                  <AuthRoute>
                    <EditEthic />
                  </AuthRoute>
                }
              />

              {/* cities */}
              <Route
                path="/cities"
                element={
                  <AuthRoute>
                    <Cities />
                  </AuthRoute>
                }
              />

              <Route
                path="/cities/add"
                element={
                  <AuthRoute>
                    <AddCity />
                  </AuthRoute>
                }
              />

              <Route
                path="/cities/:id"
                element={
                  <AuthRoute>
                    <EditCity />
                  </AuthRoute>
                }
              />

              {/* universities */}
              <Route
                path="/universities"
                element={
                  <AuthRoute>
                    <Universities />
                  </AuthRoute>
                }
              />

              <Route
                path="/universities/add"
                element={
                  <AuthRoute>
                    <AddUniversity />
                  </AuthRoute>
                }
              />

              <Route
                path="/universities/:id"
                element={
                  <AuthRoute>
                    <EditUniversity />
                  </AuthRoute>
                }
              />

              {/* subscriptions */}
              <Route
                path="/subscriptions"
                element={
                  <AuthRoute>
                    <Subscriptions />
                  </AuthRoute>
                }
              />

              <Route
                path="/subscriptions/add"
                element={
                  <AuthRoute>
                    <AddSubscription />
                  </AuthRoute>
                }
              />

              <Route
                path="/subscriptions/:id"
                element={
                  <AuthRoute>
                    <EditSubscription />
                  </AuthRoute>
                }
              />

              {/* Coupons  */}
              <Route
                path="/coupons"
                element={
                  <AuthRoute>
                    <Coupons />
                  </AuthRoute>
                }
              />
              <Route
                path="/coupons/add"
                element={
                  <AuthRoute>
                    <AddCoupon />
                  </AuthRoute>
                }
              />
              <Route
                path="/coupons/:id"
                element={
                  <AuthRoute>
                    <EditCoupon />
                  </AuthRoute>
                }
              />

              {/* questions */}
              <Route
                path="/questions"
                element={
                  <AuthRoute>
                    <Questions />
                  </AuthRoute>
                }
              />

              <Route
                path="/questions/add"
                element={
                  <AuthRoute>
                    <AddQuestion />
                  </AuthRoute>
                }
              />

              <Route
                path="/questions/:id"
                element={
                  <AuthRoute>
                    <EditQuestion />
                  </AuthRoute>
                }
              />
              <Route
                path="/questions/show/:id"
                element={
                  <AuthRoute>
                    <ShowQuestion />
                  </AuthRoute>
                }
              />

              {/* users */}
              <Route
                path="/users"
                element={
                  <AuthRoute>
                    <Users />
                  </AuthRoute>
                }
              />
              <Route
                path="/users/add"
                element={
                  <AuthRoute>
                    <AddUser />
                  </AuthRoute>
                }
              />
              <Route
                path="/users/:id"
                element={
                  <AuthRoute>
                    <EditUser />
                  </AuthRoute>
                }
              />

              <Route
                path="/users/show/:id"
                element={
                  <AuthRoute>
                    <ShowUser />
                  </AuthRoute>
                }
              />

              {/* messagesCenter */}
              <Route
                path="/messagesCenter"
                element={
                  <AuthRoute>
                    <MessagesCenter />
                  </AuthRoute>
                }
              />

              {/* users */}
              {/* <Route
                path="/subscribedUsers"
                element={
                  <AuthRoute>
                    <SubscribedUsers />
                  </AuthRoute>
                }
              /> */}

              {/* admins */}
              <Route
                path="/admins"
                element={
                  <AuthRoute>
                    <Admins />
                  </AuthRoute>
                }
              />
              <Route
                path="/admins/add"
                element={
                  <AuthRoute>
                    <AddAdmin />
                  </AuthRoute>
                }
              />
              <Route
                path="/admins/:id"
                element={
                  <AuthRoute>
                    <EditAdmin />
                  </AuthRoute>
                }
              />
              {/* roles */}
              {/* <Route
                path="/roles"
                element={
                  <AuthRoute>
                    <Roles />
                  </AuthRoute>
                }
              />

              <Route
                path="/roles/addRole"
                element={
                  <AuthRoute>
                    <AddRole />
                  </AuthRoute>
                }
              />

              <Route
                path="/roles/:id"
                element={
                  <AuthRoute>
                    <EditRole />
                  </AuthRoute>
                }
              /> */}

              <Route
                path="/contactUs"
                element={
                  <AuthRoute>
                    <ContactUs />
                  </AuthRoute>
                }
              />
              <Route
                path="/contactUs/show/:id"
                element={
                  <AuthRoute>
                    <ShowContact />
                  </AuthRoute>
                }
              />

              <Route
                path="/announcements"
                element={
                  <AuthRoute>
                    <Announcements />
                  </AuthRoute>
                }
              />
              <Route
                path="/announcements/add"
                element={
                  <AuthRoute>
                    <AddAnnouncement />
                  </AuthRoute>
                }
              />
              <Route
                path="/announcements/:id"
                element={
                  <AuthRoute>
                    <EditAnnouncement />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <AuthRoute>
                    <Settings />
                  </AuthRoute>
                }
              />

              <Route
                path="/faqs"
                element={
                  <AuthRoute>
                    <Faqs />
                  </AuthRoute>
                }
              />
              <Route
                path="/faqs/add"
                element={
                  <AuthRoute>
                    <AddFaq />
                  </AuthRoute>
                }
              />
              <Route
                path="/faqs/:id"
                element={
                  <AuthRoute>
                    <EditFaq />
                  </AuthRoute>
                }
              />
              <Route
                path="/blogs"
                element={
                  <AuthRoute>
                    <Blogs />
                  </AuthRoute>
                }
              />
              <Route
                path="/blogs/add"
                element={
                  <AuthRoute>
                    <AddBlog />
                  </AuthRoute>
                }
              />
              <Route
                path="/blogs/:id"
                element={
                  <AuthRoute>
                    <EditBlog />
                  </AuthRoute>
                }
              />

              <Route
                path="*"
                element={
                  <AuthRoute>
                    <NotFound />
                  </AuthRoute>
                }
              />
              <Route path="/server-down" element={<ServerDownPage />} />
            </Route>

            {/*Auth Routes */}

            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login logo={logo} />
                </GuestRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
