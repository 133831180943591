import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  addBlog,
  getAllCategories,
  getAllSpecializations,
} from 'store/actions';
import Loader from 'components/shared/Loader';
import AddButton from 'components/shared/AddButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import Select from 'react-select';

import EditorUploadImage from 'components/shared/EditorUploadImage';
import { EditorComponent, Input } from 'components/shared/FormComponents';
import { getId } from 'helpers/functions';
import imageUpload from '../../assets/svgs/imageUpload.svg';
import server from 'api/server';
import removeImg from '../../assets/svgs/close.svg';

const AddBlog = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.blogs);

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: '',
  });
  // const [specializationId, setSpecializationId] = useState('');
  // const { specializations, loading: specializationLoading } = useSelector(
  //   (state) => state.specializations
  // );

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { data } = await server().post('/upload', formData);

      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage('');
  };

  // const { categories, loading: categoriesLoading } = useSelector(
  //   (state) => state.categories
  // );

  // const mainCategories = categories?.filter((category) => !category?.category);

  const [contentAr, setContentAr] = useState('');
  const [contentEn, setContentEn] = useState('');
  // const [subCategories, setSubCategories] = useState([]);
  // const [subCategoriesChildren, setSubCategoriesChildren] = useState([]);
  // const [defaultSubCategoryVal, setDefaultSubCategoryVal] = useState('');
  // const [subCategoriesChild, setSubCategoriesChild] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   dispatch(getAllCategories(''));
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getAllSpecializations(''));
  // }, [dispatch]);

  useEffect(() => {
    if (error) {
      toastErrorMessage(
        formatMessage({ id: error?.message || 'sendingErrorMessage' }),
        locale
      );
      navigate('/blogs');
    }
  }, [error, formatMessage, locale, navigate]);

  // const handleSelectOptions = (options) => {
  //   if (options.length < 0) return [];

  //   return options.map((option) => {
  //     return {
  //       value: option?.id || option?._id,
  //       label: option?.name?.[locale] || option?.subTitle?.[locale],
  //     };
  //   });
  // };

  // const handleChangeSpecialization = (id) => {
  //   setSpecializationId(id);
  //   dispatch(getAllCategories({ specialization: id }));
  // };

  const onSubmit = (data) => {
    // Additional processing for blog data
    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: 'imageErrorMessage' }), locale);
      return;
    }

    data.featuredImage = selectedImage.path;
    data.content = { ar: contentAr, en: contentEn };
    delete data.contentAr;
    delete data.contentEn;
    delete data.specializationType;
    // data.category = data.mainCategory;
    delete data.mainCategory;

    console.log(data);
    // Dispatch the action to add the blog
    dispatch(addBlog({ data, navigate }));
  };

  if (loading) return <Loader />;

  // const thirdCatClass =
  //   subCategoriesChildren?.length > 0 ? { xl: '4', md: '4' } : {};

  return (
    <>
      <div className='acc-form edit_quesion___'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'addBlog'} />
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='edit_question_sec__'>
              {/* categories */}
              <Row>
                {/* mainCategory */}
                {/* <Col lg={4} xs={12} {...thirdCatClass}>
                  <div className="form-group ">
                    <h5 className="input_head_">
                      {" "}
                      <FormattedMessage id={"specializationType"} />
                    </h5>
                    <div>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            inputRef={ref}
                            name={name}
                            placeholder={
                              <FormattedMessage id={"specializationType"} />
                            }
                            options={handleSelectOptions(specializations)}
                            onChange={(selectedOption) => {
                              setDefaultSubCategoryVal({
                                value: "",
                                label: "",
                              });
                              setSubCategories([]);
                              setSubCategoriesChildren([]);
                              handleChangeSpecialization(selectedOption.value);
                              onChange(selectedOption.value);
                            }}
                            isRtl={true}
                            isSearchable={false}
                            className="basic-single"
                            classNamePrefix="select"
                          />
                        )}
                        name={"specializationType"}
                        rules={{ required: true }}
                      />
                    </div>

                    <p className="error-hint">
                      {errors?.specializationType && (
                        <FormattedMessage id={"requiredField"} />
                      )}
                    </p>
                  </div>
                </Col> */}
                {/* <Col lg={4} xs={12} {...thirdCatClass}>
                  <div className="form-group ">
                    <h5 className="input_head_">
                      {" "}
                      <FormattedMessage id={"mainCategory"} />
                    </h5>
                    <div>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            isDisabled={
                              !(
                                typeof categories !== "undefined" &&
                                categories.length > 0
                              )
                            }
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            inputRef={ref}
                            name={name}
                            placeholder={
                              <FormattedMessage id={"mainCategory"} />
                            }
                            options={handleSelectOptions(mainCategories)}
                            onChange={(selectedOption) => {
                              // setDefaultSubCategoryVal({
                              //   value: "",
                              //   label: "",
                              // });
                              // setSubCategories([]);
                              // setSubCategoriesChildren([]);

                              // const subCategories = categories?.filter(
                              //   (category) =>
                              //     category?.category?._id ===
                              //     selectedOption?.value
                              // );
                              // if (!subCategories?.length > 0)
                              //   toastErrorMessage(
                              //     formatMessage({ id: "noSubsMainCat" }),
                              //     locale
                              //   );

                              // setSubCategories(subCategories);
                              onChange(selectedOption.value);
                            }}
                            isRtl={true}
                            isSearchable={false}
                            className="basic-single"
                            classNamePrefix="select"
                          />
                        )}
                        name={"mainCategory"}
                        rules={{ required: true }}
                      />
                    </div>

                    <p className="error-hint">
                      {errors?.mainCategory && (
                        <FormattedMessage id={"requiredField"} />
                      )}
                    </p>
                  </div>
                </Col> */}

                {/* <Col lg={4} xs={12} {...thirdCatClass}>
                  <div className="form-group ">
                    <h5 className="input_head_">
                      {" "}
                      <FormattedMessage id={"subCategory"} />
                    </h5>
                    <div>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            isDisabled={
                              !(
                                typeof subCategories !== "undefined" &&
                                subCategories.length > 0
                              )
                            }
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            inputRef={ref}
                            name={name}
                            placeholder={
                              <FormattedMessage id={"subCategory"} />
                            }
                            options={handleSelectOptions(subCategories)}
                            onChange={(selectedOption) => {
                              onChange(selectedOption.value);

                              // --------------------------------------------
                              setSubCategoriesChildren(
                                categories?.filter(
                                  (category) =>
                                    getId(category?.category) ===
                                    selectedOption?.value
                                )
                              );
                              // ------------------------------------

                              setDefaultSubCategoryVal(selectedOption);
                            }}
                            isRtl={true}
                            isSearchable={false}
                            className="basic-single"
                            classNamePrefix="select"
                            value={defaultSubCategoryVal}
                          />
                        )}
                        name={"category"}
                        rules={{ required: true }}
                      />
                    </div>

                    <p className="error-hint">
                      {errors?.category && (
                        <FormattedMessage id={"requiredField"} />
                      )}
                    </p>
                  </div>
                </Col> */}
                {/* )} */}
                {/* sub category */}
                {/* {typeof subCategoriesChildren !== "undefined" &&
                  subCategoriesChildren.length > 0 && (
                    <Col lg={4} xs={12} {...thirdCatClass}>
                      <div className="form-group ">
                        <h5 className="input_head_">
                          {" "}
                          <FormattedMessage id={"subCategoryChild"} />
                        </h5>
                        <div>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                inputRef={ref}
                                name={name}
                                placeholder={
                                  <FormattedMessage id={"subCategoryChild"} />
                                }
                                options={handleSelectOptions(
                                  subCategoriesChildren
                                )}
                                onChange={(selectedOption) => {
                                  onChange(selectedOption.value);

                                  setSubCategoriesChild(selectedOption);
                                }}
                                isRtl={true}
                                isSearchable={false}
                                className="basic-single"
                                classNamePrefix="select"
                                value={subCategoriesChild}
                              />
                            )}
                            name={"category"}
                            rules={{ required: true }}
                          />
                        </div>

                        <p className="error-hint">
                          {errors?.category && (
                            <FormattedMessage id={"requiredField"} />
                          )}
                        </p>
                      </div>
                    </Col>
                  )} */}
              </Row>
            </div>

            {/* Featured Image */}

            <div className='edtor_iamge_wrapper__ '>
              <h5 className='input_head_'>
                <FormattedMessage id={'image'} />
              </h5>

              <div className='form-group required'>
                <div className='image-uploader image-contain '>
                  <div className='img-pat'>
                    <img src={imageUpload} width={46} height={46} alt='' />
                  </div>
                  <label>
                    {selectedImage && selectedImage.preview && (
                      <div>
                        <img
                          src={URL.createObjectURL(selectedImage.preview)}
                          width={160}
                          height={160}
                          alt=''
                        />

                        <button
                          className='removeImg'
                          onClick={removeSelectedImage}
                        >
                          <img src={removeImg} alt='' />
                        </button>
                      </div>
                    )}
                    <input
                      accept='image/*'
                      type='file'
                      onChange={imageChange}
                    />
                  </label>
                </div>
              </div>
            </div>

            {/* Title */}
            <div className='edit_question_sec__ mt-5'>
              <Row>
                <Col lg={6} sm={12}>
                  <div className='editor_wrapper__ '>
                    <label className='mb-2'>
                      <FormattedMessage id={'title'} /> (
                      <FormattedMessage id='arabic-language' />)
                    </label>
                    <Input
                      name='title.ar'
                      register={register}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className='editor_wrapper__ '>
                    <label className='mb-2'>
                      <FormattedMessage id={'title'} /> (
                      <FormattedMessage id='english-language' />)
                    </label>
                    <Input
                      name='title.en'
                      register={register}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className='edit_question_sec__ edit_question_editor_sec__ mt-5'>
                    <h5 className='edit_question_main_head_'>
                      <FormattedMessage id={'content'} />
                      (
                      <FormattedMessage id='arabic-language' />)
                    </h5>
                    <div className='editor_wrapper__ '>
                      <h2 className='input_head_'>
                        <FormattedMessage id='contentText' />
                        (
                        <FormattedMessage id='arabic-language' />)
                      </h2>
                      <Controller
                        control={control}
                        name='contentAr'
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name='contentAr'
                            setData={setContentAr}
                            editorHeight={350}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className='edit_question_sec__ edit_question_editor_sec__ mt-5'>
                    <h5 className='edit_question_main_head_'>
                      <FormattedMessage id={'content'} />
                      (
                      <FormattedMessage id='english-language' />)
                    </h5>

                    <div className='editor_wrapper__ '>
                      <h2 className='input_head_'>
                        <FormattedMessage id='contentText' />
                        (
                        <FormattedMessage id='english-language' />)
                      </h2>
                      <Controller
                        control={control}
                        name='contentEn'
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name='contentEn'
                            setData={setContentEn}
                            editorHeight={350}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Content */}

            <AddButton />
          </form>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
