import {
  ADD_CATEGORY,
  ADD_CATEGORY_FAILURE,
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  EDIT_CATEGORY,
  EDIT_CATEGORY_FAILURE,
  EDIT_CATEGORY_SUCCESS,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_FAILURE,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_SUCCESS,
} from './actionTypes';

export const getAllCategories = (payload) => {
  return {
    type: GET_ALL_CATEGORIES,
    payload: payload,
  };
};
export const getAllCategoriesSuccess = (payload) => {
  return {
    type: GET_ALL_CATEGORIES_SUCCESS,
    payload: payload,
  };
};

export const getAllCategoriesFailure = (error) => {
  return {
    type: GET_ALL_CATEGORIES_FAILURE,
    payload: error,
  };
};

export const addCategory = (payload) => {
  return {
    type: ADD_CATEGORY,
    payload: payload,
  };
};
export const addCategorySuccess = (payload) => {
  return {
    type: ADD_CATEGORY_SUCCESS,
    payload: payload,
  };
};

export const addCategoryFailure = (error) => {
  return {
    type: ADD_CATEGORY_FAILURE,
    payload: error,
  };
};

export const getCategory = (payload) => {
  return {
    type: GET_CATEGORY,
    payload: payload,
  };
};
export const getCategorySuccess = (payload) => {
  return {
    type: GET_CATEGORY_SUCCESS,
    payload: payload,
  };
};

export const getCategoryFailure = (error) => {
  return {
    type: GET_CATEGORY_FAILURE,
    payload: error,
  };
};

export const editCategory = (payload) => {
  return {
    type: EDIT_CATEGORY,
    payload: payload,
  };
};
export const editCategorySuccess = (payload) => {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    payload: payload,
  };
};

export const editCategoryFailure = (error) => {
  return {
    type: EDIT_CATEGORY_FAILURE,
    payload: error,
  };
};

export const deletecategory = (payload) => {
  return {
    type: DELETE_CATEGORY,
    payload: payload,
  };
};

export const deletecategorySuccess = (payload) => {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    payload: payload,
  };
};

export const deletecategoryFailure = (error) => {
  return {
    type: DELETE_CATEGORY_FAILURE,
    payload: error,
  };
};
