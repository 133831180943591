import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { Input } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { editMyProfile } from "store/actions";


const EditAdmin = ({ user, error, loading }) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    if (user && Object.keys(user).length > 0) {

      reset({
        fullName: user?.fullName || user?.user?.fullName,
        phone: user?.phone || user?.user?.phone,
        email: user?.email || user?.user?.email,
        type: user?.type || user?.user?.type
      });
    }

  }, [reset, user]);

  // error 
  useEffect(() => {
    if (error) {
      if (error?.statusCode === 422) {
        error?.errors?.forEach((err) => {
          toastErrorMessage(err.msg, locale);
        })
      } else {
        toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      }
    }

  }, [error, formatMessage, locale, navigate]);


  const onSubmit = (data) => {

    dispatch(editMyProfile(data))
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"myProfileEdit"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden"  {...register("type")} />
              <Col xl={8} md={12}>
                {/* fullName */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="name" name="fullName" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* phone */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="phone" name="phone" placeholder='00966xxxxxxxxx' register={register} errors={errors} />
                  </Col>
                </Row>

                {/* email */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="email" name="email" register={register} errors={errors} />
                  </Col>
                </Row>
                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
