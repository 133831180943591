import React, {
  useMemo,
  useState,
  forwardRef,
  useEffect,
  useCallback,
} from 'react';
import DataTable from 'react-data-table-component';
import { Accordion, Col, Dropdown, Spinner } from 'react-bootstrap';

import Filter from 'components/shared/Filter';
import { useDispatch } from 'react-redux';
import { Link, json, useNavigate } from 'react-router-dom';
import Loader from 'components/shared/Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { deletecategory, getAllCategories, getCategory } from 'store/actions';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { getId, handleIsoDate } from 'helpers/functions';
import Swal from 'sweetalert2';
import server from 'api/server';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

const Table = (props) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [specialization, setSpecialization] = useState({
    value: '',
    label: '',
  });

  console.log('categories', props?.data);
  const { locale, formatMessage } = useIntl();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataChanges, setDataChanges] = useState(0);

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  useEffect(() => {
    if (JSON.stringify(data) === JSON.stringify(props?.data)) setDataChanges(0);
  }, [props?.data, data]);

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className='form-check'>
      <input
        htmlFor='booty-check'
        type='checkbox'
        className='form-check-input'
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className='form-check-label' id='booty-check' />
    </div>
  ));

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleAddCategory = (id, type = '', specialization = '') => {
    console.log('item', specialization);
    navigate(
      `/categories/${type}/add?parentCategory=${id}&specialization=${specialization}`
    );
  };

  const handleEditCategory = (id, type = '', specialization) => {
    navigate(`/categories/${type}/${id}?specialization=${specialization}`);
  };

  const handleDeleteClick = (id, name) => {
    Swal.fire({
      text: `${formatMessage({ id: 'deleteCatConfirmMsg' })} ( ${name} )`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: formatMessage({ id: 'delete' }),
      confirmButtonColor: '#12c8cb',
      cancelButtonColor: '#fc5296',
      cancelButtonText: formatMessage({ id: 'close' }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletecategory(id));
      }
    });
  };

  function array_move(arr, old_index, new_index) {
    let newArr = [...arr];

    var f = { ...newArr.splice(old_index, 1)[0] };
    newArr.splice(new_index, 0, f);

    newArr = newArr?.map((e, index) => ({ ...e, order: index }));

    setData((data) =>
      data?.map((e) => newArr?.find((n) => getId(n) === getId(e)) || e)
    );

    setDataChanges(dataChanges + 1);

    // return arr; // for testing
  }

  useEffect(() => {
    setSpecialization({
      label: props.specializations[0]?.subTitle[locale],
      value: props.specializations[0]?.id,
    });
  }, [locale, props.specializations]);

  // const handleDeleteCategory = (id) => {
  //   dispatch(deletecategory(id));
  // };

  // const columns = [
  //   {
  //     name: <FormattedMessage id={"name"} />,
  //     selector: (row) => row?.name?.[locale],
  //     sortable: true,
  //   },
  //   {
  //     name: props.categoryType === "sub" && (
  //       <FormattedMessage id={"mainCategory"} />
  //     ),
  //     selector: (row) =>
  //       props.categoryType === "sub" && row?.category?.name?.[locale],
  //     sortable: true,
  //   },
  //   {
  //     name: <FormattedMessage id={"createdAtDate"} />,
  //     selector: (row) => {
  //       return handleIsoDate(row?.createdAt);
  //     },
  //     sortable: true,
  //   },
  //   {
  //     cell: (row) => (
  //       <>
  //         {checkPermissions(["categories-update", "categories-delete"]) && (
  //           <div className="global-dropdown">
  //             <Dropdown>
  //               <Dropdown.Toggle>
  //                 <svg
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   width="20"
  //                   height="20"
  //                   fill="currentColor"
  //                   className="bi bi-three-dots-vertical"
  //                   viewBox="0 0 16 16"
  //                 >
  //                   <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
  //                 </svg>
  //               </Dropdown.Toggle>

  //               <Dropdown.Menu>
  //                 {checkPermissions(["categories-update"]) && (
  //                   <Dropdown.Item
  //                     onClick={(e) => handleEditCategory(row?._id)}
  //                   >
  //                     <svg
  //                       xmlns="http://www.w3.org/2000/svg"
  //                       width="12"
  //                       height="12"
  //                       fill="#fff"
  //                       className="bi bi-pencil edit"
  //                       viewBox="0 0 16 16"
  //                     >
  //                       <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
  //                     </svg>
  //                     <FormattedMessage id={"edit"} />
  //                   </Dropdown.Item>
  //                 )}

  //                 {checkPermissions(["categories-delete"]) && (
  //                   <Dropdown.Item
  //                     onClick={(e) =>
  //                       handleDeleteClick(row?._id, row?.name?.[locale])
  //                     }
  //                   >
  //                     <svg
  //                       xmlns="http://www.w3.org/2000/svg"
  //                       width="12"
  //                       height="12"
  //                       fill="#fff"
  //                       className="bi bi-trash delete"
  //                       viewBox="0 0 16 16"
  //                     >
  //                       <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
  //                       <path
  //                         fillRule="evenodd"
  //                         d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
  //                       />
  //                     </svg>
  //                     <FormattedMessage id={"delete"} />
  //                   </Dropdown.Item>
  //                 )}
  //               </Dropdown.Menu>
  //             </Dropdown>
  //           </div>
  //         )}
  //       </>
  //     ),
  //     allowOverflow: true,
  //     button: true,
  //     width: "56px",
  //   },
  // ];

  const mainCategories = data?.filter((e) => !e?.category);
  // const mainCategories = data;

  const renderSubCats = (parentId, type = '') =>
    data
      ?.filter((e) => getId(e?.category) === parentId)
      ?.sort((a, b) => a?.order - b?.order)
      ?.map((subCat, index) => {
        const subData = data
          ?.filter((e) => getId(e?.category) === parentId)
          ?.sort((a, b) => a?.order - b?.order);

        return (
          <div className={type} key={index}>
            <Accordion defaultActiveKey='1' alwaysOpen>
              <Accordion.Item eventKey={type + index + subCat?.name?.[locale]}>
                <Accordion.Header
                  className={
                    data?.filter((e) => getId(e?.category) === getId(subCat))
                      ?.length === 0
                      ? 'disable'
                      : ''
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    // console.log("accordion head click", e);
                    e.target.onClick = (e) => e?.stopPropagation();
                  }}
                >
                  <div
                    className='order d-flex flex-column'
                    onClick={(e) => e.stopPropagation()}
                  >
                    {index !== 0 && (
                      <button
                        onClick={() => array_move(subData, index, index - 1)}
                      >
                        &uarr;
                      </button>
                    )}

                    {index !== subData?.length - 1 && (
                      <button
                        onClick={() => array_move(subData, index, index + 1)}
                      >
                        &darr;
                      </button>
                    )}
                  </div>

                  {subCat?.name?.[locale]}

                  <div className='global-dropdown'>
                    {type !== 'sub-child' && (
                      <button
                        className='btn'
                        onClick={(e) =>
                          handleAddCategory(
                            getId(subCat),
                            type === 'sub' ? 'sub-child' : '',
                            subCat?.specialization
                          )
                        }
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='22'
                          height='22'
                          fill='#fff'
                          className='bi bi-plus add'
                          viewBox='0 0 16 16'
                        >
                          <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                        </svg>
                      </button>
                    )}

                    <Link to={`/questions?category=${getId(subCat)}`}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='#fff'
                        className='bi bi-eye-fill add'
                        viewBox='0 0 16 16'
                      >
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                    </Link>

                    <button
                      className='btn'
                      onClick={(e) =>
                        handleEditCategory(
                          getId(subCat),
                          type,
                          subCat.specialization
                        )
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='12'
                        height='12'
                        fill='#fff'
                        className='bi bi-pencil edit'
                        viewBox='0 0 16 16'
                      >
                        <path d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z' />
                      </svg>
                    </button>

                    <button
                      className='btn'
                      onClick={(e) =>
                        handleDeleteClick(getId(subCat), subCat?.name?.[locale])
                      }
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='12'
                        height='12'
                        fill='#fff'
                        className='bi bi-trash delete'
                        viewBox='0 0 16 16'
                      >
                        <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z' />
                        <path
                          fillRule='evenodd'
                          d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'
                        />
                      </svg>
                    </button>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div>{renderSubCats(getId(subCat), 'sub-child')}</div>
                  {/* </div> */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        );
      });

  const renderCats = mainCategories
    ?.sort((a, b) => a?.order - b?.order)
    ?.map((item, index) => (
      <div className='main-cat' key={index}>
        <Accordion.Item eventKey={index}>
          <Accordion.Header>
            <div
              className='order d-flex flex-column'
              onClick={(e) => e.stopPropagation()}
            >
              {index !== 0 && (
                <button
                  onClick={() =>
                    array_move(
                      mainCategories?.sort((a, b) => a?.order - b?.order),
                      index,
                      index - 1
                    )
                  }
                >
                  &uarr;
                </button>
              )}

              {index !==
                mainCategories?.sort((a, b) => a?.order - b?.order)?.length -
                  1 && (
                <button
                  onClick={() =>
                    array_move(
                      mainCategories?.sort((a, b) => a?.order - b?.order),
                      index,
                      index + 1
                    )
                  }
                >
                  &darr;
                </button>
              )}
            </div>
            {item?.name?.[locale]}{' '}
            <div className='global-dropdown'>
              <button
                className='btn'
                onClick={(e) =>
                  handleAddCategory(getId(item), 'sub', item?.specialization)
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='22'
                  height='22'
                  fill='#fff'
                  className='bi bi-plus add'
                  viewBox='0 0 16 16'
                >
                  <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                </svg>
              </button>
              <button
                className='btn'
                onClick={(e) => handleEditCategory(getId(item), 'main',  item?.specialization)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  fill='#fff'
                  className='bi bi-pencil edit'
                  viewBox='0 0 16 16'
                >
                  <path d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z' />
                </svg>
              </button>

              <button
                className='btn'
                onClick={(e) =>
                  handleDeleteClick(getId(item), item?.name?.[locale])
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  fill='#fff'
                  className='bi bi-trash delete'
                  viewBox='0 0 16 16'
                >
                  <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z' />
                  <path
                    fillRule='evenodd'
                    d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'
                  />
                </svg>
              </button>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {/* <div className="d-flex align-items-center">
            <div style={{ flex: "1" }}>
              <div className="global-dropdown">
                <button
                  className="btn"
                  onClick={(e) => handleAddCategory(getId(item), "sub")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="#fff"
                    className="bi bi-plus add"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </button>
                <button
                  className="btn"
                  onClick={(e) => handleEditCategory(getId(item), "main")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="#fff"
                    className="bi bi-pencil edit"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                </button>

                <button
                  className="btn"
                  onClick={(e) =>
                    handleDeleteClick(getId(item), item?.name?.[locale])
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="#fff"
                    className="bi bi-trash delete"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div> */}
            <div style={{ paddingInlineStart: '1rem' }}>
              {renderSubCats(getId(item), 'sub')}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </div>
    ));
  const handleSave = async () => {
    await server().put('/categories/reorderCategories', {
      categories: data?.map((e) => ({ id: getId(e), order: e?.order || 0 })),
    });
    setDataChanges(0);
    // dispatch(getAllCategories())
  };

  const handleSelectOptions = useCallback(
    (options) => {
      if (options.length < 0) return [];

      return options.map((option) => {
        return {
          value: getId(option),
          label: option?.name?.[locale] || option?.subTitle?.[locale],
        };
      });
    },
    [locale]
  );

  const handleChangeSpecialization = (id) => {
    dispatch(getAllCategories({ specialization: id }));
  };

  return (
    <>
      <div className='table-wrap '>
        <div className='card'>
          <Col xl={6} md={12} xs={12}>
            <div className='card-head'>
              <div>
                <h4>
                  <FormattedMessage id={`categoriesTableShow`} />
                </h4>
                <p>
                  <FormattedMessage id={`categoriesTableShowAll`} />
                </p>
              </div>
            </div>
          </Col>
          {props.specializationLoading ? (
            <Spinner animation='border' variant='primary' />
          ) : (
            <Col xs={12} md={4}>
              <div className='form-group'>
                <h5 className='mb-2'>
                  {' '}
                  <FormattedMessage id={'specializationType'} />
                </h5>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      name={field.name}
                      placeholder={
                        <FormattedMessage id={'specializationType'} />
                      }
                      options={handleSelectOptions(props.specializations)}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption.value);
                        setSpecialization(selectedOption);
                        handleChangeSpecialization(selectedOption.value);
                      }}
                      isRtl={true}
                      isSearchable={false}
                      className='basic-single'
                      classNamePrefix='select'
                      value={specialization}
                    />
                  )}
                  name='specialization'
                  // rules={{ required: !categoryType?.includes('sub') }}
                />
              </div>
            </Col>
          )}

          <div className='card-body rdt_TableCell'>
            {props.loading && !props.specializationLoading && (
              <Spinner animation='border' variant='primary' />
            )}
            <Accordion defaultActiveKey='0' alwaysOpen>
              {renderCats}
            </Accordion>
            {/* <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            /> */}
          </div>
          {dataChanges > 0 && (
            <div className='card-head'>
              <h4 className='mb-0'>
                <FormattedMessage id='saveChanges' />
              </h4>
              <button className='btn btn-blue' onClick={handleSave}>
                <h4 className='mb-0 text-white'>
                  <FormattedMessage id='save' />
                </h4>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Table;
