import {
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FAILURE,
  GET_ANNOUNCEMENTS_SUCCESS,
  EDIT_ANNOUNCEMENT,
  EDIT_ANNOUNCEMENT_FAILURE,
  EDIT_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAILURE,
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_SUCCESS,
  ADD_ANNOUNCEMENT_FAILURE,
  GET_SINGLE_ANNOUNCEMENT,
  GET_SINGLE_ANNOUNCEMENT_SUCCESS,
  GET_SINGLE_ANNOUNCEMENT_FAILURE,
} from "./actionTypes";

export const getAnnouncements = (payload) => {
  return {
    type: GET_ANNOUNCEMENTS,
    payload: payload,
  };
};

export const getAnnouncementsSuccess = (payload) => {
  return {
    type: GET_ANNOUNCEMENTS_SUCCESS,
    payload: payload,
  };
};

export const getAnnouncementsFailure = (error) => {
  return {
    type: GET_ANNOUNCEMENTS_FAILURE,
    payload: error,
  };
};

// ----------------------------

export const getSingleAnnouncement = (payload) => {
  return {
    type: GET_SINGLE_ANNOUNCEMENT,
    payload: payload,
  };
};

export const getSingleAnnouncementSuccess = (payload) => {
  return {
    type: GET_SINGLE_ANNOUNCEMENT_SUCCESS,
    payload: payload,
  };
};

export const getSingleAnnouncementFailure = (error) => {
  return {
    type: GET_SINGLE_ANNOUNCEMENT_FAILURE,
    payload: error,
  };
};

// ----------------------------

export const addAnnouncement = (payload) => {
  return {
    type: ADD_ANNOUNCEMENT,
    payload: payload,
  };
};

export const addAnnouncementSuccess = (payload) => {
  return {
    type: ADD_ANNOUNCEMENT_SUCCESS,
    payload: payload,
  };
};

export const addAnnouncementFailure = (error) => {
  return {
    type: ADD_ANNOUNCEMENT_FAILURE,
    payload: error,
  };
};
// ----------------------------

export const editAnnouncement = (payload) => {
  return {
    type: EDIT_ANNOUNCEMENT,
    payload: payload,
  };
};

export const editAnnouncementSuccess = (payload) => {
  return {
    type: EDIT_ANNOUNCEMENT_SUCCESS,
    payload: payload,
  };
};

export const editAnnouncementFailure = (error) => {
  return {
    type: EDIT_ANNOUNCEMENT_FAILURE,
    payload: error,
  };
};

// ----------------------------

export const deleteAnnouncement = (payload) => {
  return {
    type: DELETE_ANNOUNCEMENT,
    payload: payload,
  };
};

export const deleteAnnouncementSuccess = (payload) => {
  return {
    type: DELETE_ANNOUNCEMENT_SUCCESS,
    payload: payload,
  };
};

export const deleteAnnouncementFailure = (error) => {
  return {
    type: DELETE_ANNOUNCEMENT_FAILURE,
    payload: error,
  };
};
