export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAILURE = "GET_ALL_BLOGS_FAILURE";

export const GET_BLOG = "GET_BLOG";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_FAILURE = "GET_BLOG_FAILURE";

export const ADD_BLOG = "ADD_BLOG";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";
export const ADD_BLOG_FAILURE = "ADD_BLOG_FAILURE";

export const EDIT_BLOG = "EDIT_BLOG";
export const EDIT_BLOG_SUCCESS = "EDIT_BLOG_SUCCESS";
export const EDIT_BLOG_FAILURE = "EDIT_BLOG_FAILURE";

export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAILURE = "DELETE_BLOG_FAILURE";

export const DELETE_BLOG_COMMENT = "DELETE_BLOG_COMMENT";
export const DELETE_BLOG_COMMENT_SUCCESS = "DELETE_BLOG_COMMENT_SUCCESS";
export const DELETE_BLOG_COMMENT_FAILURE = "DELETE_BLOG_COMMENT_FAILURE";
