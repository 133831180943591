import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ServerDownPage = () => {
  const { isLoggedIn, user, error, loading } = useSelector(
    (state) => state?.authentication
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [isLoggedIn]);

  return (
    <>
      <section className="page_404">
        <div className="text-center">
          <div className="four_zero_four_bg">
            <h1 className="text-center ">Server Error</h1>
          </div>

          <div className="contant_box_404">
            <h3 className="h2">Look like we're down.</h3>

            <p>
              <FormattedMessage id="serverDownDesc" />
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServerDownPage;
