export const GET_ALL_SUBSCRIPTIONS = "GET_ALL_SUBSCRIPTIONS";
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = "GET_ALL_SUBSCRIPTIONS_SUCCESS";
export const GET_ALL_SUBSCRIPTIONS_FAILURE = "GET_ALL_SUBSCRIPTIONS_FAILURE";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "GET_SUBSCRIPTION_FAILURE";

export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_FAILURE = "ADD_SUBSCRIPTION_FAILURE";

export const EDIT_SUBSCRIPTION = "EDIT_SUBSCRIPTION";
export const EDIT_SUBSCRIPTION_SUCCESS = "EDIT_SUBSCRIPTION_SUCCESS";
export const EDIT_SUBSCRIPTION_FAILURE = "EDIT_SUBSCRIPTION_FAILURE";

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAILURE = "DELETE_SUBSCRIPTION_FAILURE";