import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  addUser,
  getAllCities,
  getAllSpecializations,
  getAllUniversities,
} from 'store/actions';
import Loader from 'components/shared/Loader';
import Select from 'react-select';
import { Input } from 'components/shared/FormComponents';
import AddButton from 'components/shared/AddButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import { useState } from 'react';

const AddUser = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.users);
  // const { roles } = useSelector((state) => state.roles);
  const { cities } = useSelector((state) => state.cities);
  const { universities } = useSelector((state) => state.universities);
  const [toggleOtherUniversity, setToggleOtherUniversity] = useState(false);
  const [specialization, setSpecialization] = useState();
  const [universityVal, setUniversityVal] = useState({
    value: '',
    label: '',
  });
  const { specializations, loading: specialLoader } = useSelector(
    (state) => state.specializations
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // effects
  useEffect(() => {
    dispatch(getAllSpecializations());
    dispatch(getAllCities());
    dispatch(getAllUniversities());
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error) {
      if (error?.statusCode === 422) {
        error?.errors?.forEach((err) => {
          toastErrorMessage(err.msg, locale);
        });
      } else {
        toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
      }
    }
  }, [error, formatMessage, locale, navigate]);

  const handleSelectOptions = (options) => {
    if (options.length < 0) return [];

    return options.map((option) => {
      return {
        value: option?.id || option?._id,
        label:
          option?.name?.[locale] || option?.name || option?.subTitle?.[locale],
      };
    });
  };

  const handleOtherUniversity = (e) => {
    setToggleOtherUniversity((prev) => !prev);

    setUniversityVal({
      value: '',
      label: '',
    });
  };

  const onSubmit = (data) => {
    if (data?.otherUnveristy) {
      delete data.university;
    } else {
      delete data.otherUnveristy;
    }

    data.phone = data?.phone?.trim();
    dispatch(addUser({ data, navigate }));
  };

  if (loading || specialLoader) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'addUser'} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* fullName */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='name'
                      name='fullName'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='phone'
                      name='phone'
                      placeholder='00966xxxxxxxxx'
                      register={register}
                      dir='ltr'
                      errors={errors}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='email'
                      name='email'
                      register={register}
                      dir='ltr'
                      errors={errors}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='password'
                      type='password'
                      name='password'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className='form-group'>
                      <h5 className='mb-2'>
                        {' '}
                        <FormattedMessage id={'specializationType'} />
                      </h5>
                      <Controller
                        control={control}
                        render={({ field }) => (
                          <Select
                            name={field.name}
                            placeholder={
                              <FormattedMessage id={'specializationType'} />
                            }
                            options={handleSelectOptions(specializations)}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption.value);
                              setSpecialization(selectedOption);
                            }}
                            isRtl={true}
                            isSearchable={false}
                            className='basic-single'
                            classNamePrefix='select'
                            value={specialization}
                            
                          />
                        )}
                        name='specialization'
                      />
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className='form-group'>
                      <h5>
                        <FormattedMessage id={'city'} />{' '}
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({
                            field: { onChange, value, name, ref, selected },
                          }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={'city'} />}
                              options={handleSelectOptions(cities)}
                              onChange={(selectedOption) => {
                                onChange(selectedOption?.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className='basic-single'
                              classNamePrefix='select'
                            />
                          )}
                          name={'city'}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={9} xs={12}>
                    <div className='form-group'>
                      <h5>
                        <FormattedMessage id={'university'} />{' '}
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({
                            field: { onChange, value, name, ref, selected },
                          }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={
                                <FormattedMessage id={'university'} />
                              }
                              options={handleSelectOptions(universities)}
                              onChange={(selectedOption) => {
                                setUniversityVal(selectedOption);
                                onChange(selectedOption?.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className='basic-single'
                              classNamePrefix='select'
                              value={universityVal}
                              isDisabled={toggleOtherUniversity ? true : false}
                            />
                          )}
                          name={'university'}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <button
                      type='button'
                      className='btn btn-blue other_unversity___'
                      onClick={handleOtherUniversity}
                    >
                      <FormattedMessage id={'other'} />
                    </button>
                  </Col>
                </Row>
                {/* universities  */}

                {/* otherUnveristy */}
                {toggleOtherUniversity ? (
                  <Row>
                    <Col lg={12} xs={12}>
                      <Input
                        langId='otherUnveristy'
                        name='otherUnveristy'
                        register={register}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                ) : (
                  ''
                )}

                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
