import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import categories from "./categories/reducer";
import settings from "./settings/reducer";
import pages from "./pages/reducer";
import users from "./users/reducer";
import roles from "./roles/reducer";
import permissions from "./permissions/reducer";
import contacts from "./contacts/reducer";
import cities from "./cities/reducer";
import universities from "./universities/reducer";
import subscriptions from "./subscriptions/reducer";
import questions from "./questions/reducer";
import medias from "./medias/reducer";
import ethics from "./ethics/reducer";
import admins from "./admins/reducer";
import subscribedUsers from "./subscribedUsers/reducer";
import announcements from "./announcements/reducer";
import reviews from "./reviews/reducer";
import specializations from "./specialization/reducer";
import coupons from "./coupons/reducer";
import blogs from "./blogs/reducer";
import faqs from "./faqs/reducer";

const rootReducer = combineReducers({
  authentication,
  categories,
  pages,
  settings,
  users,
  roles,
  permissions,
  contacts,
  cities,
  universities,
  subscriptions,
  questions,
  medias,
  ethics,
  admins,
  subscribedUsers,
  announcements,
  reviews,
  specializations,
  coupons,
  blogs,
  faqs,
});

export default rootReducer;
