import { useState } from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getAllSubscribedUsers } from 'store/actions';
import RemindersMessageTable from './RemindersMessageTable';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
const ReminderMessages = () => {
  const { subscribedUsers, loading, error } = useSelector((state) => state.subscribedUsers);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();


  const [isDispatched, setIsDispatched] = useState(false)

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllSubscribedUsers());
  }, [dispatch]);

  // error 
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);
  return (
    <>
      <div className="users-wrap">
        <RemindersMessageTable data={subscribedUsers} loading={loading} />
      </div>
    </>
  )
}

export default ReminderMessages