import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import {
  editCategory,
  getAllCategories,
  getAllSpecializations,
  getCategory,
} from 'store/actions';
import Loader from 'components/shared/Loader';
import { Input } from 'components/shared/FormComponents';
import SaveChangesButton from 'components/shared/SaveChangesButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import Select from 'react-select';
import { getId } from 'helpers/functions';

const EditCategory = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { loading, error, singleCategory, categories } = useSelector(
    (state) => state.categories
  );
  const { specializations, loading: specialLoader } = useSelector(
    (state) => state.specializations
  );

  const [isFree, setIsFree] = useState(false);
  const [specialization, setSpecialization] = useState({
    value: '',
    label: '',
  });
  const categoryType = window.location.pathname?.split('/')?.at(-2);

  const [subCategories, setSubCategories] = useState([]);
  const [mainCategory, setMainCategory] = useState({});
  const mainCategories = categories?.filter((category) => !category?.category);

  const specializationParam = searchParams.get('specialization');

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm();

  useEffect(() => {
    if (!specializationParam) {
      navigate('/categories');
    }
  }, []);

  // effects
  useEffect(() => {
    if (categoryType === 'sub-child') {
      dispatch(getAllCategories({ specialization: specializationParam }));
    }
    if (categoryType === 'sub') {
      dispatch(getAllCategories({ specialization: specializationParam }));
    }
    dispatch(getCategory(id));
  }, [dispatch, id, categoryType]);

  useEffect(() => {
    if (singleCategory) {
      dispatch(getAllSpecializations());
      setIsFree(singleCategory?.userlogs?.isFree);
    }
  }, [dispatch, singleCategory]);

  useEffect(() => {
    if (specializations) {
      const currentSpecialization = specializations?.filter(
        (item) => item.id === singleCategory?.userlogs?.specialization
      );
      setSpecialization({
        value: currentSpecialization[0]?.id,
        label: currentSpecialization[0]?.subTitle?.[locale],
      });
    }
  }, [locale, singleCategory?.specialization, specializations]);

  useEffect(() => {
    if (categoryType === 'sub-child') {
      // const subCategories = categories?.filter(
      //   (category) =>
      //     getId(category?.category) ===
      //     getId(
      //       categories?.find((e) => getId(e) == getId(singleCategory?.category))
      //         ?.category
      //     )
      // );

      // setSubCategories(subCategories);
      const subCategories = categories?.filter(
        (category) => category?.category
      );

      setSubCategories(subCategories);
    }
  }, [categoryType, categories]);

  useEffect(() => {
    if (categoryType === 'sub-child') {
      // setMainCategory(
      //   getId(
      //     mainCategories?.find((e) =>
      //       e?.subCategories?.find(
      //         (sub) => getId(sub) == getId(singleCategory?.category)
      //       )
      //     )
      //   )
      // );

      // const subCategories = categories?.filter(
      //   (category) =>
      //     getId(category?.category) ===
      //     getId(
      //       categories?.find((e) => getId(e) == getId(singleCategory?.category))
      //         ?.category
      //     )
      // );

      setSubCategories(subCategories);
    }

    reset({
      name: singleCategory?.name,
      category: singleCategory?.category?.id,
    });
  }, [reset, singleCategory, locale, JSON.stringify(mainCategories)]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
      // navigate(`/categories/${categoryType}`);
    }
  }, [error, formatMessage, locale, navigate, categoryType]);

  const handleSelectOptions = (options) => {
    if (options.length < 0) return [];

    return options.map((option) => {
      return {
        value: getId(option),
        label: option?.name?.[locale] || option?.subTitle?.[locale],
      };
    });
  };

  const onSubmit = async (data) => {
    if (!data?.category) {
      delete data?.category;
    }
    const isValid = await trigger();

    if (isValid) {
      if (singleCategory) {
        data.isFree = isFree;
      }
      dispatch(editCategory({ id, data, navigate, categoryType: '' }));
    } else {
      console.log('Form is not valid.');
    }

    // dispatch(editCategory({ id, data, navigate, categoryType: '' }));
  };
  // const mainCategory =
  //   mainCategories?.find((e) => getId(e) == getId(singleCategory?.category)) ||
  //   mainCategories?.find(
  //     (e) =>
  //       getId(e) ==
  //       getId(
  //         categories?.find((e) => getId(e) == getId(singleCategory?.category))
  //           ?.category
  //       )
  //   );

  if (loading) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={`editCategory_${categoryType}`} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* name  */}
                <Row>
                  {!categoryType?.includes('sub') && (
                    <Col xs={12}>
                      <div className='form-group'>
                        <h5 className='mb-2'>
                          {' '}
                          <FormattedMessage id={'specializationType'} />
                        </h5>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Select
                              name={field.name}
                              placeholder={
                                <FormattedMessage id={'specializationType'} />
                              }
                              options={handleSelectOptions(specializations)}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value);
                                setSpecialization(selectedOption);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className='basic-single'
                              classNamePrefix='select'
                              value={specialization}
                            />
                          )}
                          name='specialization'
                          // rules={{ required: !categoryType?.includes('sub') }}
                        />
                      </div>
                    </Col>
                  )}
                  <Col lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='name.ar'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='name.en'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* parert category */}
                {categoryType !== 'sub-child' &&
                  categoryType?.includes('sub') &&
                  singleCategory?.category && (
                    <Row>
                      <Col lg={12} xs={12}>
                        <div className='form-group '>
                          <h5>
                            {' '}
                            <FormattedMessage id={'mainCategory'} />
                          </h5>
                          <div>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <>
                                  <Select
                                    inputRef={ref}
                                    name={name}
                                    placeholder={
                                      <FormattedMessage id={'mainCategory'} />
                                    }
                                    options={handleSelectOptions(
                                      mainCategories
                                    )}
                                    onChange={(selectedOption) => {
                                      // -------------------------------------------------------
                                      if (categoryType == 'sub-child') {
                                        setValue('category', {});
                                        setSubCategories([]);

                                        const subCategories =
                                          categories?.filter(
                                            (category) =>
                                              category?.category?._id ===
                                              selectedOption?.value
                                          );

                                        setSubCategories(subCategories);
                                      }
                                      // --------------------------------
                                      if (categoryType !== 'sub-child')
                                        onChange(selectedOption.value);
                                    }}
                                    isRtl={true}
                                    isSearchable={false}
                                    className='basic-single'
                                    classNamePrefix='select'
                                    value={
                                      handleSelectOptions(mainCategories)?.find(
                                        (e) => e?.value == value
                                      ) ||
                                      handleSelectOptions(mainCategories)?.find(
                                        (e) => e?.value == mainCategory
                                      )
                                    }
                                  />
                                  {/* {console.log(
                                  `handleSelectOptions(mainCategories)?.find(
                                    (e) => e?.value == value
                                  ) ||
                                  handleSelectOptions(mainCategories)?.find(
                                    (e) => e?.value == mainCategory
                                  )`,
                                  handleSelectOptions(mainCategories)?.find(
                                    (e) => e?.value == value
                                  ) ||
                                    handleSelectOptions(mainCategories)?.find(
                                      (e) => e?.value == mainCategory
                                    ),
                                  `handleSelectOptions(mainCategories)?.find(
                                    (e) => e?.value == value
                                  )  `,
                                  handleSelectOptions(mainCategories)?.find(
                                    (e) => e?.value == value
                                  )
                                )} */}
                                </>
                              )}
                              name={'category'}
                              rules={{
                                required: categoryType === 'sub' ? true : false,
                              }}
                            />
                          </div>
                          <p className='error-hint'>
                            {errors?.category && (
                              <FormattedMessage id={'requiredField'} />
                            )}
                          </p>
                        </div>
                      </Col>

                      {categoryType === 'sub-child' &&
                        typeof subCategories !== 'undefined' && (
                          // subCategories.length > 0 &&
                          <Col lg={12} xs={12}>
                            <div className='form-group '>
                              <h5 className='input_head_'>
                                <FormattedMessage id={'subCategory'} />
                              </h5>
                              <div>
                                <Controller
                                  control={control}
                                  render={({
                                    field: { onChange, value, name, ref },
                                  }) => (
                                    <Select
                                      styles={{
                                        // Fixes the overlapping problem of the component
                                        menu: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      inputRef={ref}
                                      name={name}
                                      placeholder={
                                        <FormattedMessage id={'subCategory'} />
                                      }
                                      options={handleSelectOptions(
                                        subCategories
                                      )}
                                      onChange={(selectedOption) => {
                                        onChange(selectedOption.value);
                                      }}
                                      isRtl={true}
                                      isSearchable={false}
                                      className='basic-single'
                                      classNamePrefix='select'
                                      // value={defaultSubCategoryVal}
                                      value={handleSelectOptions(
                                        subCategories
                                      )?.find(
                                        ({ value: selected }) =>
                                          selected == value
                                      )}
                                    />
                                  )}
                                  name={'category'}
                                  rules={{ required: true }}
                                />
                              </div>

                              <p className='error-hint'>
                                {errors?.category && (
                                  <FormattedMessage id={'requiredField'} />
                                )}
                              </p>
                            </div>
                          </Col>
                        )}
                    </Row>
                  )}
                <div className='pb-4'>
                  <div
                    className={`content__block__ free_label  ${
                      isFree && 'check_free'
                    }`}
                    onClick={() => setIsFree((prevState) => !prevState)}
                  >
                    <span class='radio_wrapper__'>
                      <span class='check_radio_input'></span>
                    </span>
                    <p className='content_block_details__'>
                      <FormattedMessage id='avaliableForAll' />
                    </p>
                    {/* {
                    answer?.isCorrect ? <span className="correct_answer__">correct answer</span> : ""
                  } */}
                  </div>
                  <span className='check_note'>
                    <FormattedMessage id='note' />
                  </span>
                </div>
                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
