import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  ADD_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  EDIT_SUBSCRIPTION,
  GET_ALL_SUBSCRIPTIONS,
  GET_SUBSCRIPTION,
} from './actionTypes';
import {
  addSubscriptionApi,
  getAllSubscriptionsApi,
  getSubscriptionApi,
  editSubscriptionApi,
  deleteSubscriptionApi,
} from 'api/subscriptions';
import {
  addSubscriptionFailure,
  addSubscriptionSuccess,
  deleteSubscriptionFailure,
  deleteSubscriptionSuccess,
  editSubscriptionFailure,
  editSubscriptionSuccess,
  getAllSubscriptionsFailure,
  getAllSubscriptionsSuccess,
  getSubscriptionFailure,
  getSubscriptionSuccess,
} from './actions';

function* getAllSubscriptions({ payload }) {
  try {
    const { data } = yield call(getAllSubscriptionsApi, payload);

    yield put(getAllSubscriptionsSuccess(data));
  } catch (error) {
    yield put(
      getAllSubscriptionsFailure(error?.response?.data || error || error)
    );
  }
}

function* addSubscription({ payload }) {
  try {
    const { data } = yield call(addSubscriptionApi, payload.data);

    yield put(addSubscriptionSuccess(data));
    payload.navigate('/subscriptions');
  } catch (error) {
    yield put(addSubscriptionFailure(error?.response?.data || error || error));
  }
}

function* getSubscription({ payload }) {
  try {
    const { data } = yield call(getSubscriptionApi, payload);

    yield put(getSubscriptionSuccess(data));
  } catch (error) {
    yield put(getSubscriptionFailure(error?.response?.data || error || error));
  }
}

function* editSubscription({ payload }) {
  try {
    const { data } = yield call(editSubscriptionApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editSubscriptionSuccess(data));
    payload.navigate('/subscriptions');
  } catch (error) {
    yield put(editSubscriptionFailure(error?.response?.data || error || error));
  }
}

function* deleteSubscription({ payload }) {
  try {
    yield call(deleteSubscriptionApi, payload);
    yield put(deleteSubscriptionSuccess(payload));
  } catch (error) {
    yield put(
      deleteSubscriptionFailure(error?.response?.data || error || error)
    );
  }
}

export function* watchGetAllSubscriptions() {
  yield takeEvery(GET_ALL_SUBSCRIPTIONS, getAllSubscriptions);
}

export function* watchAddSubscription() {
  yield takeEvery(ADD_SUBSCRIPTION, addSubscription);
}

export function* watchGetSubscription() {
  yield takeEvery(GET_SUBSCRIPTION, getSubscription);
}

export function* watchEditSubscription() {
  yield takeEvery(EDIT_SUBSCRIPTION, editSubscription);
}

export function* watchDeleteSubscription() {
  yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscription);
}

function* subscriptionsSaga() {
  yield all([
    fork(watchGetAllSubscriptions),
    fork(watchAddSubscription),
    fork(watchGetSubscription),
    fork(watchEditSubscription),
    fork(watchDeleteSubscription),
  ]);
}

export default subscriptionsSaga;
