import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import { editReview, getReview } from 'store/actions'; // Update the import path
import Loader from 'components/shared/Loader';
import { Input } from 'components/shared/FormComponents';
import SaveChangesButton from 'components/shared/SaveChangesButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';

const EditReview = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { loading, error, singleReview } = useSelector(
    (state) => state.reviews
  ); // Update state selector

  // effects
  useEffect(() => {
    dispatch(getReview(id));
  }, [dispatch, id]);

  console.log(singleReview);
  useEffect(() => {
    reset({
      'name.ar': singleReview?.name?.ar,
      'name.en': singleReview?.name?.en,
      rate: singleReview?.rate,
      'content.ar': singleReview?.content?.ar,
      'content.en': singleReview?.content?.en,
    });
  }, [locale, reset, singleReview]);

  // error
  useEffect(() => {
    if (error) {
      console.log(error);
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
      navigate('/reviews');
    }
  }, [error, formatMessage, locale, navigate]);

  const onSubmit = (data) => {
    // Modify the structure of the data object to match your Redux actions
    const formattedData = {
      name: data.name,
      content: data.content,
      rate: data.rate,
    };

    dispatch(editReview({ id, data: formattedData, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'editReview'} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* name and rate */}
                <Row>
                  <Col className='mt-2' lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='name.ar'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col className='mt-2' lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='name.en'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col className='mt-2' lg={6} xs={12}>
                    <Input
                      langId='content'
                      name='content.ar'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col className='mt-2' lg={6} xs={12}>
                    <Input
                      langId='content'
                      name='content.en'
                      register={register}
                      errors={errors}
                    />
                  </Col>

                  <Col className='mt-2' lg={6} xs={12}>
                    <Input
                      langId='rate'
                      name='rate'
                      type='number'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReview;
