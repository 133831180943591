import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import {
  editAnnouncement,
  getAllCategories,
  getAnnouncements,
  getSingleAnnouncement,
} from "store/actions";
import Loader from "components/shared/Loader";
import { Input, Textarea } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import ImageUpload from "components/shared/ImageUpload";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { handleImage } from "helpers/functions";

const EditAnnouncement = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, announcements, singleAnnouncement } = useSelector(
    (state) => state.announcements
  );

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });

  const [transparent, setTransparent] = useState(true);
  // effects
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (announcements?.length === 0) dispatch(getAnnouncements());

    dispatch(getSingleAnnouncement(id));
  }, [announcements?.length, dispatch, id]);

  useEffect(() => {
    reset(singleAnnouncement);
    setSelectedImage({
      preview: null,
      path: singleAnnouncement?.icon,
    });
  }, [locale, reset, singleAnnouncement]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, navigate]);

  const onSubmit = (data) => {
    if (transparent) data.color = "";

    // if (!selectedImage?.path && !data.image) {
    //   toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
    //   return;
    // }

    data.image = `/uploads/${(selectedImage?.path || data.image)
      ?.split("/")
      .at(-1)}`;

    dispatch(editAnnouncement({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"editAnnouncement"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <Row>
                        {/* {announcements?.length > 0 && (
                          <Col md="9" xs="12">
                            <h5>
                              <FormattedMessage id="chooseImage" />
                            </h5>
                            <Row
                              style={{
                                alignItems: "stretch",
                                flexWrap: "nowrap",
                                overflowX: "auto",
                                height: "70%",
                                paddingBottom: "10%",
                              }}
                            >
                              {[
                                ...new Set(
                                  announcements?.map((ann) => ann?.image)
                                ),
                              ]?.map((image, index) => (
                                <Col xs="3" key={index}>
                                  <label className="text-center h-100">
                                    <img
                                      src={handleImage(image)}
                                      className="rounded-3 mb-2 img-thumbnail bg-light bg-opacity-25 w-100"
                                      style={{
                                        objectFit: "contain",
                                        height: "85%",
                                        minWidth: "50px",
                                      }}
                                      alt=""
                                    />
                                    <input
                                      type="radio"
                                      name="image"
                                      {...register("image")}
                                      value={image}
                                    />
                                  </label>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        )} */}
                        <Col md="3" xs="12">
                          <h5>
                            <FormattedMessage id="icon" />
                          </h5>
                          <ImageUpload
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} xs={12}>
                    <Input
                      langId="title"
                      name="title.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <Input
                      langId="title"
                      name="title.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <Textarea
                      langId="description"
                      name="description.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <Textarea
                      langId="description"
                      name="description.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <label className="d-flex gap-2 form-group">
                      <input
                        type="checkbox"
                        langId="color"
                        name="color"
                        onChange={(e) => setTransparent(e.target.checked)}
                        defaultChecked={transparent}
                        value={transparent}
                        className=""
                      />
                      <h5>
                        <FormattedMessage id="transparent?" />
                      </h5>
                    </label>

                    {!transparent && (
                      <Input
                        type="color"
                        langId="color"
                        name="color"
                        register={register}
                        errors={errors}
                        rules={{ required: false }}
                        className=""
                      />
                    )}
                  </Col>
                </Row>

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAnnouncement;
