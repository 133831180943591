import { v4 } from "uuid";

export const sidebarData = [
  {
    id: v4(),
    to: '/',
    text: 'home',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='#a3aed0'
        className='bi bi-speedometer2'
        viewBox='0 0 16 16'
      >
        <path d='M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z' />
        <path
          fillRule='evenodd'
          d='M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z'
        />
      </svg>
    ),
    // permissions: ["dashboard-access"]
  },

  {
    id: v4(),
    to: '/users',
    text: 'users',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-people-fill'
        viewBox='0 0 16 16'
      >
        <path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
        <path
          fillRule='evenodd'
          d='M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z'
        />
        <path d='M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z' />
      </svg>
    ),
    // permissions: ["users-index"]
  },
  // {
  //   id: v4(),
  //   to: "/subscribedUsers",
  //   text: "subscribedUsers",
  //   svg: (
  //     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#a3aed0" className="bi bi-person-plus" viewBox="0 0 16 16">
  //       <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
  //       <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
  //     </svg>
  //   ),
  //   // permissions: ["users-index"]
  // },
  {
    id: v4(),
    to: '/admins',
    text: 'admins',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-person-check'
        viewBox='0 0 16 16'
      >
        <path d='M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z' />
        <path
          fillRule='evenodd'
          d='M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z'
        />
      </svg>
    ),
    // permissions: ["users-index"]
  },

  // {
  //   id: v4(),
  //   separatedHead: true,
  //   text: "settings",
  //   to: "",
  //   svg: "",
  // permissions: [
  //   "settings-index",
  //   "pages-index",
  //   "addressTypes-index",
  //   "categories-index",
  //   "skills-index",
  //   "banks-index",
  //   "roles-index",
  //   "settings-index"
  // ]
  // },
  {
    id: v4(),
    to: '/pages',
    text: 'pages',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-file-richtext'
        viewBox='0 0 16 16'
      >
        <path d='M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z' />
        <path d='M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z' />
      </svg>
    ),
    // permissions: ["pages-index"]
  },

  {
    id: v4(),
    to: '/cities',
    text: 'cities',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-building'
        viewBox='0 0 16 16'
      >
        <path
          fillRule='evenodd'
          d='M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z'
        />
        <path d='M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z' />
      </svg>
    ),
    // permissions: ["cities-index"]
  },
  {
    id: v4(),
    to: '/universities',
    text: 'universities',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-book'
        viewBox='0 0 16 16'
      >
        <path d='M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z' />
      </svg>
    ),
    // permissions: ["universities-index"]
  },
  {
    id: v4(),
    to: '/subscriptions',
    text: 'subscriptions',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-currency-exchange'
        viewBox='0 0 16 16'
      >
        <path d='M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z' />
      </svg>
    ),
    // permissions: ["subscriptions-index"]
  },
  // {
  //   id: v4(),
  //   to: '/coupons',
  //   text: 'coupons',
  //   svg: (
  //     <svg
  //       xmlns='http://www.w3.org/2000/svg'
  //       width='16'
  //       height='16'
  //       fill='#a3aed0'
  //       className='bi bi-currency-exchange'
  //       viewBox='0 0 16 16'
  //     >
  //       <path d='M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z' />
  //     </svg>
  //   ),
  //   // permissions: ["subscriptions-index"]
  // },
  // {
  //   id: v4(),
  //   to: '/specializations',
  //   text: 'specializations',
  //   svg: (
  //     <svg
  //       xmlns='http://www.w3.org/2000/svg'
  //       width='16'
  //       height='16'
  //       fill='#a3aed0'
  //       className='bi bi-question-circle'
  //       viewBox='0 0 16 16'
  //     >
  //       <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
  //       <path d='M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z' />
  //     </svg>
  //   ),
  //   // permissions: ["questions-index"]
  // },
  {
    id: v4(),
    to: '/categories/',
    text: 'categories',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-tags'
        viewBox='0 0 16 16'
      >
        <path d='M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z' />
        <path d='M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z' />
      </svg>
    ),
    children: [
      // {
      //   id: v4(),
      //   to: "/categories/main",
      //   text: "mainCategories",
      // },
      // {
      //   id: v4(),
      //   to: "/categories/sub",
      //   text: "subCategories",
      // },
      // {
      //   id: v4(),
      //   to: "/categories/sub-child",
      //   text: "subCategoriesChild",
      // },
    ],
    // permissions: ["categories-index"]
  },
  {
    id: v4(),
    to: '/questions',
    text: 'questions',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-question-circle'
        viewBox='0 0 16 16'
      >
        <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
        <path d='M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z' />
      </svg>
    ),
    children: [
      // {
      //   id: v4(),
      //   to: "/categories/main",
      //   text: "mainCategories",
      // },
      // {
      //   id: v4(),
      //   to: "/categories/sub",
      //   text: "subCategories",
      // },
      // {
      //   id: v4(),
      //   to: "/categories/sub-child",
      //   text: "subCategoriesChild",
      // },
    ],

    // permissions: ["questions-index"]
  },
  {
    id: v4(),
    to: '/faqs',
    text: 'faqs',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='#a3aed0'
        className='bi bi-question-circle'
        viewBox='0 0 16 16'
      >
        <path d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM8 14a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm-.445-4.57a.47.47 0 0 0 .44-.57c0-.33-.29-.6-.65-.58a.47.47 0 0 0-.44.57c0 .33.29.6.65.58zm-.156-3.222c.07-.336.394-.588.782-.586.437.002.78.286.782.638a.647.647 0 0 1-.195.473c-.208.208-.642.387-.782.466-.142.082-.251.176-.297.352a.513.513 0 0 0-.02.165.75.75 0 0 0 .742.75c.34 0 .634-.246.723-.586.05-.199.177-.274.357-.405.187-.134.43-.308.487-.645.03-.167.004-.49-.297-.882-.293-.39-.673-.758-1.077-1.113-.256-.204-.55-.412-.813-.64a.75.75 0 0 1-.105-.938z' />
      </svg>
    ),
  },
  {
    id: v4(),
    to: '/blogs',
    text: 'blogs',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='#a3aed0'
        className='bi bi-journal-text'
        viewBox='0 0 16 16'
      >
        <path d='M14.5 0a1.5 1.5 0 0 1 0 3h-11a1.5 1.5 0 1 1 0-3h11zM2 4a1 1 0 0 1-1 1 1 1 0 0 1 0-2 1 1 0 0 1 1 1zM4 6a1 1 0 0 1-1 1 1 1 0 0 1 0-2 1 1 0 0 1 1 1zM14.5 6h-11a1.5 1.5 0 0 1 0-3h11a1.5 1.5 0 0 1 0 3zM2 10a1 1 0 0 1-1 1 1 1 0 0 1 0-2 1 1 0 0 1 1 1zM4 12a1 1 0 0 1-1 1 1 1 0 0 1 0-2 1 1 0 0 1 1 1zM14.5 12h-11a1.5 1.5 0 0 1 0-3h11a1.5 1.5 0 0 1 0 3zM2 16a1 1 0 0 1-1 1 1 1 0 0 1 0-2 1 1 0 0 1 1 1zM4 18a1 1 0 0 1-1 1 1 1 0 0 1 0-2 1 1 0 0 1 1 1z' />
      </svg>
    ),
  },
  {
    id: v4(),
    to: '/reviews/',
    text: 'reviews',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-tags'
        viewBox='0 0 16 16'
      >
        <path d='M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z' />
        <path d='M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z' />
      </svg>
    ),
    children: [
      // {
      //   id: v4(),
      //   to: "/categories/main",
      //   text: "mainCategories",
      // },
      // {
      //   id: v4(),
      //   to: "/categories/sub",
      //   text: "subCategories",
      // },
      // {
      //   id: v4(),
      //   to: "/categories/sub-child",
      //   text: "subCategoriesChild",
      // },
    ],
    // permissions: ["categories-index"]
  },
  {
    id: v4(),
    to: '/ethics',
    text: 'ethics',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='#a3aed0'
        width='20'
        height='20'
        viewBox='0 0 640 512'
      >
        <path d='M384 32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H320 128c-17.7 0-32-14.3-32-32s14.3-32 32-32H288V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32zM125.8 177.3L51.1 320H204.9L130.2 177.3c-.4-.8-1.3-1.3-2.2-1.3s-1.7 .5-2.2 1.3zM128 128c18.8 0 36 10.4 44.7 27l77.8 148.5c3.1 5.8 6.1 14 5.5 23.8c-.7 12.1-4.8 35.2-24.8 55.1C210.9 402.6 178.2 416 128 416s-82.9-13.4-103.2-33.5c-20-20-24.2-43-24.8-55.1c-.6-9.8 2.5-18 5.5-23.8L83.3 155c8.7-16.6 25.9-27 44.7-27zm384 48c-.9 0-1.7 .5-2.2 1.3L435.1 320H588.9L514.2 177.3c-.4-.8-1.3-1.3-2.2-1.3zm-44.7-21c8.7-16.6 25.9-27 44.7-27s36 10.4 44.7 27l77.8 148.5c3.1 5.8 6.1 14 5.5 23.8c-.7 12.1-4.8 35.2-24.8 55.1C594.9 402.6 562.2 416 512 416s-82.9-13.4-103.2-33.5c-20-20-24.2-43-24.8-55.1c-.6-9.8 2.5-18 5.5-23.8L467.3 155z' />
      </svg>
    ),
    // permissions: ["categories-index"]
  },
  {
    id: v4(),
    to: '/medias',
    text: 'medias',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-card-image'
        viewBox='0 0 16 16'
      >
        <path d='M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z' />
        <path d='M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z' />
      </svg>
    ),
    // permissions: ["categories-index"]
  },
  // {
  //   id: v4(),
  //   to: "/roles",
  //   text: "roles",
  //   svg: (
  //     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#a3aed0" className="bi bi-code-square" viewBox="0 0 16 16">
  //       <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
  //       <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z" />
  //     </svg>
  //   ),
  //   // permissions: ["roles-index"]
  // },
  {
    id: v4(),
    to: '/contactUs',
    text: 'contactUs',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-envelope'
        viewBox='0 0 16 16'
      >
        <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z' />
      </svg>
    ),
    permissions: ['contactUs-index'],
  },

  {
    id: v4(),
    to: '/settings',
    text: 'siteSettings',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-gear'
        viewBox='0 0 16 16'
      >
        <path d='M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z' />
        <path d='M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z' />
      </svg>
    ),
    // permissions: ["settings-index", "settings-update"]
  },

  {
    id: v4(),
    to: '/announcements',
    text: 'announcements',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-envelope'
        viewBox='0 0 16 16'
      >
        <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z' />
      </svg>
    ),
    permissions: ['contactUs-index'],
  },

  {
    id: v4(),
    to: '/messagesCenter',
    text: 'messagesCenter',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='#a3aed0'
        className='bi bi-envelope-at'
        viewBox='0 0 16 16'
      >
        <path d='M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z' />
        <path d='M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z' />
      </svg>
    ),
  },
];

export const firebaseConfig = {
  apiKey: "AIzaSyAxZznEff8MhJkLbBUF6b5C-V046B1c7VI",
  authDomain: "examist-6ed98.firebaseapp.com",
  databaseURL: "https://examist-6ed98-default-rtdb.firebaseio.com",
  projectId: "examist-6ed98",
  storageBucket: "examist-6ed98.appspot.com",
  messagingSenderId: "37457174691",
  appId: "1:37457174691:web:3ed6f82606f33fa6ca1f16",
  measurementId: "G-64WM1DX1NM",
};

// question types
// enum: ["checkbox", "multiselect"],
export const questionType = [
  {
    id: "checkbox",
    name: {
      ar: "اختيار واحد",
      en: "single choice",
    },
  },
  {
    id: "multiselect",
    name: {
      ar: "اختيار من متعدد",
      en: "multichoice",
    },
  },
];
