import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import {
  deleteQuestion,
  deleteQuestionComment,
  getQuestion,
} from "store/actions";
import { useState } from "react";
import LightBox from "components/shared/LightBox";
import userImage from "assets/images/user.png";
import quesMark from "assets/images/ques-mark.png";
import QuestionCommentsTab from "./QuestionCommentsTab";
import Accordion from "react-bootstrap/Accordion";
import QuestionComments from "./QuestionComments";
import ShowQuestionTab from "./ShowQuestionTab";
import { getQuestionApi } from "api/questions";

export const extractImagesSrcsNodes = (htmlString) => {
  let parsed = new window.DOMParser().parseFromString(htmlString, "text/html");

  return [...parsed.querySelectorAll("img")].map((img) => {
    return img?.src;
  });
};

const ShowQuestion = ({
  singleQuestion,
  setEditModal,
  currentIndex,
  setSingleQuestion,
  loading,
}) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [imagesSrcs, setIMagesSrcs] = useState([]);
  const [explantionsimagesSrcs, setExplantionsIMagesSrcs] = useState([]);

  // const { loading, error, singleQuestion } = useSelector(
  //   (state) => state.questions
  // );

  useEffect(() => {
    const srcs = extractImagesSrcsNodes(singleQuestion?.title);
    setIMagesSrcs(srcs);

    const explantionsSrcs = extractImagesSrcsNodes(singleQuestion?.explanation);
    setExplantionsIMagesSrcs(explantionsSrcs);
  }, [singleQuestion]);

  const handleQuestionDelete = (id) => {
    dispatch(deleteQuestion(id));
  };

  // effects
  // useEffect(() => {
  //   dispatch(getQuestion('636ce963f6369ce52307de13'));
  // }, [dispatch, id]);

  // error
  // useEffect(() => {
  //   if (error) {
  //     toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
  //     navigate('/questions');
  //   }
  // }, [error, formatMessage, locale, navigate]);

  // const handleDeleteComment = (commentId) => {
  //   dispatch(deleteQuestionComment({ id: id, commentId: commentId }));
  // };

  console.log("singleQuestion", singleQuestion);

  const renderExplanation = () => {
    return (
      <Accordion className="explanation-accordin">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <FormattedMessage id="explanation" />
          </Accordion.Header>{" "}
          <Accordion.Body>
            <div className="question-explanation card">
              <div className="card-body">
                <Row className="justify-content-between">
                  <Col lg={explantionsimagesSrcs ? 7 : 12}>
                    <div className="card-head ">
                      <h4>
                        <FormattedMessage id="explanation" />
                      </h4>
                    </div>
                    <div className="explanation-content mt-2">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: singleQuestion?.explanation,
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <LightBox imagesSrcs={explantionsimagesSrcs} />
                  </Col>
                </Row>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const renderComments = () => {
    return (
      <Accordion className="comments-accordin">
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <FormattedMessage id="comments" />
          </Accordion.Header>
          <Accordion.Body>
            <QuestionComments singleQuestion={singleQuestion} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  // if (loading) return <Loader />;

  const handleQuestionEdit = async (id) => {
    try {
      setSingleQuestion((state) => {
        return {
          ...state,
          loading: true,
        };
      });

      const response = await getQuestionApi(id);

      setSingleQuestion((state) => {
        return {
          ...state,
          loading: false,
          question: response?.data?.question,
          view: "edit",
        };
      });
    } catch (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  };

  return (
    <>
      <div className="show-question-section card border-0 mt-4">
        <div className="question_number__ mb-4 d-flex align-items-center  gap-2">
          <figure className="img_block text-center">
            <img src={quesMark} alt={quesMark} />
            {/* <Shimmer src={clock} width={20} height={20} alt={clock} /> */}
          </figure>
          <span className="primary-color">
            {/* {singleQuestion?.questionNumber?.[locale]} */}
            <FormattedMessage id="showQuestionNumber" /> ({currentIndex + 1})
          </span>
        </div>
        <div className="card-body">
          <div className="card-head mb-0 question_show_heading_ d-ltr show_title__">
            <div
              className="question-content__ "
              dangerouslySetInnerHTML={{
                __html: singleQuestion?.title,
              }}
            ></div>
          </div>
          <Row className="d-ltr">
            <Col sm={12} md={6} lg={3}>
              <LightBox imagesSrcs={imagesSrcs} />
            </Col>
          </Row>
          <div className="answers-content">
            <Row>
              {singleQuestion?.answers?.map((answer) => {
                return (
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className="m-b-15"
                    key={answer?._id}
                  >
                    <div
                      className={`content__block__ answer-block ${
                        answer?.isCorrect ? "correct" : "wrong"
                      }`}
                    >
                      {/* <h5 className='content_block_head__'>
                        {' '}
                        {answer?.index}{' '}
                      </h5> */}
                      <span class="radio_wrapper__">
                        <span class="check_radio_input"></span>
                      </span>
                      <p className="content_block_details__">
                        {answer?.answer}
                      </p>
                      {/* {
                      answer?.isCorrect ? <span className="correct_answer__">correct answer</span> : ""
                    } */}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        {renderExplanation()}
        {/* {renderComments()} */}
        <div className="question_buttons__ text-start">
          <button
            className="question_button__"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleQuestionEdit(singleQuestion?.id);
            }}
          >
            {!loading ? (
              <>
                <div className="shape__">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2603 4.10022L5.05034 12.7902C4.74034 13.1202 4.44034 13.7702 4.38034 14.2202L4.01034 17.4602C3.88034 18.6302 4.72034 19.4302 5.88034 19.2302L9.10034 18.6802C9.55034 18.6002 10.1803 18.2702 10.4903 17.9302L18.7003 9.24022C20.1203 7.74022 20.7603 6.03022 18.5503 3.94022C16.3503 1.87022 14.6803 2.60022 13.2603 4.10022V4.10022Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.89 5.5498C12.0996 6.89066 12.7486 8.12394 13.735 9.05605C14.7214 9.98816 15.9894 10.5663 17.34 10.6998M3 22.4998H21"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <FormattedMessage id="edit" />
              </>
            ) : (
              <FormattedMessage id={"loading..."} />
            )}
          </button>
          <button
            className="question_button__ delete__"
            onClick={(e) => {
              e.preventDefault();
              handleQuestionDelete(singleQuestion?.id);
            }}
          >
            <div className="shape__">
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.17 14.33L13.83 8.67M13.83 14.33L8.17 8.67M11 21.5C16.5 21.5 21 17 21 11.5C21 6 16.5 1.5 11 1.5C5.5 1.5 1 6 1 11.5C1 17 5.5 21.5 11 21.5Z"
                  stroke="#E80000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <FormattedMessage id="delete" />
          </button>
        </div>
      </div>

      {/* <div className='question-explanation card'>
        <div className='card-body'>
          <div className='card-head '>
            <h4>
              <FormattedMessage id='explanation' />
            </h4>
          </div>
          <div className='explanation-content mt-2'>
            <div
              dangerouslySetInnerHTML={{ __html: singleQuestion?.explanation }}
            />
          </div>
          <LightBox imagesSrcs={explantionsimagesSrcs} />
        </div>
      </div> */}

      {/* {singleQuestion?.comments?.length > 0 && (
        <div className='question-comments card mt-4'>
          <div className='card-body'>
            <div className='card-head '>
              <h4>
                <FormattedMessage id='comments' />
              </h4>
            </div>
            <div className='comments_content__'>
              <Row>
                {singleQuestion?.comments?.map((comment) => {
                  return (
                    <Col lg={6} md={12} className='m-b-15' key={comment?._id}>
                      <div className='comment_block content__block__'>
                        <Link
                          className=' comment_user '
                          to={`/users/show/${comment?.user?.id}`}
                        >
                          <div className='img_block__'>
                            <img
                              src={comment?.user?.image || userImage}
                              alt=''
                            />
                          </div>
                          <div className='desc__'>
                            <p className='name__'>{comment?.user?.fullName}</p>
                            <p className='date__'>
                              {
                                <FormattedDate
                                  value={comment?.createdAt}
                                  day='numeric'
                                  month='long'
                                  year='numeric'
                                />
                              }
                            </p>
                          </div>
                        </Link>
                        <p className='comment-details content_block_details__'>
                          {comment?.comment}
                        </p>
                        <button
                          className='delete_comment_'
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteComment(comment?._id);
                          }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='12'
                            height='12'
                            fill='#fff'
                            className='bi bi-trash delete'
                            viewBox='0 0 16 16'
                          >
                            <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z' />
                            <path
                              fillRule='evenodd'
                              d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'
                            />
                          </svg>
                        </button>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ShowQuestion;
