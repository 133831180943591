import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { addAnnouncement, getAnnouncements } from 'store/actions';
import Loader from 'components/shared/Loader';
import AddButton from 'components/shared/AddButton';
import { Input, Textarea } from 'components/shared/FormComponents';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import ImageUpload from 'components/shared/ImageUpload';
import { handleImage } from 'helpers/functions';
import Select from 'react-select';
import { getId } from 'helpers/functions';

const AddAnnouncement = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [specialization, setSpecialization] = useState({
    value: '',
    label: '',
  });
  const { announcements, loading, error } = useSelector(
    (state) => state.announcements
  );
  const { specializations, loading: specialLoader } = useSelector(
    (state) => state.specializations
  );

  const [selected, setSelected] = useState('allUsers');

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: '',
  });
  const [transparent, setTransparent] = useState(true);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { color: '#00000000' } });

  const handleSelectOptions = useCallback(
    (options) => {
      if (options.length < 0) return [];

      return options.map((option) => {
        return {
          value: getId(option),
          label: option?.subTitle?.[locale],
        };
      });
    },
    [locale]
  );

  useEffect(() => {
    if (announcements?.length === 0) dispatch(getAnnouncements());
  }, [announcements?.length, dispatch]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
    }
  }, [error, formatMessage, locale, navigate]);

  const onSubmit = (data) => {
    if (transparent) data.color = '';

    // if (!selectedImage?.path && !data.image) {
    //   toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
    //   return;
    // }

    console.log('data.image', data.image);
    console.log('selectedImage?.path', selectedImage?.path);
    data.image = (selectedImage?.path || data.image)?.split('/').at(-1);

    data.type = selected;
    dispatch(addAnnouncement({ data, navigate }));
  };

  // handle Change
  const handleSelect = (e) => {
    const { value } = e.target;
    if (selected === value) {
      setSelected(null);
    } else {
      setSelected(value);
    }
    console.log(value);
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'addAnnouncement'} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <Col xl={8} md={12}> */}

              <Row>
                <Col lg={12} xs={12}>
                  <div className='form-group'>
                    <Row>
                      {/* {announcements?.length > 0 && (
                          <Col md="9" xs="12">
                            <h5>
                              <FormattedMessage id="chooseImage" />
                            </h5>
                            <Row
                              style={{
                                alignItems: "stretch",
                                flexWrap: "nowrap",
                                overflowX: "auto",
                                height: "70%",
                                paddingBottom: "10%",
                              }}
                            >
                              {[
                                ...new Set(
                                  announcements?.map((ann) => ann?.image)
                                ),
                              ]?.map((image, index) => (
                                <Col xs="3" key={index}>
                                  <label className="text-center h-100">
                                    <img
                                      src={handleImage(image)}
                                      className="rounded-3 mb-2 img-thumbnail bg-light bg-opacity-25 w-100"
                                      style={{
                                        objectFit: "contain",
                                        height: "85%",
                                        minWidth: "50px",
                                      }}
                                      alt=""
                                    />
                                    <input
                                      type="radio"
                                      name="image"
                                      {...register("image")}
                                      value={image}
                                    />
                                  </label>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        )} */}
                      <Col md='3' xs='12'>
                        <h5>
                          <FormattedMessage id='icon' />
                        </h5>
                        <ImageUpload
                          selectedImage={selectedImage}
                          setSelectedImage={setSelectedImage}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6} xs={12}>
                  <Input
                    langId='title'
                    name='title.ar'
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Input
                    langId='title'
                    name='title.en'
                    register={register}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12}>
                  <Textarea
                    langId='description'
                    name='description.ar'
                    register={register}
                    errors={errors}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <Textarea
                    langId='description'
                    name='description.en'
                    register={register}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12}>
                  <label className='d-flex gap-2 form-group'>
                    <input
                      type='checkbox'
                      langId='color'
                      name='color'
                      onChange={(e) => setTransparent(e.target.checked)}
                      defaultChecked={transparent}
                      value={transparent}
                      className='annu_input'
                      />
                    <h5>
                      <FormattedMessage id='transparent?' />
                    </h5>
                  </label>

                  {!transparent && (
                    <Input
                    
                      type='color'
                      langId='color'
                      name='color'
                      register={register}
                      errors={errors}
                      rules={{ required: false }}
                      className=''
                      // colorformat="rgba" // not working
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <div className='mb-3'>
                  <Col xl={12} md={12}>
                    <Row>
                      <Col lg={6} xs={12}>
                        <div className='select_all_users_'>
                          <div>
                            <label
                              htmlFor='allUsers'
                              className='checkbox-label'
                            >
                              <input
                              className='annu_input'
                                id='allUsers'
                                type='checkbox'
                                value={'allUsers'}
                                checked={selected === 'allUsers' ? true : false}
                                onChange={handleSelect}
                              />

                              <span className='text__'>
                                <FormattedMessage id='selectAllUsers' />{' '}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label
                              htmlFor='selectDoctors'
                              className='checkbox-label'
                            >
                              <input
                              className='annu_input'
                                id='selectDoctors'
                                type='checkbox'
                                value={'doctor'}
                                checked={selected === 'doctor' ? true : false}
                                onChange={handleSelect}
                              />

                              <span className='text__'>
                                <FormattedMessage id='selectDoctors' />{' '}
                              </span>
                            </label>
                          </div>
                          <div>
                            <label
                              htmlFor='selectNurse'
                              className='checkbox-label'
                            >
                              <input
                              className='annu_input'
                                id='selectNurse'
                                type='checkbox'
                                value={'nurse'}
                                checked={selected === 'nurse' ? true : false}
                                onChange={handleSelect}
                              />

                              <span className='text__'>
                                <FormattedMessage id='selectNurses' />{' '}
                              </span>
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Row>
              <AddButton />
              {/* </Col> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAnnouncement;
