import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

const AnswersArray = ({ fields, register, getValues, setValue, errors }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="edit_question_answers__">
      <h3 className="edit_question_main_head_">
        <figure className="shape__">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 12.6816V9.68164C22 4.68164 20 2.68164 15 2.68164H9C4 2.68164 2 4.68164 2 9.68164V15.6816C2 20.6816 4 22.6816 9 22.6816H12" stroke="#12C8CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.9602 18.5221L19.3302 19.0721C18.8802 19.2221 18.5202 19.5721 18.3702 20.0321L17.8202 21.6621C17.3502 23.0721 15.3702 23.0421 14.9302 21.6321L13.0802 15.6821C12.7202 14.5021 13.8102 13.4021 14.9802 13.7721L20.9402 15.6221C22.3402 16.0621 22.3602 18.0521 20.9602 18.5221Z" stroke="#12C8CB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </figure>

        <FormattedMessage id={"answers"} />
        <span>
          <FormattedMessage id="YouCanChooseTheCorrectAnswer" />
        </span>
      </h3>
      <Row>
        {fields.map((item, index, allItems) => {

          return (
            <Col xl={6} lg={6} md={6} xs={12} key={index}>
              <div key={item?.id} className="edit_question_answer_block__">
                <label htmlFor={item?.id} className="checkbox-label">
                  <input
                    id={item?.index}
                    type="radio"
                    defaultValue={`checked`}
                    checked={item?.isCorrect}

                    onChange={(e) => {
                      // change radio 
                      const filterValues = [...getValues("answers")]?.map((answer, index) => {
                        if (answer?.index === e.target.getAttribute("id")) {
                          answer.isCorrect = true
                        } else {
                          answer.isCorrect = false
                        }
                        return answer;

                      });
                      setValue("answers", filterValues)
                    }}

                  />

                </label>
                <input
                  type="text"
                  className="form-control form-outline"
                  placeholder={`${formatMessage({ id: "Answer " })} ( ${index + 1} )`}
                  {...register(`answers.${index}.answer`, {
                    required: true,
                  })}
                />
                <p className="error-hint">
                  {errors?.answers?.[index]?.["answer"]?.type === "required" &&
                    <FormattedMessage id={"requiredField"} />}
                </p>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default AnswersArray;
