export const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
export const GET_ALL_SETTINGS_SUCCESS = "GET_ALL_SETTINGS_SUCCESS";
export const GET_ALL_SETTINGS_FAILURE = "GET_ALL_SETTINGS_FAILURE";

export const EDIT_MAIN_SETTINGS = "EDIT_MAIN_SETTINGS";
export const EDIT_MAIN_SETTINGS_SUCCESS = "EDIT_MAIN_SETTINGS_SUCCESS";
export const EDIT_MAIN_SETTINGS_FAILURE = "EDIT_MAIN_SETTINGS_FAILURE";

export const EDIT_SOCIALS_SETTINGS = "EDIT_SOCIALS_SETTINGS";
export const EDIT_SOCIALS_SETTINGS_SUCCESS = "EDIT_SOCIALS_SETTINGS_SUCCESS";
export const EDIT_SOCIALS_SETTINGS_FAILURE = "EDIT_SOCIALS_SETTINGS_FAILURE";

export const EDIT_MAIL_SETTINGS = "EDIT_MAIL_SETTINGS";
export const EDIT_MAIL_SETTINGS_SUCCESS = "EDIT_MAIL_SETTINGS_SUCCESS";
export const EDIT_MAIL_SETTINGS_FAILURE = "EDIT_MAIL_SETTINGS_FAILURE";

