import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Col } from 'react-bootstrap';

import Filter from 'components/shared/Filter';
import Loader from 'components/shared/Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { sendAlertEmailToUser } from 'api/sendMail';
// import { sendAlertEmailToUser } from "api/subscribedUsers";
const Table = (props) => {
  const { formatMessage, locale } = useIntl();

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  console.log('filteredItems', props?.data);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const sendEmail = async (id) => {
    try {
      await sendAlertEmailToUser(id);

      toast.success(formatMessage({ id: 'messageHasBeenSent' }), {
        position: locale === 'ar' ? 'bottom-left' : 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error(formatMessage({ id: 'sendingErrorMessage' }), {
        position: locale === 'ar' ? 'bottom-left' : 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleReminderClick = (id, name) => {
    Swal.fire({
      text: `${formatMessage({
        id: 'areYouSureYouWantToSendReminderMessage',
      })} ( ${name} )`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: formatMessage({ id: 'send' }),
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: formatMessage({ id: 'close' }),
    }).then((result) => {
      if (result.isConfirmed) {
        sendEmail(id);
      }
    });
  };

  const columns = [
    {
      name: <FormattedMessage id={'userName'} />,
      selector: (row) => `${row?.user?.fullName || ''} `,
      sortable: true,
    },
    {
      name: <FormattedMessage id={'accountType'} />,
      selector: (row) => `${row?.user?.type || ''} `,
      sortable: true,
    },
    {
      name: <FormattedMessage id={'remainingTime'} />,
      selector: (row) =>
        `${row?.subscriptionDaysReminder} ${formatMessage({ id: 'days' })}`,
      sortable: true,
    },

    {
      cell: (row) => {
        return (
          <>
            <div className='send_riminder_message_wrapper__'>
              <button
                className='send_riminder_message_button__ btn btn-blue'
                onClick={() => {
                  handleReminderClick(row?._id, row?.user?.fullName);
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  fill='#fff'
                  className='bi bi-bell-fill'
                  viewBox='0 0 16 16'
                >
                  <path d='M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z' />
                </svg>
                <FormattedMessage id='reminderMessage' />
              </button>
            </div>
          </>
        );
      },
      // allowOverflow: true,
      // button: true,
    },
  ];

  return (
    <>
      <div className='table-wrap'>
        <div className='card'>
          <Col xl={6} md={12} xs={12}>
            <div className='card-head'>
              <div>
                <h4>
                  <FormattedMessage id={'usersTableShow'} />{' '}
                </h4>
                <p>
                  {' '}
                  <FormattedMessage id={'usersTableShowAll'} />
                </p>
              </div>
            </div>
          </Col>
          <div className='card-body'>
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField='name'
              // selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              // selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
