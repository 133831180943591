import {
  ADD_SPECIALIZATION,
  ADD_SPECIALIZATION_FAILURE,
  ADD_SPECIALIZATION_SUCCESS,
  DELETE_SPECIALIZATION,
  DELETE_SPECIALIZATION_FAILURE,
  DELETE_SPECIALIZATION_SUCCESS,
  EDIT_SPECIALIZATION,
  EDIT_SPECIALIZATION_FAILURE,
  EDIT_SPECIALIZATION_SUCCESS,
  GET_ALL_SPECIALIZATIONS,
  GET_ALL_SPECIALIZATIONS_FAILURE,
  GET_ALL_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATION,
  GET_SPECIALIZATION_FAILURE,
  GET_SPECIALIZATION_SUCCESS,
} from './actionTypes';

export const getAllSpecializations = (payload) => {
  return {
    type: GET_ALL_SPECIALIZATIONS,
    payload: payload,
  };
};

export const getAllSpecializationsSuccess = (payload) => {
  return {
    type: GET_ALL_SPECIALIZATIONS_SUCCESS,
    payload: payload,
  };
};

export const getAllSpecializationsFailure = (error) => {
  return {
    type: GET_ALL_SPECIALIZATIONS_FAILURE,
    payload: error,
  };
};

export const addSpecialization = (payload) => {
  return {
    type: ADD_SPECIALIZATION,
    payload: payload,
  };
};

export const addSpecializationSuccess = (payload) => {
  return {
    type: ADD_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};

export const addSpecializationFailure = (error) => {
  return {
    type: ADD_SPECIALIZATION_FAILURE,
    payload: error,
  };
};

export const getSpecialization = (payload) => {
  return {
    type: GET_SPECIALIZATION,
    payload: payload,
  };
};

export const getSpecializationSuccess = (payload) => {
  return {
    type: GET_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};

export const getSpecializationFailure = (error) => {
  return {
    type: GET_SPECIALIZATION_FAILURE,
    payload: error,
  };
};

export const editSpecialization = (payload) => {
  return {
    type: EDIT_SPECIALIZATION,
    payload: payload,
  };
};

export const editSpecializationSuccess = (payload) => {
  return {
    type: EDIT_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};

export const editSpecializationFailure = (error) => {
  return {
    type: EDIT_SPECIALIZATION_FAILURE,
    payload: error,
  };
};

export const deleteSpecialization = (payload) => {
  return {
    type: DELETE_SPECIALIZATION,
    payload: payload,
  };
};

export const deleteSpecializationSuccess = (payload) => {
  return {
    type: DELETE_SPECIALIZATION_SUCCESS,
    payload: payload,
  };
};

export const deleteSpecializationFailure = (error) => {
  return {
    type: DELETE_SPECIALIZATION_FAILURE,
    payload: error,
  };
};
