import React from 'react';
import './toggleSwitch.css';
const ToggleSwitch = ({ id, checked, onChange, classess }) => {
  return (
    <div className='toggle-switch'>
      <input
        type='checkbox'
        className={`toggle-switch-checkbox d-none ${classess}`}
        name={'type'}
        id={id ? id : 'type'}
        onChange={onChange}
        checked={checked}
      />
      <label className='toggle-switch-label' htmlFor={id}>
        <span className='toggle-switch-inner' />
        <span className='toggle-switch-switch' />
      </label>
    </div>
  );
};

export default ToggleSwitch;
