import server from "./server";

export const getAllCategoriesApi = async ({ specialization }) => {
  const response = await server().get(
    `/categories?specialization=${specialization}`
  );

  return response.data;
};

export const addCategoryApi = async (data) => {
  const response = await server().post("/categories", data);

  return response.data;
};

export const getCategoryApi = async (id) => {
  const response = await server().get(`/categories/summary/${id}`);

  return response.data;
};

export const editCategoryApi = async ({ id, data }) => {
  const response = await server().put(`/categories/${id}`, data);

  return response.data;
};

export const deleteCategoryApi = async (id) => {
  const response = await server().delete(`/categories/${id}`);

  return response.data;
};
