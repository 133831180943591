import server from "./server";

// export const addFaqApi = async (data) => {
//   const response = await server().post("/faqs", data);

//   return response.data;
// };

export const getAllFaqsApi = async (searchParams) => {
  const response = await server().get(`/faqs/?${searchParams}`);

  return response.data;
};

export const addFaqApi = async (data) => {
  const response = await server().post("/faqs", data);

  return response.data;
};

export const getFaqApi = async (id) => {
  const response = await server().get(`/faqs/${id}`);

  return response.data;
};

export const editFaqApi = async ({ id, data }) => {
  const response = await server().patch(`/faqs/${id}`, data);

  return response.data;
};

export const deleteFaqApi = async (id) => {
  const response = await server().delete(`/faqs/${id}`);

  return response.data;
};

export const deleteFaqCommentApi = async ({ id, commentId }) => {
  const response = await server().delete(`/faqs/${id}/${commentId}`);

  return response.data;
};
