import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { GET_ALL_SUBSCRIBED_USERS } from "./actionTypes";
import { getAllSubscribedUsersApi } from "api/subscribedUsers";
import {
  // addSubscribedUserFailure,
  // addSubscribedUserSuccess,
  getAllSubscribedUsersFailure,
  getAllSubscribedUsersSuccess,
} from "./actions";

function* getAllSubscribedUsers() {
  try {
    const { data } = yield call(getAllSubscribedUsersApi);

    yield put(getAllSubscribedUsersSuccess(data));
  } catch (error) {
    yield put(
      getAllSubscribedUsersFailure(error?.response?.data || error || error)
    );
  }
}

// function* addSubscribedUser({ payload }) {

//   try {
//     const { data } = yield call(addSubscribedUserApi, payload.data);

//     yield put(addSubscribedUserSuccess(data));
//     payload.navigate("/subscribedUsers");

//   } catch (error) {

//     yield put(addSubscribedUserFailure(error?.response?.data||error || error));
//   }
// }

export function* watchGetAllSubscribedUsers() {
  yield takeEvery(GET_ALL_SUBSCRIBED_USERS, getAllSubscribedUsers);
}

// export function* watchAddSubscribedUser() {
//   yield takeEvery(ADD_SUBSCRIBED_USER, addSubscribedUser);
// }

function* subscribedUsersSaga() {
  yield all([
    fork(watchGetAllSubscribedUsers),
    // fork(watchAddSubscribedUser),
  ]);
}

export default subscribedUsersSaga;
