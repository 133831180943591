import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import categoriesSaga from "./categories/saga";
import settingsSaga from "./settings/saga";
import pagesSaga from "./pages/saga";
import usersSaga from "./users/saga";
import rolesSaga from "./roles/saga";
import permissionsSaga from "./permissions/saga";
import contactsSaga from "./contacts/saga";
import citiesSaga from "./cities/saga";
import UniversitiesSaga from "./universities/saga";
import subscriptionsSaga from "./subscriptions/saga";
import questionsSaga from "./questions/saga";
import mediasSaga from "./medias/saga";
import ethicsSaga from "./ethics/saga";
import adminsSaga from "./admins/saga";
import subscribedUsersSaga from "./subscribedUsers/saga";
import announcementSaga from "./announcements/saga";
import reviewsSaga from "./reviews/saga";
import specializationsSaga from "./specialization/saga";
import couponsSaga from "./coupons/saga";
import blogsSaga from "./blogs/saga";
import faqsSaga from "./faqs/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    categoriesSaga(),
    settingsSaga(),
    pagesSaga(),
    usersSaga(),
    rolesSaga(),
    permissionsSaga(),
    contactsSaga(),
    citiesSaga(),
    UniversitiesSaga(),
    subscriptionsSaga(),
    questionsSaga(),
    mediasSaga(),
    ethicsSaga(),
    adminsSaga(),
    subscribedUsersSaga(),
    announcementSaga(),
    reviewsSaga(),
    specializationsSaga(),
    couponsSaga(),
    blogsSaga(),
    faqsSaga(),
  ]);
}
