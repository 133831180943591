import { getId, handleIsoDate, isObjectEmpty } from "helpers/functions";
import { useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import FormLoader from "components/shared/FormLoader";
// import "./questions.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { addQuestion } from "store/actions";
import { Link } from "react-router-dom";
import ShowBlog from "./ShowBlog";

const Table = ({ data: allBlogs }) => {
  const { locale, formatMessage } = useIntl();

  console.log({ allBlogs });

  const renderBlogs = () => {
    return (
      <div className=" questions_list__">
        {allBlogs?.length ? (
          allBlogs?.map((blog, index) => {
            return (
              <Accordion.Item eventKey={blog?.id} key={blog?.id}>
                <Accordion.Header
                  className="question_block__ hide"
                  title={blog?.title[locale]}
                >
                  {/* <div
                    className="col__ title__"
                    dangerouslySetInnerHTML={{
                      __html: blog?.title[locale],
                    }}
                  ></div> */}
                  <p className="col__ title__">{blog?.title[locale]}</p>

                  <div className="col__ date__ ">
                    <span className="d-flex align-items-center">
                      {handleIsoDate(blog?.createdAt)}
                    </span>
                  </div>
                  <div className="col__ date__ d-flex justify-content-evenly align-content-center gap-2">
                    <div
                      className="btn btn-blue accordin-btn d-flex flex-nowrap gap-0"
                      title={formatMessage({ id: "showDetails" })}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        className="bi bi-eye-fill add"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                      </svg>
                      <span>
                        <FormattedMessage id="showAllDetails" />
                      </span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ShowBlog singleBlog={blog} />
                </Accordion.Body>
              </Accordion.Item>
            );
          })
        ) : (
          <div className="form-group">
            <h5 className="safari-warn alert alert-warning bg-secondary text-center text-white">
              <FormattedMessage id="noBlogs" />
            </h5>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="questions_table_content__">
        <Accordion defaultActiveKey={allBlogs?.[0]?.id}>
          {renderBlogs()}
        </Accordion>
      </div>
    </>
  );
};

export default Table;
