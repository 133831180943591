import {
  ADD_ETHIC,
  ADD_ETHIC_FAILURE,
  ADD_ETHIC_SUCCESS,

  DELETE_ETHIC,
  DELETE_ETHIC_FAILURE,
  DELETE_ETHIC_SUCCESS,

  EDIT_ETHIC,
  EDIT_ETHIC_FAILURE,
  EDIT_ETHIC_SUCCESS,

  GET_ALL_ETHICS,
  GET_ALL_ETHICS_FAILURE,
  GET_ALL_ETHICS_SUCCESS,

  GET_ETHIC,
  GET_ETHIC_FAILURE,
  GET_ETHIC_SUCCESS
} from "./actionTypes";


export const getAllEthics = (payload) => {
  return {
    type: GET_ALL_ETHICS,
    payload: payload,
  };
}
export const getAllEthicsSuccess = (payload) => {
  return {
    type: GET_ALL_ETHICS_SUCCESS,
    payload: payload,
  };
}

export const getAllEthicsFailure = (error) => {
  return {
    type: GET_ALL_ETHICS_FAILURE,
    payload: error,
  };
}

export const addEthic = (payload) => {
  return {
    type: ADD_ETHIC,
    payload: payload,
  };
}
export const addEthicSuccess = (payload) => {
  return {
    type: ADD_ETHIC_SUCCESS,
    payload: payload,
  };
}

export const addEthicFailure = (error) => {
  return {
    type: ADD_ETHIC_FAILURE,
    payload: error,
  };
}

export const getEthic = (payload) => {
  return {
    type: GET_ETHIC,
    payload: payload,
  };
}
export const getEthicSuccess = (payload) => {
  return {
    type: GET_ETHIC_SUCCESS,
    payload: payload,
  };
}

export const getEthicFailure = (error) => {
  return {
    type: GET_ETHIC_FAILURE,
    payload: error,
  };
}

export const editEthic = (payload) => {
  return {
    type: EDIT_ETHIC,
    payload: payload,
  };
}
export const editEthicSuccess = (payload) => {
  return {
    type: EDIT_ETHIC_SUCCESS,
    payload: payload,
  };
}

export const editEthicFailure = (error) => {
  return {
    type: EDIT_ETHIC_FAILURE,
    payload: error,
  };
}

export const deleteEthic = (payload) => {
  return {
    type: DELETE_ETHIC,
    payload: payload,
  };
}

export const deleteEthicSuccess = (payload) => {
  return {
    type: DELETE_ETHIC_SUCCESS,
    payload: payload,
  };
}

export const deleteEthicFailure = (error) => {
  return {
    type: DELETE_ETHIC_FAILURE,
    payload: error,
  };
}