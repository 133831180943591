// import BootyPagination from 'components/shared/Pagination';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';


import { getAllContacts } from "store/actions";

import Table from './Table';


const AdvirtiseType = () => {
  const { contacts, loading } = useSelector((state => state.contacts));
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getAllContacts(searchParams))
  }, [dispatch, searchParams]);

  return (
    <div className="property-wrap">
      <Table data={contacts} loading={loading} />
      {/* <BootyPagination metaData={metadata} /> */}
    </div>
  )
}

export default AdvirtiseType;