import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { addMedia, getAllCategories } from "store/actions";
import Loader from "components/shared/Loader";
import AddButton from "components/shared/AddButton";
import { Textarea } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import Select from "react-select";
import ImageUpload from "components/shared/ImageUpload";
import { getId } from "helpers/functions";

const AddMedia = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.medias);
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const { categories } = useSelector((state) => state.categories);
  const [subCategories, setSubCategories] = useState([]);

  const [subCategoriesChildren, setSubCategoriesChildren] = useState([]);

  const mainCategories = categories?.filter((category) => !category?.category);
  const [defaultSubCategoryVal, setDefaultSubCategoryVal] = useState({
    value: "",
    label: "",
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getAllCategories(""));
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      navigate("/medias");
    }
  }, [error, formatMessage, locale, navigate]);

  const handleSelectOptions = (options) => {
    if (options.length < 0) return [];

    return options.map((option) => {
      return {
        value: option?.id || option?._id,
        label: option?.name?.[locale],
      };
    });
  };

  const onSubmit = (data) => {
    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }

    data.image = `/uploads/${selectedImage?.path?.split("/").at(-1)}`;
    delete data.mainCategory;
    dispatch(addMedia({ data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"addMedia"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"image"} />
                      </h5>

                      <ImageUpload
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} xs={12}>
                    <Textarea
                      langId="description"
                      name="description"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* mainCategory */}
                {
                  <Row>
                    <Col lg={12} xs={12}>
                      <div className="form-group ">
                        <h5>
                          {" "}
                          <FormattedMessage id={"mainCategory"} />
                        </h5>
                        <div>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                inputRef={ref}
                                name={name}
                                placeholder={
                                  <FormattedMessage id={"mainCategory"} />
                                }
                                options={handleSelectOptions(mainCategories)}
                                onChange={(selectedOption) => {
                                  setDefaultSubCategoryVal({
                                    value: "",
                                    label: "",
                                  });

                                  const subCategories = categories?.filter(
                                    (category) =>
                                      category?.category?._id ===
                                      selectedOption?.value
                                  );
                                  setSubCategories(subCategories);

                                  onChange(selectedOption.value);
                                }}
                                isRtl={true}
                                isSearchable={false}
                                className="basic-single"
                                classNamePrefix="select"
                              />
                            )}
                            name={"mainCategory"}
                            rules={{ required: true }}
                          />
                        </div>

                        <p className="error-hint">
                          {errors?.mainCategory && (
                            <FormattedMessage id={"requiredField"} />
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                }

                {/* sub category */}
                {typeof subCategories !== "undefined" &&
                  subCategories.length > 0 && (
                    <Row>
                      <Col lg={12} xs={12}>
                        <div className="form-group ">
                          <h5>
                            {" "}
                            <FormattedMessage id={"subCategory"} />{" "}
                          </h5>
                          <div>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <Select
                                  inputRef={ref}
                                  name={name}
                                  placeholder={
                                    <FormattedMessage id={"subCategory"} />
                                  }
                                  options={handleSelectOptions(subCategories)}
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption.value);
                                    // --------------------------------------------
                                    setSubCategoriesChildren(
                                      categories?.filter(
                                        (category) =>
                                          getId(category?.category) ===
                                          selectedOption?.value
                                      )
                                    );
                                    // ------------------------------------
                                    setDefaultSubCategoryVal(selectedOption);
                                  }}
                                  isRtl={true}
                                  isSearchable={false}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  value={defaultSubCategoryVal}
                                />
                              )}
                              name={"category"}
                              rules={{ required: true }}
                            />
                          </div>

                          <p className="error-hint">
                            {errors?.category && (
                              <FormattedMessage id={"requiredField"} />
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  )}
                {/* {typeof subCategoriesChildren !== "undefined" &&
                  subCategoriesChildren.length > 0 && (
                    <Col lg={12} xs={12}>
                      <div className="form-group ">
                        <h5>
                          <FormattedMessage id={"subCategoryChild"} />
                        </h5>
                        <div>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <Select
                                inputRef={ref}
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                name={name}
                                placeholder={
                                  <FormattedMessage id={"subCategoryChild"} />
                                }
                                options={handleSelectOptions(
                                  subCategoriesChildren
                                )}
                                onChange={(selectedOption) => {
                                  onChange(selectedOption.value);

                                  // setDefaultSubCategoryVal(selectedOption);
                                }}
                                isRtl={true}
                                isSearchable={false}
                                className="basic-single"
                                classNamePrefix="select"
                                value={handleSelectOptions(
                                  subCategoriesChildren
                                )?.find((e) => getId(e) == value)}
                              />
                            )}
                            name={"category"}
                            rules={{ required: true }}
                          />
                        </div>

                        <p className="error-hint">
                          {errors?.category && (
                            <FormattedMessage id={"requiredField"} />
                          )}
                        </p>
                      </div>
                    </Col>
                  )} */}

                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMedia;
