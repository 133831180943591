import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import {
  addCategory,
  getAllCategories,
  getAllSpecializations,
} from 'store/actions';
import Loader from 'components/shared/Loader';
import { Input } from 'components/shared/FormComponents';
import AddButton from 'components/shared/AddButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import Select from 'react-select';
import { getId } from 'helpers/functions';
import { useCallback } from 'react';

const AddCategory = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, categories } = useSelector(
    (state) => state.categories
  );
  const { specializations, loading: specialLoader } = useSelector(
    (state) => state.specializations
  );
  const [searchParams] = useSearchParams();
  const [isFree, setIsFree] = useState(false);
  const specializationParam = searchParams.get('specialization');


  const categoryType = window.location.pathname?.split('/')?.at(-2);

  const [subCategories, setSubCategories] = useState([]);
  const [defaultSubCategoryVal, setDefaultSubCategoryVal] = useState({
    value: '',
    label: '',
  });
  const [specialization, setSpecialization] = useState({
    value: '',
    label: '',
  });
  const mainCategories = categories?.filter((category) => !category?.category);

  // const { categories } = useSelector((state) => state.categories);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();


    // useEffect(() => {
    //   if (!specializationParam) {
    //     // navigate('/categories');
    //   }
    // }, []);

  useEffect(() => {
    dispatch(getAllSpecializations());
    if (categoryType === 'sub-child') {
      // dispatch(getAllCategories(''));
            dispatch(getAllCategories({ specialization: specializationParam }));

    }
    if (categoryType === 'sub' && specializationParam) {
      console.log('a');
      dispatch(getAllCategories({ specialization: specializationParam }));
    }
  }, [dispatch, categoryType, specializationParam]);

  useEffect(() => {
    if (categoryType === 'sub-child') {
      // const subCategories = categories?.filter(
      //   (category) =>
      //     getId(category?.category) ===
      //     getId(
      //       categories?.find((e) => getId(e) == getId(singleCategory?.category))
      //         ?.category
      //     )
      // );

      console.log('subCategory');

      // setSubCategories(subCategories);
      const subCategories = categories?.filter(
        (category) => category?.category
      );

      setSubCategories(subCategories);
    }
  }, [categoryType, categories]);
  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
      // navigate(`/categories/${categoryType}`);
    }
  }, [error, formatMessage, locale, navigate, categoryType]);

  const handleSelectOptions = useCallback(
    (options) => {
      if (options.length < 0) return [];

      return options.map((option) => {
        return {
          value: getId(option),
          label: option?.name?.[locale] || option?.subTitle?.[locale],
        };
      });
    },
    [locale]
  );

  useEffect(() => {
    setDefaultSubCategoryVal(
      handleSelectOptions(categories)?.find(
        (e) =>
          e?.value === Object.fromEntries([...searchParams])?.parentCategory
      )
    );

    setValue('category', Object.fromEntries([...searchParams])?.parentCategory);
  }, [categories, handleSelectOptions, searchParams, setValue, subCategories]);

  const onSubmit = (data) => {
    data.isFree = isFree;
    if (specializationParam) {
      data.specialization = specializationParam;
    }
    // if (singleCategory) {
    //   data.isFree = isFree;
    // }
    dispatch(addCategory({ data, navigate, categoryType: '' }));
  };

  if (loading || specialLoader) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={`addCategory_${categoryType}`} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* name  */}
                <Row>
                  {!categoryType?.includes('sub') && (
                    <Col xs={12}>
                      <div className='form-group'>
                        <h5 className='mb-2'>
                          {' '}
                          <FormattedMessage id={'specializationType'} />
                        </h5>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <Select
                              name={field.name}
                              placeholder={
                                <FormattedMessage id={'specializationType'} />
                              }
                              options={handleSelectOptions(specializations)}
                              onChange={(selectedOption) => {
                                field.onChange(selectedOption.value);
                                setSpecialization(selectedOption);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className='basic-single'
                              classNamePrefix='select'
                              value={specialization}
                            />
                          )}
                          name='specialization'
                          rules={{ required: !categoryType?.includes('sub') }}
                        />
                      </div>
                    </Col>
                  )}
                  <Col lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='name.ar'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='name.en'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* parert category */}
                <Row>
                  {categoryType !== 'sub-child' &&
                    categoryType?.includes('sub') && (
                      <Col lg={12} xs={12}>
                        <div className='form-group '>
                          <h5>
                            {' '}
                            <FormattedMessage id={'mainCategory'} />
                          </h5>
                          <div>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <Select
                                  inputRef={ref}
                                  name={name}
                                  placeholder={
                                    <FormattedMessage id={'mainCategory'} />
                                  }
                                  options={handleSelectOptions(mainCategories)}
                                  onChange={(selectedOption) => {
                                    // -------------------------------------------------------

                                    setDefaultSubCategoryVal({
                                      value: '',
                                      label: '',
                                    });
                                    setSubCategories([]);

                                    const subCategories = categories?.filter(
                                      (category) =>
                                        category?.category?._id ===
                                        selectedOption?.value
                                    );

                                    setSubCategories(subCategories);

                                    // --------------------------------
                                    if (categoryType !== 'sub-child')
                                      onChange(selectedOption.value);
                                  }}
                                  isRtl={true}
                                  isSearchable={false}
                                  className='basic-single'
                                  classNamePrefix='select'
                                  value={defaultSubCategoryVal}
                                />
                              )}
                              name={'category'}
                              rules={{
                                required: categoryType === 'sub' ? true : false,
                              }}
                            />
                          </div>
                          <p className='error-hint'>
                            {errors?.category && (
                              <FormattedMessage id={'requiredField'} />
                            )}
                          </p>
                        </div>
                      </Col>
                    )}

                  {categoryType === 'sub-child' &&
                    typeof subCategories !== 'undefined' && (
                      // subCategories.length > 0 &&
                      <Col lg={12} xs={12}>
                        <div className='form-group '>
                          <h5 className='input_head_'>
                            <FormattedMessage id={'subCategory'} />
                          </h5>
                          <div>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <Select
                                  styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  inputRef={ref}
                                  name={name}
                                  placeholder={
                                    <FormattedMessage id={'subCategory'} />
                                  }
                                  options={handleSelectOptions(subCategories)}
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption.value);

                                    setDefaultSubCategoryVal(selectedOption);
                                  }}
                                  isRtl={true}
                                  isSearchable={false}
                                  className='basic-single'
                                  classNamePrefix='select'
                                  value={defaultSubCategoryVal}
                                />
                              )}
                              name={'category'}
                              rules={{ required: true }}
                            />
                          </div>

                          <p className='error-hint'>
                            {errors?.category && (
                              <FormattedMessage id={'requiredField'} />
                            )}
                          </p>
                        </div>
                      </Col>
                    )}
                </Row>
                {!categoryType?.includes('sub') && (
                  <div className='pb-4'>
                    <div
                      className={`content__block__ free_label  ${
                        isFree && 'check_free'
                      }`}
                      onClick={() => setIsFree((prevState) => !prevState)}
                    >
                      <span class='radio_wrapper__'>
                        <span class='check_radio_input'></span>
                      </span>
                      <p className='content_block_details__'>
                        <FormattedMessage id='avaliableForAll' />
                      </p>
                      {/* {
                    answer?.isCorrect ? <span className="correct_answer__">correct answer</span> : ""
                  } */}
                    </div>
                    <span className='check_note'>
                      <FormattedMessage id='note' />
                    </span>
                  </div>
                )}
                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
