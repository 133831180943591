
export const formattedDate = (dateValue, locale) => {
  const date = new Date(dateValue);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  let formattedDate = `${day}/${month}/${year}`;

  // locale === 'ar' && formattedDate = 
  return formattedDate;
};
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getCurrentDateTime = () => {
  const now = new Date();
  return formatDate(now);
};

