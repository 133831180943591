import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_REVIEW,
  DELETE_REVIEW,
  EDIT_REVIEW,
  GET_ALL_REVIEWS,
  GET_REVIEW,
} from './actionTypes';
import {
  addReviewApi,
  getAllReviewsApi,
  getReviewApi,
  editReviewApi,
  deleteReviewApi,
} from 'api/reviews';
import {
  addReviewFailure,
  addReviewSuccess,
  deleteReviewFailure,
  deleteReviewSuccess,
  editReviewFailure,
  editReviewSuccess,
  getAllReviewsFailure,
  getAllReviewsSuccess,
  getReviewFailure,
  getReviewSuccess,
} from './actions';

function* getAllReviews() {
  try {
    const { data } = yield call(getAllReviewsApi);

    yield put(getAllReviewsSuccess(data));
  } catch (error) {
    yield put(getAllReviewsFailure(error?.response?.data || error || error));
  }
}

function* addReview({ payload }) {
  try {
    const { data } = yield call(addReviewApi, payload.data);

    yield put(addReviewSuccess(data));
    payload.navigate('/reviews');
 
    // Add any additional logic based on your application's requirements
  } catch (error) {
    yield put(addReviewFailure(error?.response?.data || error || error));
  }
}

function* getReview({ payload }) {
  try {
    const { data } = yield call(getReviewApi, payload);

    yield put(getReviewSuccess(data));
  } catch (error) {
    yield put(getReviewFailure(error?.response?.data || error || error));
  }
}

function* editReview({ payload }) {
  try {
    const { data } = yield call(editReviewApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editReviewSuccess(data));
    payload.navigate('/reviews');

  } catch (error) {
    yield put(editReviewFailure(error?.response?.data || error || error));
  }
}

function* deleteReview({ payload }) {
  try {
    yield call(deleteReviewApi, payload);
    yield put(deleteReviewSuccess(payload));
  } catch (error) {
    yield put(deleteReviewFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllReviews() {
  yield takeEvery(GET_ALL_REVIEWS, getAllReviews);
}

export function* watchAddReview() {
  yield takeEvery(ADD_REVIEW, addReview);
}

export function* watchGetReview() {
  yield takeEvery(GET_REVIEW, getReview);
}

export function* watchEditReview() {
  yield takeEvery(EDIT_REVIEW, editReview);
}

export function* watchDeleteReview() {
  yield takeEvery(DELETE_REVIEW, deleteReview);
}

function* reviewsSaga() {
  yield all([
    fork(watchGetAllReviews),
    fork(watchAddReview),
    fork(watchGetReview),
    fork(watchEditReview),
    fork(watchDeleteReview),
  ]);
}

export default reviewsSaga;
