import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { editCity, getCity } from "store/actions";
import Loader from "components/shared/Loader";
import { Input } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";


const EditCity = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const { loading, error, singleCity } = useSelector((state) => state.cities);


  // effects
  useEffect(() => {
    dispatch(getCity(id));
  }, [dispatch, id]);

  useEffect(() => {

    reset({
      name: singleCity?.name,
    });


  }, [locale, reset, singleCity]);

  // error 
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      navigate("/cities");

    }

  }, [error, formatMessage, locale, navigate]);


  const onSubmit = (data) => {

    dispatch(editCity({ id, data, navigate }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"editCity"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* name  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="name" name="name.ar" register={register} errors={errors} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="name" name="name.en" register={register} errors={errors} />
                  </Col>
                </Row>
                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCity;
