import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_QUESTION,
  DELETE_QUESTION,
  DELETE_QUESTION_COMMENT,
  EDIT_QUESTION,
  GET_ALL_QUESTIONS,
  GET_QUESTION,
} from "./actionTypes";
import {
  addQuestionApi,
  getAllQuestionsApi,
  getQuestionApi,
  editQuestionApi,
  deleteQuestionApi,
  deleteQuestionCommentApi,
} from "api/questions";
import {
  addQuestionFailure,
  addQuestionSuccess,
  deleteQuestionCommentSuccess,
  deleteQuestionFailure,
  deleteQuestionSuccess,
  editQuestionFailure,
  editQuestionSuccess,
  getAllQuestionsFailure,
  getAllQuestionsSuccess,
  getQuestionFailure,
  getQuestionSuccess,
} from "./actions";

function* getAllQuestions({ payload }) {
  try {
    const { data } = yield call(getAllQuestionsApi, payload);

    yield put(getAllQuestionsSuccess(data));
  } catch (error) {
    yield put(getAllQuestionsFailure(error?.response?.data || error || error));
  }
}

function* addQuestion({ payload }) {
  try {
    const { data } = yield call(addQuestionApi, payload.data);

    yield put(addQuestionSuccess(data));

    payload.navigate("/questions");
  } catch (error) {
    console.log("error", error);
    yield put(addQuestionFailure(error?.response?.data || error || error));
  }
}

function* getQuestion({ payload }) {
  try {
    const { data } = yield call(getQuestionApi, payload);

    yield put(getQuestionSuccess(data));
  } catch (error) {
    yield put(getQuestionFailure(error?.response?.data || error || error));
  }
}

function* editQuestion({ payload }) {
  try {
    const { data } = yield call(editQuestionApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editQuestionSuccess(data));
    payload.navigate("/questions");
  } catch (error) {
    yield put(editQuestionFailure(error?.response?.data || error || error));
  }
}

function* deleteQuestion({ payload }) {
  try {
    yield call(deleteQuestionApi, payload);
    yield put(deleteQuestionSuccess(payload));
  } catch (error) {
    yield put(deleteQuestionFailure(error?.response?.data || error || error));
  }
}

function* deleteQuestionComment({ payload }) {
  try {
    yield call(deleteQuestionCommentApi, payload);
    yield put(deleteQuestionCommentSuccess(payload));
  } catch (error) {
    yield put(deleteQuestionFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllQuestions() {
  yield takeEvery(GET_ALL_QUESTIONS, getAllQuestions);
}

export function* watchAddQuestion() {
  yield takeEvery(ADD_QUESTION, addQuestion);
}

export function* watchGetQuestion() {
  yield takeEvery(GET_QUESTION, getQuestion);
}

export function* watchEditQuestion() {
  yield takeEvery(EDIT_QUESTION, editQuestion);
}

export function* watchDeleteQuestion() {
  yield takeEvery(DELETE_QUESTION, deleteQuestion);
}

export function* watchDeleteQuestionComment() {
  yield takeEvery(DELETE_QUESTION_COMMENT, deleteQuestionComment);
}

function* questionsSaga() {
  yield all([
    fork(watchGetAllQuestions),
    fork(watchAddQuestion),
    fork(watchGetQuestion),
    fork(watchEditQuestion),
    fork(watchDeleteQuestion),
    fork(watchDeleteQuestionComment),
  ]);
}

export default questionsSaga;
