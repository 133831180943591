export const GET_ALL_MEDIAS = "GET_ALL_MEDIAS";
export const GET_ALL_MEDIAS_SUCCESS = "GET_ALL_MEDIAS_SUCCESS";
export const GET_ALL_MEDIAS_FAILURE = "GET_ALL_MEDIAS_FAILURE";

export const GET_MEDIA = "GET_MEDIA";
export const GET_MEDIA_SUCCESS = "GET_MEDIA_SUCCESS";
export const GET_MEDIA_FAILURE = "GET_MEDIA_FAILURE";

export const ADD_MEDIA = "ADD_MEDIA";
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";
export const ADD_MEDIA_FAILURE = "ADD_MEDIA_FAILURE";

export const EDIT_MEDIA = "EDIT_MEDIA";
export const EDIT_MEDIA_SUCCESS = "EDIT_MEDIA_SUCCESS";
export const EDIT_MEDIA_FAILURE = "EDIT_MEDIA_FAILURE";

export const DELETE_MEDIA = "DELETE_MEDIA";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_FAILURE = "DELETE_MEDIA_FAILURE";