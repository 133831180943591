const Loader = () => {
    return (
        <section className="loader">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="204px"
                height="204px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
            >
                <g transform="translate(12.5 50)">
                    <circle cx="0" cy="0" r="6" fill="#1890f9">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.5791505791505792s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1.3513513513513513s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </circle>
                </g>
                <g transform="translate(25 50)">
                    <circle cx="0" cy="0" r="6" fill="#25e37c">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.48262548262548266s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1.3513513513513513s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </circle>
                </g>
                <g transform="translate(37.5 50)">
                    <circle cx="0" cy="0" r="6" fill="#f25f69">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.3861003861003861s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1.3513513513513513s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </circle>
                </g>
                <g transform="translate(50 50)">
                    <circle cx="0" cy="0" r="6" fill="#c230ec">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.2895752895752896s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1.3513513513513513s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </circle>
                </g>
                <g transform="translate(62.5 50)">
                    <circle cx="0" cy="0" r="6" fill="#1890f9">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.19305019305019305s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1.3513513513513513s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </circle>
                </g>
                <g transform="translate(75 50)">
                    <circle cx="0" cy="0" r="6" fill="#25e37c">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.09652509652509653s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1.3513513513513513s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </circle>
                </g>
                <g transform="translate(87.5 50)">
                    <circle cx="0" cy="0" r="6" fill="#f25f69">
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1.3513513513513513s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </circle>
                </g>
            </svg>
        </section>
    );
};

export default Loader;
