import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { editUniversity, getUniversity } from "store/actions";
import Loader from "components/shared/Loader";
import { Input } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";


const EditUniversity = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const { loading, error, singleUniversity } = useSelector((state) => state.universities);


  // effects
  useEffect(() => {
    dispatch(getUniversity(id));
  }, [dispatch, id]);

  useEffect(() => {

    reset({
      name: singleUniversity?.name,
      // foundationYear: singleUniversity?.foundationYear,
      city: singleUniversity?.city,
      // websiteURL: singleUniversity?.websiteURL,
    });


  }, [locale, reset, singleUniversity]);

  // error 
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      navigate("/universities");

    }

  }, [error, formatMessage, locale, navigate]);


  const onSubmit = (data) => {

    dispatch(editUniversity({ id, data, navigate }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"editUniversity"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="name" name="name" register={register} errors={errors} />
                  </Col>
                  {/* <Col lg={6} xs={12}>
                    <Input langId="foundationYear" name="foundationYear" register={register} errors={errors} rules={{ required: false }} />
                  </Col> */}
                  <Col lg={6} xs={12}>
                    <Input langId="city" name="city" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  {/* <Col lg={6} xs={12}>
                    <Input langId="websiteURL" name="websiteURL" register={register} errors={errors} rules={{ required: false }} />
                  </Col> */}
                </Row>
                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUniversity;
