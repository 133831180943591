import {
  ADD_CITY, ADD_CITY_FAILURE, ADD_CITY_SUCCESS,
  DELETE_CITY, DELETE_CITY_FAILURE, DELETE_CITY_SUCCESS,
  EDIT_CITY, EDIT_CITY_FAILURE, EDIT_CITY_SUCCESS,
  GET_ALL_CITIES, GET_ALL_CITIES_FAILURE, GET_ALL_CITIES_SUCCESS,
  GET_CITY, GET_CITY_FAILURE, GET_CITY_SUCCESS
} from "./actionTypes";



const initialState = {
  cities: [],
  loading: false,
  error: "",
  singleCity: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all cities  
    case GET_ALL_CITIES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_CITIES_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        cities: action.payload.cities,
      };
      break;

    case GET_ALL_CITIES_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add city  
    case ADD_CITY:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_CITY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleCity: action.payload.city,
      };
      break;

    case ADD_CITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get city  
    case GET_CITY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleCity: {}
      };
      break;

    case GET_CITY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleCity: action.payload.city,
      };
      break;

    case GET_CITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit city  
    case EDIT_CITY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleCity: {}
      };
      break;

    case EDIT_CITY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleCity: action.payload.city,
      };
      break;

    case EDIT_CITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete city  
    case DELETE_CITY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleCity: {}
      };
      break;

    case DELETE_CITY_SUCCESS:
      const citiesAfterDeleteing = [
        ...state?.cities?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        cities: citiesAfterDeleteing,
      };
      break;

    case DELETE_CITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
