import {
  ADD_QUESTION, ADD_QUESTION_FAILURE, ADD_QUESTION_SUCCESS,
  DELETE_QUESTION, DELETE_QUESTION_COMMENT, DELETE_QUESTION_COMMENT_FAILURE, DELETE_QUESTION_COMMENT_SUCCESS, DELETE_QUESTION_FAILURE, DELETE_QUESTION_SUCCESS,
  EDIT_QUESTION, EDIT_QUESTION_FAILURE, EDIT_QUESTION_SUCCESS,
  GET_ALL_QUESTIONS, GET_ALL_QUESTIONS_FAILURE, GET_ALL_QUESTIONS_SUCCESS,
  GET_QUESTION, GET_QUESTION_FAILURE, GET_QUESTION_SUCCESS
} from "./actionTypes";



const initialState = {
  questions: [],
  metadata: {},
  loading: false,
  error: "",
  singleQuestion: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all questions  
    case GET_ALL_QUESTIONS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_QUESTIONS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        questions: action.payload.questions,
        metadata: action.payload.metadata
      };
      break;

    case GET_ALL_QUESTIONS_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add Question
    case ADD_QUESTION:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_QUESTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleQuestion: action.payload.question,
      };
      break;

    case ADD_QUESTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get Question  
    case GET_QUESTION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleQuestion: {}
      };
      break;

    case GET_QUESTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleQuestion: action.payload.question,
      };
      break;

    case GET_QUESTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit Question  
    case EDIT_QUESTION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleQuestion: {}
      };
      break;

    case EDIT_QUESTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleQuestion: action.payload.question,
      };
      break;

    case EDIT_QUESTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete Question  
    case DELETE_QUESTION:
      state = {
        ...state,
        error: "",
        loading: true,
        singleQuestion: {}
      };
      break;

    case DELETE_QUESTION_SUCCESS:
      const questionsAfterDeleteing = [
        ...state?.questions?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        questions: questionsAfterDeleteing,
      };
      break;

    case DELETE_QUESTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete Comment  
    case DELETE_QUESTION_COMMENT:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case DELETE_QUESTION_COMMENT_SUCCESS:

      const commentsAfterDeleteingOne = state?.singleQuestion?.comments?.filter(comment => {
        return comment?._id !== action.payload?.commentId
      })

      const questionAfterDeletingComment = { ...state?.singleQuestion, comments: commentsAfterDeleteingOne }

      state = {
        ...state,
        error: "",
        loading: false,
        singleQuestion: questionAfterDeletingComment,
      };
      break;

    case DELETE_QUESTION_COMMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
