import {
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_FAILURE,
  ADD_SUBSCRIPTION_SUCCESS,

  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION_SUCCESS,

  EDIT_SUBSCRIPTION,
  EDIT_SUBSCRIPTION_FAILURE,
  EDIT_SUBSCRIPTION_SUCCESS,

  GET_ALL_SUBSCRIPTIONS,
  GET_ALL_SUBSCRIPTIONS_FAILURE,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,

  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION_SUCCESS
} from "./actionTypes";


export const getAllSubscriptions = (payload) => {
  return {
    type: GET_ALL_SUBSCRIPTIONS,
    payload: payload,
  };
}
export const getAllSubscriptionsSuccess = (payload) => {
  return {
    type: GET_ALL_SUBSCRIPTIONS_SUCCESS,
    payload: payload,
  };
}

export const getAllSubscriptionsFailure = (error) => {
  return {
    type: GET_ALL_SUBSCRIPTIONS_FAILURE,
    payload: error,
  };
}

export const addSubscription = (payload) => {
  return {
    type: ADD_SUBSCRIPTION,
    payload: payload,
  };
}
export const addSubscriptionSuccess = (payload) => {
  return {
    type: ADD_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
}

export const addSubscriptionFailure = (error) => {
  return {
    type: ADD_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}

export const getSubscription = (payload) => {
  return {
    type: GET_SUBSCRIPTION,
    payload: payload,
  };
}
export const getSubscriptionSuccess = (payload) => {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
}

export const getSubscriptionFailure = (error) => {
  return {
    type: GET_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}

export const editSubscription = (payload) => {
  return {
    type: EDIT_SUBSCRIPTION,
    payload: payload,
  };
}
export const editSubscriptionSuccess = (payload) => {
  return {
    type: EDIT_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
}

export const editSubscriptionFailure = (error) => {
  return {
    type: EDIT_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}

export const deleteSubscription = (payload) => {
  return {
    type: DELETE_SUBSCRIPTION,
    payload: payload,
  };
}

export const deleteSubscriptionSuccess = (payload) => {
  return {
    type: DELETE_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
}

export const deleteSubscriptionFailure = (error) => {
  return {
    type: DELETE_SUBSCRIPTION_FAILURE,
    payload: error,
  };
}