// couponReducer.js

import {
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAILURE,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  EDIT_COUPON,
  EDIT_COUPON_SUCCESS,
  EDIT_COUPON_FAILURE,
  GET_ALL_COUPONS,
  GET_ALL_COUPONS_SUCCESS,
  GET_ALL_COUPONS_FAILURE,
  GET_COUPON,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAILURE,
  TOGGLE_COUPON,
  TOGGLE_COUPON_SUCCESS,
  TOGGLE_COUPON_FAILURE,
} from './actionTypes';

const initialState = {
  coupons: [],
  timesTamps: '',
  loading: false,
  error: '',
  singleCoupon: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all coupons
    case GET_ALL_COUPONS:
      state = {
        ...state,
        error: '',
        loading: true,
      };
      break;

    case GET_ALL_COUPONS_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        coupons: action.payload.coupons,
        timesTamps: action.payload.timesTamps,
      };
      break;

    case GET_ALL_COUPONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // add coupon
    case ADD_COUPON:
      state = {
        ...state,
        error: '',
        loading: true,
      };
      break;

    case ADD_COUPON_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleCoupon: action.payload.coupon,
        timesTamps: action.payload.timesTamps,
      };
      break;

    case ADD_COUPON_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get coupon
    case GET_COUPON:
      state = {
        ...state,
        error: '',
        loading: true,
        singleCoupon: {},
      };
      break;

    case GET_COUPON_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleCoupon: action.payload.coupon,
      };
      break;

    case GET_COUPON_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit coupon
    case EDIT_COUPON:
      state = {
        ...state,
        error: '',
        loading: true,
        singleCoupon: {},
      };
      break;

    case EDIT_COUPON_SUCCESS:
      state = {
        ...state,
        error: '',
        loading: false,
        singleCoupon: action.payload.coupon,
        timesTamps: action.payload.timesTamps,
      };
      break;

    // toggle coupon
    case TOGGLE_COUPON:
      state = {
        ...state,
        error: '',
        loading: true,
      };
      break;

    case TOGGLE_COUPON_SUCCESS:
      const toggledCouponId = action.payload.id;
      const updatedCoupons = state.coupons?.data.map((coupon) =>
        coupon.id === toggledCouponId
          ? { ...coupon, isActive: !coupon.isActive }
          : coupon
      );
      state = {
        ...state,
        error: '',
        loading: false,
        coupons: updatedCoupons,
      };
      break;

    case TOGGLE_COUPON_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case EDIT_COUPON_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete coupon
    case DELETE_COUPON:
      state = {
        ...state,
        error: '',
        loading: true,
        singleCoupon: {},
      };
      break;

    case DELETE_COUPON_SUCCESS:
      const couponsAfterDeleting = [
        ...state?.coupons?.data?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: '',
        loading: false,
        coupons: couponsAfterDeleting,
      };
      break;

    case DELETE_COUPON_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
