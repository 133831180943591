import server from './server';

export const getAllReviewsApi = async () => {
  const response = await server().get('/reviews');

  return response.data;
};

export const addReviewApi = async (data) => {
  const response = await server().post('/reviews', data);

  return response.data;
};

export const getReviewApi = async (id) => {
  const response = await server().get(`/reviews/${id}`);

  return response.data;
};

export const editReviewApi = async ({ id, data }) => {
  const response = await server().put(`/reviews/${id}`, data);

  return response.data;
};

export const deleteReviewApi = async (id) => {
  const response = await server().delete(`/reviews/${id}`);

  return response.data;
};
