import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import { editCoupon, getAllCoupons, getCoupon } from 'store/actions'; // Update the import path
import Loader from 'components/shared/Loader';
import { Input } from 'components/shared/FormComponents';
import SaveChangesButton from 'components/shared/SaveChangesButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import { formattedInputDate } from 'helpers/formatDate';

const EditCoupon = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { loading, error, singleCoupon } = useSelector(
    (state) => state.coupons
  ); // Update state selector

  // effects
  useEffect(() => {
    dispatch(getCoupon(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      code: singleCoupon?.code,
      amount: singleCoupon.amount,
      usageLimit: singleCoupon.usageLimit,
      startAt: singleCoupon?.startAt?.split('T')[0],
      expireAt: singleCoupon?.expireAt?.split('T')[0],
    });
  }, [locale, reset, singleCoupon]);

  // error
  useEffect(() => {
    if (error) {
      console.log(error);
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
      navigate(`/coupons?subscriptions=${singleCoupon?.subscription}`);
    }
  }, [error, formatMessage, locale, navigate, singleCoupon?.subscription]);

  const onSubmit = (data) => {
    dispatch(
      editCoupon({
        id,
        data,
        navigate,
        subscription: singleCoupon?.subscription,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'editCoupon'} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* name  */}
                <Row>
                  <Col lg={4} xs={12}>
                    <Input
                      langId='code'
                      name='code'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={4} xs={12}>
                    <Input
                      langId='amountPer'
                      name='amount'
                      register={register}
                      errors={errors}
                      type={'number'}
                      hint={<FormattedMessage id={'number'} />}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <Input
                      langId='usageLimit'
                      name='usageLimit'
                      type={'number'}
                      register={register}
                      errors={errors}
                      required={false}
                      hint={<FormattedMessage id={'optional'} />}
                      rules={{ required: false }}
                    />
                  </Col>
                  {/* <Col lg={6} xs={12}>
                    <Input
                      langId='startAt'
                      name='startAt'
                      type={'date'}
                      register={register}
                      errors={errors}
                      required={false}
                      // hint={<FormattedMessage id={'optional'} />}
                    />
                  </Col>  */}
                  <Col lg={4} xs={12}>
                    <Input
                      langId='expireAt'
                      name='expireAt'
                      type={'date'}
                      register={register}
                      errors={errors}
                      required={false}
                      hint={<FormattedMessage id={'optional'} />}
                      rules={{ required: false }}
                    />
                  </Col>
                </Row>
                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCoupon;
