export const handleImage = (image) => {
  if (image) {
    if (!image.includes("http"))
      image = `${process.env.REACT_APP_API_URL}/${image}`;
    return image;
  }
  return `${process.env.REACT_APP_API_URL}`;
};

export const checkIfUserHasPermission = (
  userPermissions = [],
  testedPermission
) => {
  if (userPermissions.length === 0) return false;
  const hasPermission = userPermissions.includes(testedPermission);
  return hasPermission;
};

export const handleSearchParamsChange = (search, params, page = false) => {
  const url = new URLSearchParams("");
  search = { ...search, ...params };
  Object.entries(search).map(([key, value]) => {
    if (key === "page" && page) value = "1";
    return url.set(key, value);
  });
  return "?" + url;
};

export const themeSwitcherFunction = () => {
  document.querySelector(".themetoggle").classList.toggle("fire");
  document.querySelector("body").classList.toggle("dark");
};

export const sidebarToggleFunction = () => {
  document.querySelector("body").classList.remove("sidebarToggled");
  document.querySelector(".overlay-s").classList.remove("fire");
  document.querySelector("html").classList.remove("offScroll");
};

export const getSettingsBySlug = (settings, slug) => {
  return settings.find((setting) => {
    return setting._id === slug;
  });
};

export const getToday = () => {
  let today = new Date();
  let dd = String(today.getDate());
  let mm = String(today.getMonth() + 1); //January is 0!
  let yyyy = today.getFullYear();

  today = `${yyyy}-${mm}-${dd}`;
  return today;
};

export const getDateOfBirthWithFormat = (date) => {
  let newDateWithFormate = new Date(date);

  let dd = String(newDateWithFormate.getDate()).padStart(2, "0");
  let mm = String(newDateWithFormate.getMonth() + 1).padStart(2, "0");
  let yyyy = newDateWithFormate.getFullYear();

  newDateWithFormate = `${yyyy}-${mm}-${dd}`;
  return newDateWithFormate;
};

export const selctOptionValues = (options, selectedOption) => {
  const option = options?.find((option) => option?.value === selectedOption);

  return option;
};

export const handleIsoDate = (isoDate) => {
  const date = new Date(isoDate);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const hour = date.getHours()?.toString()?.padStart(2, "0");
  const min = date.getMinutes()?.toString()?.padStart(2, "0");
  const seconds = date.getSeconds()?.toString()?.padStart(2, "0");

  const time = `${hour}:${min}:${seconds}`;

  return `${day}/${month}/${year} - ${TimeConvert(time)}`;
};

function TimeConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const getId = (obj) => obj?.id || obj?._id || obj?.value || obj;
