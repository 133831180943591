import {
  ADD_ETHIC, ADD_ETHIC_FAILURE, ADD_ETHIC_SUCCESS,
  DELETE_ETHIC, DELETE_ETHIC_FAILURE, DELETE_ETHIC_SUCCESS,
  EDIT_ETHIC, EDIT_ETHIC_FAILURE, EDIT_ETHIC_SUCCESS,
  GET_ALL_ETHICS, GET_ALL_ETHICS_FAILURE, GET_ALL_ETHICS_SUCCESS,
  GET_ETHIC, GET_ETHIC_FAILURE, GET_ETHIC_SUCCESS
} from "./actionTypes";



const initialState = {
  ethics: [],
  loading: false,
  error: "",
  singleEthic: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all ethics  
    case GET_ALL_ETHICS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_ETHICS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        ethics: action.payload.ethics,
      };
      break;

    case GET_ALL_ETHICS_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add Ethic
    case ADD_ETHIC:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_ETHIC_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleEthic: action.payload.ethic,
      };
      break;

    case ADD_ETHIC_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get Ethic  
    case GET_ETHIC:
      state = {
        ...state,
        error: "",
        loading: true,
        singleEthic: {}
      };
      break;

    case GET_ETHIC_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleEthic: action.payload.ethic,
      };
      break;

    case GET_ETHIC_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit Ethic  
    case EDIT_ETHIC:
      state = {
        ...state,
        error: "",
        loading: true,
        singleEthic: {}
      };
      break;

    case EDIT_ETHIC_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleEthic: action.payload.ethic,
      };
      break;

    case EDIT_ETHIC_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete Ethic  
    case DELETE_ETHIC:
      state = {
        ...state,
        error: "",
        loading: true,
        singleEthic: {}
      };
      break;

    case DELETE_ETHIC_SUCCESS:
      const ethicsAfterDeleteing = [
        ...state?.ethics?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        ethics: ethicsAfterDeleteing,
      };
      break;

    case DELETE_ETHIC_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
