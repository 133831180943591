import {
  ADD_BLOG,
  ADD_BLOG_FAILURE,
  ADD_BLOG_SUCCESS,
  DELETE_BLOG,
  DELETE_BLOG_COMMENT,
  DELETE_BLOG_COMMENT_FAILURE,
  DELETE_BLOG_COMMENT_SUCCESS,
  DELETE_BLOG_FAILURE,
  DELETE_BLOG_SUCCESS,
  EDIT_BLOG,
  EDIT_BLOG_FAILURE,
  EDIT_BLOG_SUCCESS,
  GET_ALL_BLOGS,
  GET_ALL_BLOGS_FAILURE,
  GET_ALL_BLOGS_SUCCESS,
  GET_BLOG,
  GET_BLOG_FAILURE,
  GET_BLOG_SUCCESS,
} from "./actionTypes";

const initialState = {
  blogs: [],
  metadata: {},
  loading: false,
  error: "",
  singleBlog: {},
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    // get all blogs
    case GET_ALL_BLOGS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_BLOGS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        blogs: action.payload.blogs,
        metadata: action.payload.metadata,
      };
      break;

    case GET_ALL_BLOGS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // add Blog
    case ADD_BLOG:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_BLOG_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBlog: action.payload.blog,
      };
      break;

    case ADD_BLOG_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get Blog
    case GET_BLOG:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBlog: {},
      };
      break;

    case GET_BLOG_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBlog: action.payload.blog,
      };
      break;

    case GET_BLOG_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit Blog
    case EDIT_BLOG:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBlog: {},
      };
      break;

    case EDIT_BLOG_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBlog: action.payload.blog,
      };
      break;

    case EDIT_BLOG_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete Blog
    case DELETE_BLOG:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBlog: {},
      };
      break;

    case DELETE_BLOG_SUCCESS:
      const blogsAfterDeleting = [
        ...state?.blogs?.filter((blog) => blog?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        blogs: blogsAfterDeleting,
      };
      break;

    case DELETE_BLOG_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete Comment
    case DELETE_BLOG_COMMENT:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case DELETE_BLOG_COMMENT_SUCCESS:
      const commentsAfterDeletingOne = state?.singleBlog?.comments?.filter(
        (comment) => {
          return comment?._id !== action.payload?.commentId;
        }
      );

      const blogAfterDeletingComment = {
        ...state?.singleBlog,
        comments: commentsAfterDeletingOne,
      };

      state = {
        ...state,
        error: "",
        loading: false,
        singleBlog: blogAfterDeletingComment,
      };
      break;

    case DELETE_BLOG_COMMENT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default blogReducer;
