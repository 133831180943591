import axios from 'axios';

const server = (options = {}) => {
  const headers = {};

  const token = `${localStorage.getItem('super-token')}`;
  if (token) headers.Authorization = token;

  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
    timeout: 20000, //20 seconds
    ...options,
  });
};

export default server;
