export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const GET_ALL_FAQS_SUCCESS = "GET_ALL_FAQS_SUCCESS";
export const GET_ALL_FAQS_FAILURE = "GET_ALL_FAQS_FAILURE";

export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

export const ADD_FAQ = "ADD_FAQ";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_FAILURE = "ADD_FAQ_FAILURE";

export const EDIT_FAQ = "EDIT_FAQ";
export const EDIT_FAQ_SUCCESS = "EDIT_FAQ_SUCCESS";
export const EDIT_FAQ_FAILURE = "EDIT_FAQ_FAILURE";

export const DELETE_FAQ = "DELETE_FAQ";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAILURE = "DELETE_FAQ_FAILURE";
