import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_CITY,
  DELETE_CITY,
  EDIT_CITY,
  GET_ALL_CITIES,
  GET_CITY,
} from "./actionTypes";
import {
  addCityApi,
  getAllCitiesApi,
  getCityApi,
  editCityApi,
  deleteCityApi,
} from "api/cities";
import {
  addCityFailure,
  addCitySuccess,
  deleteCityFailure,
  deleteCitySuccess,
  editCityFailure,
  editCitySuccess,
  getAllCitiesFailure,
  getAllCitiesSuccess,
  getCityFailure,
  getCitySuccess,
} from "./actions";

function* getAllCities() {
  try {
    const { data } = yield call(getAllCitiesApi);

    yield put(getAllCitiesSuccess(data));
  } catch (error) {
    yield put(getAllCitiesFailure(error?.response?.data || error || error));
  }
}

function* addCity({ payload }) {
  try {
    const { data } = yield call(addCityApi, payload.data);

    yield put(addCitySuccess(data));
    payload.navigate("/cities");
  } catch (error) {
    yield put(addCityFailure(error?.response?.data || error || error));
  }
}

function* getCity({ payload }) {
  try {
    const { data } = yield call(getCityApi, payload);

    yield put(getCitySuccess(data));
  } catch (error) {
    yield put(getCityFailure(error?.response?.data || error || error));
  }
}

function* editCity({ payload }) {
  try {
    const { data } = yield call(editCityApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editCitySuccess(data));
  } catch (error) {
    yield put(editCityFailure(error?.response?.data || error || error));
  }
}

function* deleteCity({ payload }) {
  try {
    yield call(deleteCityApi, payload);
    yield put(deleteCitySuccess(payload));
  } catch (error) {
    yield put(deleteCityFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllCities() {
  yield takeEvery(GET_ALL_CITIES, getAllCities);
}

export function* watchAddCity() {
  yield takeEvery(ADD_CITY, addCity);
}

export function* watchGetCity() {
  yield takeEvery(GET_CITY, getCity);
}

export function* watchEditCity() {
  yield takeEvery(EDIT_CITY, editCity);
}

export function* watchDeleteCity() {
  yield takeEvery(DELETE_CITY, deleteCity);
}

function* citiesSaga() {
  yield all([
    fork(watchGetAllCities),
    fork(watchAddCity),
    fork(watchGetCity),
    fork(watchEditCity),
    fork(watchDeleteCity),
  ]);
}

export default citiesSaga;
