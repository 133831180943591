import { checkPermissions } from "helpers/authentication/checkPermissions";
import React from "react";

import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
function UsedCoupon({ users, show , setShow }) {
    console.log('users', users)
  const toggleModal = () => {
    setShow(!show);
  };


  const columns = [
    {
      name: <FormattedMessage id={'userName'} />,
      selector: (row) =>
        checkPermissions(['users-show']) ? (
          <Link to={`/users/show/${row?._id || row?.id}`}>{`${row?.fullName}`}</Link>
        ) : (
          `${row?.fullName}`
        ),

      sortable: true,
    },
    {
      name: <FormattedMessage id={'accountType'} />,
      selector: (row) => row?.type,
      sortable: true,
    },

    {
      name: <FormattedMessage id={'email'} />,
      selector: (row) => row?.email,
      sortable: true,
    },

   
  ];

  return (
    <Modal show={show} onHide={toggleModal}>
      <Modal.Header onHide={toggleModal} closeButton>
        <FormattedMessage id='couponUsers'/>
      </Modal.Header>
      <Modal.Body>
        {
            users?.length > 0 ?  
                      <div className='card-body'>
                 <DataTable
                   columns={columns}
                   data={users}
                //    progressPending={props.loading}
                //    progressComponent={<Loader />}
                   defaultSortField='name'
                //    selectableRows
                //    subHeader
                //    subHeaderComponent={subHeaderComponent}
                //    selectableRowsComponent={BootyCheckbox}
                 />
               </div>
            //      <ul key={user.id}>
            //     <li>
            //         <a href=''>
            //             {user?.fullName}
            //         </a>
            //     </li>
            // </ul> 
                : <div>
                <h2 className="text-center m-auto">
                    <FormattedMessage id='noCouponsUsers' /> 
                </h2>
            </div>
        }
 
      </Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  );
}

export default UsedCoupon;
