import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import { addReview } from 'store/actions';
import Loader from 'components/shared/Loader';
import AddButton from 'components/shared/AddButton';
import { Input } from 'components/shared/FormComponents';
import { toastErrorMessage, toastSuccessMessage } from 'helpers/toaster/index';
import imageUpload from '../../assets/svgs/imageUpload.svg';
import removeImg from '../../assets/svgs/close.svg';
import server from 'api/server';

const AddReview = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.cities);
  const [selectedImage, setSelectedImage] = useState({ preview: '', path: '' });

  const {
    register,
    handleSubmit,
    formState: { errors, success },
  } = useForm();

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { data } = await server().post('/upload', formData);

      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage('');
  };

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);

      navigate('/reviews');
    }
  }, [error, formatMessage, locale, navigate]);

  const onSubmit = (data) => {
    dispatch(
      addReview({ data: { ...data, img: selectedImage?.path }, navigate })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'addReview'} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div className='image-uploader image-contain'>
                <div className='img-pat'>
                  <img src={imageUpload} width={46} height={46} alt='' />
                </div>
                <label>
                  {selectedImage && selectedImage?.preview && (
                    <div>
                      <img
                        src={URL.createObjectURL(selectedImage.preview)}
                        width={160}
                        height={160}
                        alt=''
                      />
                      <button
                        className='removeImg'
                        onClick={removeSelectedImage}
                      >
                        <img src={removeImg} alt='' />
                      </button>
                    </div>
                  )}
                  <input
                    accept='image/*'
                    type='file'
                    onChange={handleImageChange}
                  />
                </label>
              </div> */}
              <Col xl={8} md={12}>
                {/* name  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='name.ar'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='name.en'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='content'
                      name='content.ar'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='name'
                      name='content.en'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='rate'
                      name='rate'
                      type='number'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddReview;
