export const GET_ALL_QUESTIONS = "GET_ALL_QUESTIONS";
export const GET_ALL_QUESTIONS_SUCCESS = "GET_ALL_QUESTIONS_SUCCESS";
export const GET_ALL_QUESTIONS_FAILURE = "GET_ALL_QUESTIONS_FAILURE";

export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE";

export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAILURE = "ADD_QUESTION_FAILURE";

export const EDIT_QUESTION = "EDIT_QUESTION";
export const EDIT_QUESTION_SUCCESS = "EDIT_QUESTION_SUCCESS";
export const EDIT_QUESTION_FAILURE = "EDIT_QUESTION_FAILURE";

export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE";

export const DELETE_QUESTION_COMMENT = "DELETE_QUESTION_COMMENT";
export const DELETE_QUESTION_COMMENT_SUCCESS = "DELETE_QUESTION_COMMENT_SUCCESS";
export const DELETE_QUESTION_COMMENT_FAILURE = "DELETE_QUESTION_COMMENT_FAILURE";