import { sendCustomMessagesToUsers } from 'api/sendMail';
import Filter from 'components/shared/Filter';
import { Input, Textarea } from 'components/shared/FormComponents';
import Loader from 'components/shared/Loader';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllUsers } from 'store/actions';

const CustomMessages = () => {
  const { users, loading, error } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isDispatched, setIsDispatched] = useState(false);
  const { locale, formatMessage } = useIntl();
  const [selected, setSelected] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllUsers(searchParams));
  }, [dispatch, searchParams]);

  useEffect(() => {
    setFilteredUsers(users?.users?.users);
  }, [users]);


  console.log(users);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toast.error(formatMessage({ id: 'sendingErrorMessage' }), {
        position: locale === 'ar' ? 'bottom-left' : 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error, formatMessage, locale, isDispatched]);

  const handleSelect = (e) => {
    const { value } = e.target;
    if (selected === value) {
      setSelected(null);
    } else {
      setSelected(value);
    }

    console.log(value);

    const usersIds = [];

    if (e.target.checked) {
      // users?.users?.forEach((element) => {
      //   usersIds.push(element?.id);
      // });
      switch (value) {
        case 'allUsers':
          users?.users?.forEach((element) => {
            usersIds.push(element?.id);
          });
          break;
        case 'doctors':
          users?.users
            ?.filter((element) => {
              return element.type === 'doctor';
            })
            .forEach((element) => {
              usersIds.push(element?.id);
            });
          break;
        case 'nurses':
          users?.users
            ?.filter((element) => {
              return element.type === 'nurse';
            })
            .forEach((element) => {
              usersIds.push(element?.id);
            });
          break;

        default:
          break;
        // users?.users?.forEach((element) => {
        //   usersIds.push(element?.id);
        // });
      }
    }

    // select all users
    setValue('users', usersIds);
  };

  const handleOnFilter = (e) => {
    if (e.target.value) {
      const usersAfterFilter = filteredUsers?.filter(
        (item) =>
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(e.target.value.toLowerCase()) !== -1
      );
      setFilteredUsers(usersAfterFilter);
    } else {
      setFilteredUsers(users?.users);
    }
  };

  const renderusers = () => {
    // const allUsers = watch("users")
    // console.log(allUsers);

    return filteredUsers?.map((user) => {
      return (
        <div className='form-group' key={user?.id}>
          <label htmlFor={user?.id} className='checkbox-label'>
            <input
              id={user?.id}
              type='checkbox'
              value={user?.id}
              {...register('users')}
            />
            <span className='text__'>
              {user?.fullName} - ({user?.email}){' '}
            </span>
          </label>
        </div>
      );
    });
  };

  const onSubmit = async (data) => {
    if (!data?.users?.length || !data?.users) {
      toast.error(formatMessage({ id: 'pleaseChooseAtleastOneUser' }), {
        position: locale === 'ar' ? 'bottom-left' : 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    try {
      await sendCustomMessagesToUsers(data);

      toast.success(formatMessage({ id: 'messageHasBeenSent' }), {
        position: locale === 'ar' ? 'bottom-left' : 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      reset();
    } catch (error) {
      toast.error(formatMessage({ id: 'sendingErrorMessage' }), {
        position: locale === 'ar' ? 'bottom-left' : 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (loading) return <Loader />;

  return (
    <div className='acc-form send_custom_message_section_'>
      <div className='card'>
        <div className='card-head'>
          <h4>
            <FormattedMessage id='customMessages' />
          </h4>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='add-multi-component'>
              <Col xl={12} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId='subject'
                      name='subject'
                      register={register}
                      errors={errors}
                    />
                    <Textarea
                      langId='message'
                      name='message'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className='select_all_users_'>
                      <div>
                        <label htmlFor='allUsers' className='checkbox-label'>
                          <input
                            id='allUsers'
                            type='checkbox'
                            value={'allUsers'}
                            checked={selected === 'allUsers' ? true : false}
                            onChange={handleSelect}
                          />

                          <span className='text__'>
                            <FormattedMessage id='selectAllUsers' />{' '}
                          </span>
                        </label>
                      </div>
                      <div>
                        <label
                          htmlFor='selectDoctors'
                          className='checkbox-label'
                        >
                          <input
                            id='selectDoctors'
                            type='checkbox'
                            value={'doctors'}
                            checked={selected === 'doctors' ? true : false}
                            onChange={handleSelect}
                          />

                          <span className='text__'>
                            <FormattedMessage id='selectDoctors' />{' '}
                          </span>
                        </label>
                      </div>
                      <div>
                        <label htmlFor='selectNurse' className='checkbox-label'>
                          <input
                            id='selectNurse'
                            type='checkbox'
                            value={'nurses'}
                            checked={selected === 'nurses' ? true : false}
                            onChange={handleSelect}
                          />

                          <span className='text__'>
                            <FormattedMessage id='selectNurses' />{' '}
                          </span>
                        </label>
                      </div>
                      <Filter onFilter={handleOnFilter} />
                    </div>
                    <div className='custom_messages_users__'>
                      {renderusers()}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg='4'>
                    <div className='form-group'>
                      <button type='submit' className='btn btn-blue'>
                        <FormattedMessage id={'send'} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomMessages;
