import {
  ADD_MEDIA,
  ADD_MEDIA_FAILURE,
  ADD_MEDIA_SUCCESS,

  DELETE_MEDIA,
  DELETE_MEDIA_FAILURE,
  DELETE_MEDIA_SUCCESS,

  EDIT_MEDIA,
  EDIT_MEDIA_FAILURE,
  EDIT_MEDIA_SUCCESS,

  GET_ALL_MEDIAS,
  GET_ALL_MEDIAS_FAILURE,
  GET_ALL_MEDIAS_SUCCESS,

  GET_MEDIA,
  GET_MEDIA_FAILURE,
  GET_MEDIA_SUCCESS
} from "./actionTypes";


export const getAllMedias = (payload) => {
  return {
    type: GET_ALL_MEDIAS,
    payload: payload,
  };
}
export const getAllMediasSuccess = (payload) => {
  return {
    type: GET_ALL_MEDIAS_SUCCESS,
    payload: payload,
  };
}

export const getAllMediasFailure = (error) => {
  return {
    type: GET_ALL_MEDIAS_FAILURE,
    payload: error,
  };
}

export const addMedia = (payload) => {
  return {
    type: ADD_MEDIA,
    payload: payload,
  };
}
export const addMediaSuccess = (payload) => {
  return {
    type: ADD_MEDIA_SUCCESS,
    payload: payload,
  };
}

export const addMediaFailure = (error) => {
  return {
    type: ADD_MEDIA_FAILURE,
    payload: error,
  };
}

export const getMedia = (payload) => {
  return {
    type: GET_MEDIA,
    payload: payload,
  };
}
export const getMediaSuccess = (payload) => {
  return {
    type: GET_MEDIA_SUCCESS,
    payload: payload,
  };
}

export const getMediaFailure = (error) => {
  return {
    type: GET_MEDIA_FAILURE,
    payload: error,
  };
}

export const editMedia = (payload) => {
  return {
    type: EDIT_MEDIA,
    payload: payload,
  };
}
export const editMediaSuccess = (payload) => {
  return {
    type: EDIT_MEDIA_SUCCESS,
    payload: payload,
  };
}

export const editMediaFailure = (error) => {
  return {
    type: EDIT_MEDIA_FAILURE,
    payload: error,
  };
}

export const deleteMedia = (payload) => {
  return {
    type: DELETE_MEDIA,
    payload: payload,
  };
}

export const deleteMediaSuccess = (payload) => {
  return {
    type: DELETE_MEDIA_SUCCESS,
    payload: payload,
  };
}

export const deleteMediaFailure = (error) => {
  return {
    type: DELETE_MEDIA_FAILURE,
    payload: error,
  };
}