import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import { addSubscription } from 'store/actions';
import Loader from 'components/shared/Loader';
import AddButton from 'components/shared/AddButton';
import { Input } from 'components/shared/FormComponents';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import FeaturesArray from './featuresArray';

const AddSubscription = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.subscriptions);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const specialization = queryParams.get('specialization');

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'features',
  });

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
      navigate('/subscriptions');
    }
  }, [error, formatMessage, locale, navigate]);

  const onSubmit = (data) => {
    data.specialization = specialization;
    dispatch(addSubscription({ data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'addSubscription'} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='price'
                      name='price'
                      register={register}
                      errors={errors}
                      hint={<FormattedMessage id={'SAR'} />}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='planOfMonths'
                      type='number'
                      min={1}
                      defaultValue={1}
                      name='plan'
                      register={register}
                      errors={errors}
                      hint={<FormattedMessage id={'pleaseEnterNumber'} />}
                    />
                  </Col>
                </Row>
                <FeaturesArray
                  fields={fields}
                  append={append}
                  remove={remove}
                  register={register}
                  errors={errors}
                />

                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSubscription;
