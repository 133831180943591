import {
  ADD_UNIVERSITY, ADD_UNIVERSITY_FAILURE, ADD_UNIVERSITY_SUCCESS,
  DELETE_UNIVERSITY, DELETE_UNIVERSITY_FAILURE, DELETE_UNIVERSITY_SUCCESS,
  EDIT_UNIVERSITY, EDIT_UNIVERSITY_FAILURE, EDIT_UNIVERSITY_SUCCESS,
  GET_ALL_UNIVERSITIES, GET_ALL_UNIVERSITIES_FAILURE, GET_ALL_UNIVERSITIES_SUCCESS,
  GET_UNIVERSITY, GET_UNIVERSITY_FAILURE, GET_UNIVERSITY_SUCCESS
} from "./actionTypes";



const initialState = {
  universities: [],
  loading: false,
  error: "",
  singleUniversity: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all universities  
    case GET_ALL_UNIVERSITIES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_UNIVERSITIES_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        universities: action.payload.universities,
      };
      break;

    case GET_ALL_UNIVERSITIES_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add university  
    case ADD_UNIVERSITY:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_UNIVERSITY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleUniversity: action.payload.university,
      };
      break;

    case ADD_UNIVERSITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get university  
    case GET_UNIVERSITY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleUniversity: {}
      };
      break;

    case GET_UNIVERSITY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleUniversity: action.payload.university,
      };
      break;

    case GET_UNIVERSITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit university  
    case EDIT_UNIVERSITY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleUniversity: {}
      };
      break;

    case EDIT_UNIVERSITY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleUniversity: action.payload.university,
      };
      break;

    case EDIT_UNIVERSITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete university  
    case DELETE_UNIVERSITY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleUniversity: {}
      };
      break;

    case DELETE_UNIVERSITY_SUCCESS:
      const universitiesAfterDeleteing = [
        ...state?.universities?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        universities: universitiesAfterDeleteing,
      };
      break;

    case DELETE_UNIVERSITY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
