import {
  ADD_QUESTION,
  ADD_QUESTION_FAILURE,
  ADD_QUESTION_SUCCESS,

  DELETE_QUESTION,
  DELETE_QUESTION_FAILURE,
  DELETE_QUESTION_SUCCESS,

  EDIT_QUESTION,
  EDIT_QUESTION_FAILURE,
  EDIT_QUESTION_SUCCESS,

  GET_ALL_QUESTIONS,
  GET_ALL_QUESTIONS_FAILURE,
  GET_ALL_QUESTIONS_SUCCESS,

  GET_QUESTION,
  GET_QUESTION_FAILURE,
  GET_QUESTION_SUCCESS,
  DELETE_QUESTION_COMMENT,
  DELETE_QUESTION_COMMENT_FAILURE,
  DELETE_QUESTION_COMMENT_SUCCESS
} from "./actionTypes";


export const getAllQuestions = (payload) => {
  return {
    type: GET_ALL_QUESTIONS,
    payload: payload,
  };
}
export const getAllQuestionsSuccess = (payload) => {
  return {
    type: GET_ALL_QUESTIONS_SUCCESS,
    payload: payload,
  };
}

export const getAllQuestionsFailure = (error) => {
  return {
    type: GET_ALL_QUESTIONS_FAILURE,
    payload: error,
  };
}

export const addQuestion = (payload) => {
  return {
    type: ADD_QUESTION,
    payload: payload,
  };
}
export const addQuestionSuccess = (payload) => {
  return {
    type: ADD_QUESTION_SUCCESS,
    payload: payload,
  };
}

export const addQuestionFailure = (error) => {
  return {
    type: ADD_QUESTION_FAILURE,
    payload: error,
  };
}

export const getQuestion = (payload) => {
  return {
    type: GET_QUESTION,
    payload: payload,
  };
}
export const getQuestionSuccess = (payload) => {
  return {
    type: GET_QUESTION_SUCCESS,
    payload: payload,
  };
}

export const getQuestionFailure = (error) => {
  return {
    type: GET_QUESTION_FAILURE,
    payload: error,
  };
}

export const editQuestion = (payload) => {
  return {
    type: EDIT_QUESTION,
    payload: payload,
  };
}
export const editQuestionSuccess = (payload) => {
  return {
    type: EDIT_QUESTION_SUCCESS,
    payload: payload,
  };
}

export const editQuestionFailure = (error) => {
  return {
    type: EDIT_QUESTION_FAILURE,
    payload: error,
  };
}

export const deleteQuestion = (payload) => {
  return {
    type: DELETE_QUESTION,
    payload: payload,
  };
}

export const deleteQuestionSuccess = (payload) => {
  return {
    type: DELETE_QUESTION_SUCCESS,
    payload: payload,
  };
}

export const deleteQuestionFailure = (error) => {
  return {
    type: DELETE_QUESTION_FAILURE,
    payload: error,
  };
}

export const deleteQuestionComment = (payload) => {
  return {
    type: DELETE_QUESTION_COMMENT,
    payload: payload,
  };
}

export const deleteQuestionCommentSuccess = (payload) => {
  return {
    type: DELETE_QUESTION_COMMENT_SUCCESS,
    payload: payload,
  };
}

export const deleteQuestionCommentFailure = (error) => {
  return {
    type: DELETE_QUESTION_COMMENT_FAILURE,
    payload: error,
  };
}