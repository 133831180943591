import { Input, Textarea } from 'components/shared/FormComponents'
import Loader from 'components/shared/Loader'
import SaveChangesButton from 'components/shared/SaveChangesButton'
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select';
import { editPage, getAllCategories, getPage } from 'store/actions'
import { v4 } from 'uuid';
import ContentArray from "./ContentArray"

const PageContent = ({ id }) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, singlePage } = useSelector((state) => state.pages);
  const { register, control, reset, handleSubmit, formState: { errors }, } = useForm();
  const [sections, setSections] = useState([]);
  const { categories } = useSelector((state) => state.categories);
  const [subCategories, setSubCategories] = useState([]);
  const mainCategories = categories?.filter(category => !category?.category)
  const [defaultSubCategoryVal, setDefaultSubCategoryVal] = useState(
    {
      value: "",
      label: "",
      questionsCount: 0
    }
  )
  const [defaultCategoryVal, setDefaultCategoryVal] = useState(
    {
      value: "",
      label: "",
    }
  )

  // get single page by slug 
  useEffect(() => {
    dispatch(getPage(id));
    dispatch(getAllCategories(""));
  }, [dispatch, id]);


  useEffect(() => {
    setDefaultCategoryVal(singlePage?.content?.demoTest?.category?.mainCategory)
    setDefaultSubCategoryVal(singlePage?.content?.demoTest?.category?.subCategory);

    const subCategories = categories?.filter((category) => {
      return category?.category?._id === singlePage?.content?.demoTest?.category?.mainCategory.value;
    });

    setSubCategories(subCategories);

    reset({
      title: singlePage?.title,
      content: singlePage?.content,
    });


    if (singlePage?.content?.whyUs) {
      const updatedSections = [];

      Object?.entries(singlePage?.content?.whyUs?.features)?.forEach(([key, section]) => {
        section.id = v4();
        updatedSections?.push(section)
      });

      setSections(updatedSections)
    }

  }, [categories, reset, singlePage]);
  // error 
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }

  }, [error, formatMessage, locale]);


  const handleSelectOptions = (options) => {

    if (options.length < 0) return []

    return options.map((option) => {

      return {
        value: option?.id || option?._id,
        label: option?.name?.[locale],
        questionsCount: option?.questionsCount
      }
    });
  }

  const onSubmit = (data) => {

    data.content.whyUs.features = sections;
    data.content.demoTest.category = {
      mainCategory: defaultCategoryVal,
      subCategory: defaultSubCategoryVal
    };
    delete data.category
    delete data.mainCategory

    dispatch(editPage({ id, data, navigate }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Col xl={12} md={12}>

          <input type="hidden"  {...register("title.ar")} />
          <input type="hidden"  {...register("title.en")} />

          <Tabs
            defaultActiveKey="intro"
            id="home_page_tabs"
            className="home_page_tabs__"
          >
            <Tab eventKey="intro" title={formatMessage({ id: "intro" })}>
              <Col lg={8}>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.intro.subTitle.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.intro.subTitle.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <Textarea langId="description" name="content.intro.description.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Textarea langId="description" name="content.intro.description.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
              </Col>
            </Tab>
            <Tab eventKey="whyUs" title={formatMessage({ id: "whyUs" })}>

              <Col lg={8}>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="content.whyUs.subTitle.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="content.whyUs.subTitle.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.whyUs.title.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.whyUs.title.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
              </Col>
              <ContentArray sections={sections} setSections={setSections} />
            </Tab>
            <Tab eventKey="demoTest" title={formatMessage({ id: "demoTest" })}   >
              <Col lg={8}>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="content.demoTest.subTitle.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="content.demoTest.subTitle.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.demoTest.title.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.demoTest.title.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>

                {/* mainCategory */}
                {<Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5> <FormattedMessage id={"mainCategory"} /></h5>
                      <div>

                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"mainCategory"} />}
                              options={handleSelectOptions(mainCategories)}
                              onChange={(selectedOption) => {

                                setDefaultSubCategoryVal({
                                  value: "",
                                  label: "",
                                })

                                setDefaultCategoryVal(selectedOption)

                                setSubCategories([]);
                                const subCategories = mainCategories?.find(category => category?.id === selectedOption?.value)?.subCategories;

                                setSubCategories(subCategories);
                                onChange(selectedOption.value);

                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              value={defaultCategoryVal}
                            />
                          )}
                          name={"mainCategory"}
                          rules={{ required: false }}
                        />
                      </div>


                    </div>
                  </Col>
                </Row>}

                {/* sub category */}
                {(typeof subCategories !== 'undefined' && subCategories.length > 0) && <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5> <FormattedMessage id={"subCategory"} /> </h5>
                      <div>

                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"subCategory"} />}
                              options={handleSelectOptions(subCategories)}
                              onChange={(selectedOption) => {
                                onChange(selectedOption.value);
                                setDefaultSubCategoryVal(selectedOption)
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              value={defaultSubCategoryVal}

                            />
                          )}
                          name={"category"}
                          rules={{ required: false }}
                        />
                      </div>

                    </div>
                  </Col>

                </Row>}
              </Col>
            </Tab>
            <Tab eventKey="subscriptions" title={formatMessage({ id: "subscriptions" })} >
              <Col lg={8}>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="content.subscriptions.subTitle.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="content.subscriptions.subTitle.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.subscriptions.title.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.subscriptions.title.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
              </Col>
            </Tab>
            <Tab eventKey="contactUs" title={formatMessage({ id: "contactUs" })}   >
              <Col lg={8}>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="content.contactUs.subTitle.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="subTitle" name="content.contactUs.subTitle.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.contactUs.title.ar" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="content.contactUs.title.en" register={register} errors={errors} rules={{ required: false }} />
                  </Col>
                </Row>
              </Col>
            </Tab>
          </Tabs>

          <SaveChangesButton />
        </Col>
      </form>
    </>
  )
}

export default PageContent