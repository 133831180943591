import React from 'react'
import imageUpload from "assets/svgs/imageUpload.svg";
import removeImg from "assets/svgs/close.svg";
import server from 'api/server';

const ImageUpload = ({ selectedImage, setSelectedImage }) => {


  const imageChange = async (e) => {

    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);


      setSelectedImage({
        preview: e.target.file,
        path: data.data.files[0].fullpath,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage("");
  };

  return (
    <div className="image-uploader image-contain">
      <div className="img-pat">
        <img src={imageUpload} width={46} height={46} alt="" />
      </div>
      <label>
        {(selectedImage?.preview || selectedImage?.path) && (
          <div>
            <img
              src={
                selectedImage && selectedImage.preview
                  ? URL.createObjectURL(selectedImage.preview)
                  : selectedImage?.path ? selectedImage?.path : imageUpload
              }
              width={160}
              height={160}
              alt=""
            />
            <button className="removeImg" onClick={removeSelectedImage}>
              <img src={removeImg} alt="" />
            </button>
          </div>
        )}
        <input accept="image/*" type="file" onChange={imageChange} />
      </label>
    </div>
  )
}

export default ImageUpload