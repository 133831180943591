import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_ALL_CATEGORIES,
  GET_CATEGORY,
} from './actionTypes';
import {
  addCategoryApi,
  getAllCategoriesApi,
  getCategoryApi,
  editCategoryApi,
  deleteCategoryApi,
} from 'api/categories';
import {
  addCategoryFailure,
  addCategorySuccess,
  deletecategoryFailure,
  deletecategorySuccess,
  editCategoryFailure,
  editCategorySuccess,
  getAllCategoriesFailure,
  getAllCategoriesSuccess,
  getCategoryFailure,
  getCategorySuccess,
} from './actions';

function* getAllCategories({ payload }) {
  try {
    console.log('sage', payload)
    const { data } = yield call(getAllCategoriesApi, {
      specialization: payload.specialization,
    });
    yield put(getAllCategoriesSuccess({ data: data, categoryType: payload }));
  } catch (error) {
    yield put(getAllCategoriesFailure(error?.response?.data || error || error));
  }
}

function* addCategory({ payload }) {
  try {
    const { data } = yield call(addCategoryApi, payload.data);

    yield put(addCategorySuccess(data));
    payload.navigate(`/categories/${payload.categoryType}`);
  } catch (error) {
    yield put(addCategoryFailure(error?.response?.data || error || error));
  }
}

function* getCategory({ payload }) {
  try {
    const { data } = yield call(getCategoryApi, payload);
    yield put(getCategorySuccess(data));
  } catch (error) {
    yield put(getCategoryFailure(error?.response?.data || error || error));
  }
}

function* editCategory({ payload }) {
  try {
    const { data } = yield call(editCategoryApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editCategorySuccess(data));

    payload.navigate(`/categories/${payload.categoryType}`);
  } catch (error) {
    yield put(editCategoryFailure(error?.response?.data || error || error));
  }
}

function* deleteCategory({ payload }) {
  try {
    yield call(deleteCategoryApi, payload);
    yield put(deletecategorySuccess(payload));
  } catch (error) {
    yield put(deletecategoryFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllCategories() {
  yield takeEvery(GET_ALL_CATEGORIES, getAllCategories);
}

export function* watchAddCategory() {
  yield takeEvery(ADD_CATEGORY, addCategory);
}

export function* watchGetCategory() {
  yield takeEvery(GET_CATEGORY, getCategory);
}

export function* watchEditCategory() {
  yield takeEvery(EDIT_CATEGORY, editCategory);
}

export function* watchDeleteCategory() {
  yield takeEvery(DELETE_CATEGORY, deleteCategory);
}

function* categoriesSaga() {
  yield all([
    fork(watchGetAllCategories),
    fork(watchAddCategory),
    fork(watchGetCategory),
    fork(watchEditCategory),
    fork(watchDeleteCategory),
  ]);
}

export default categoriesSaga;
