import server from "./server";

export const getAllCitiesApi = async () => {
  const response = await server().get("/cities");

  return response.data;
}

export const addCityApi = async (data) => {
  const response = await server().post("/cities", data);

  return response.data;
}


export const getCityApi = async (id) => {
  const response = await server().get(`/cities/${id}`);

  return response.data;
}

export const editCityApi = async ({ id, data }) => {
  const response = await server().put(`/cities/${id}`, data);

  return response.data;
}

export const deleteCityApi = async (id) => {
  const response = await server().delete(`/cities/${id}`);

  return response.data;
}


