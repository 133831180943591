import ControlArea from "components/Layout/ControlArea";
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "assets/svgs/questions.svg";
import Table from "./Table1";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFaqs } from "store/actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const Faqs = () => {
  const { faqs, loading, error } = useSelector((state) => state.faqs);
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllFaqs());
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);

  console.log("faqs", faqs);
  return (
    <div className="faqs_wrap">
      <ControlArea
        btnTxt={<FormattedMessage id={"addFaq"} />}
        cardTxt={<FormattedMessage id={"faqs"} />}
        icon={Icon}
        url="/faqs/add"
      />
      <Table data={faqs} />
    </div>
  );
};

export default Faqs;
