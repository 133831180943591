import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import { addCoupon } from 'store/actions';
import Loader from 'components/shared/Loader';
import AddButton from 'components/shared/AddButton';
import { Input } from 'components/shared/FormComponents';
import { toastErrorMessage } from 'helpers/toaster/index';

const AddCoupon = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.cities);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subscriptions = queryParams.get('subscriptions');

  const {
    register,
    handleSubmit,
    formState: { errors, success },
  } = useForm();

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);

      navigate(`/coupons?subscriptions=${subscriptions}`);
    }
  }, [error, formatMessage, locale, navigate, subscriptions]);

  const onSubmit = (data) => {
    data.subscription = subscriptions;
    dispatch(addCoupon({ data, navigate, subscription: subscriptions }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'addCoupon'} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* name  */}
                <Row>
                  <Col lg={4} xs={12}>
                    <Input
                      langId='code'
                      name='code'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={4} xs={12}>
                    <Input
                      langId='amountPer'
                      name='amount'
                      register={register}
                      errors={errors}
                      type={'number'}
                      hint={<FormattedMessage id={'number'} />}
                      rules={{ max: 100 }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <Input
                      langId='usageLimit'
                      name='usageLimit'
                      type={'number'}
                      register={register}
                      errors={errors}
                      required={false}
                      hint={<FormattedMessage id={'optional'} />}
                      rules={{ required: false }}
                    />
                  </Col>
                  {/* <Col lg={4} xs={12}>
                    <Input
                      langId='startAt'
                      name='startAt'
                      type={'date'}
                      register={register}
                      errors={errors}
                      required={false}
                      hint={<FormattedMessage id={'optional'} />}
                    />
                  </Col> */}
                  <Col lg={4} xs={12}>
                    <Input
                      langId='expireAt'
                      name='expireAt'
                      type={'date'}
                      register={register}
                      errors={errors}
                      hint={<FormattedMessage id={'optional'} />}
                      rules={{ required: false }}
                    />
                  </Col>
                </Row>

                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCoupon;
