import server from "./server";

export const getAllEthicsApi = async () => {
  const response = await server().get("/ethics");

  return response.data;
}

export const addEthicApi = async (data) => {
  const response = await server().post("/ethics", data);

  return response.data;
}


export const getEthicApi = async (id) => {
  const response = await server().get(`/ethics/${id}`);

  return response.data;
}

export const editEthicApi = async ({ id, data }) => {
  const response = await server().put(`/ethics/${id}`, data);

  return response.data;
}

export const deleteEthicApi = async (id) => {
  const response = await server().delete(`/ethics/${id}`);

  return response.data;
}