import ControlArea from 'components/Layout/ControlArea';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSpecializations, getAllSubscriptions } from 'store/actions';
import Icon from 'assets/svgs/subscriptions.svg';
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';

const Subscriptions = () => {
  const { subscriptions, loading, error } = useSelector(
    (state) => state.subscriptions
  );
  const {
    specializations,
    loading: specializationsLoading,
    error: specializationsError,
  } = useSelector((state) => state.specializations);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    dispatch(getAllSpecializations());
  }, [dispatch]);

  console.log(specializations);
  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);

  useEffect(() => {
    if (specializations && specializations.length > 0) {
      setChecked(specializations[0]?.id);
      setIsDispatched(true);
      dispatch(
        getAllSubscriptions({
          params: { specialization: specializations[0]?.id },
        })
      );
    }
  }, [dispatch, specializations]);

  return (
    <>
      <div className='subscriptions-wrap'>
        {checkPermissions(['subscriptions-store']) && (
          <ControlArea
            btnTxt={<FormattedMessage id={'addSubscription'} />}
            cardTxt={<FormattedMessage id={'subscriptions'} />}
            icon={Icon}
            url={`/subscriptions/add?specialization=${checked}`}
          />
        )}

        <Table
          checked={checked}
          setChecked={setChecked}
          data={subscriptions}
          loading={loading}
          specializations={specializations}
          specializationsLoading={specializationsLoading}
        />
      </div>
    </>
  );
};

export default Subscriptions;
