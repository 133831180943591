import React from "react";
import removeImg from "../../assets/svgs/close.svg";
import { v4 } from "uuid";
import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const SectionsArray = ({ sections, setSections }) => {
  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;

    const newSectionsArray = sections.map((section) => {
      if (section.id !== id) return section;

      // make an object with locals inside the big one
      const inputName = name?.split(".")?.[0];
      const local = name?.split(".")?.[1];

      if (local) {
        return {
          ...section,
          [inputName]: {
            ...section?.[inputName],
            [local]: value
          }
        };
      }

      return { ...section, [name]: value };
    });


    setSections(newSectionsArray);
  };

  const removeSection = (sectionId) => {
    const newSections = sections.filter(
      (section, index) => section.id !== sectionId
    );
    setSections(() => newSections);
  };

  const addSection = () => {
    setSections(() => [
      ...sections,
      { id: v4(), title: {}, description: {}, image: "" },
    ]);
  };


  return (
    <div className="form-group branches sections home_page_why_us__">
      <Row>

        <Col lg={12}>
          <Row>

            <h5>
              <FormattedMessage id={"features"} />
            </h5>
            {sections.map((section, index) => {

              return (

                <Col xxl={4} xl={6} lg={6} key={section.id} >
                  <div className="add-multi-component">
                    <button
                      type="button"
                      onClick={() => removeSection(section.id)}
                      className="removeImg"
                    >
                      <img src={removeImg} alt="" />
                    </button>
                    <Row>
                      <Col lg={12}>
                        <ImageUpdate
                          item={section}
                          sections={sections}
                          setSections={setSections}
                        />
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group">
                          <h5> <FormattedMessage id={"title"} /> ( <FormattedMessage id={"arabic-language"} /> )</h5>
                          <input
                            type="text"
                            name="title.ar"
                            className="form-control form-outline"
                            onChange={(e) => handleInputOnChange(e, section.id)}
                            value={section?.title?.ar}
                          />
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group">
                          <h5> <FormattedMessage id={"title"} /> ( <FormattedMessage id={"english-language"} /> )</h5>
                          <input
                            type="text"
                            name="title.en"
                            className="form-control form-outline"
                            onChange={(e) => handleInputOnChange(e, section.id)}
                            value={section?.title?.en}
                          />
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group">
                          <h5><FormattedMessage id={"description"} /> ( <FormattedMessage id={"arabic-language"} /> ) </h5>
                          <textarea
                            className="form-control form-outline"
                            name="description.ar"
                            onChange={(e) => handleInputOnChange(e, section.id)}
                            value={section?.description?.ar}
                          ></textarea>
                          <input type="hidden" value={v4()} />
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group">
                          <h5><FormattedMessage id={"description"} /> ( <FormattedMessage id={"english-language"} /> ) </h5>
                          <textarea
                            className="form-control form-outline"
                            name="description.en"
                            onChange={(e) => handleInputOnChange(e, section.id)}
                            value={section?.description?.en}
                          ></textarea>
                          <input type="hidden" value={v4()} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col lg={3} xs={12} className="text-left">
          <button
            type="button"
            className="btn btn-green add_new_section__"
            onClick={() => {
              addSection();
            }}
          >
            <FormattedMessage id={"addFeature"} />
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default SectionsArray;

