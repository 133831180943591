import {
  ADD_ROLE,
  ADD_ROLE_FAILURE,
  ADD_ROLE_SUCCESS,

  DELETE_ROLE,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,

  EDIT_ROLE,
  EDIT_ROLE_FAILURE,
  EDIT_ROLE_SUCCESS,

  GET_ALL_ROLES,
  GET_ALL_ROLES_FAILURE,
  GET_ALL_ROLES_SUCCESS,

  GET_ROLE,
  GET_ROLE_FAILURE,
  GET_ROLE_SUCCESS
} from "./actionTypes";


export const getAllRoles = (payload) => {
  return {
    type: GET_ALL_ROLES,
    payload: payload,
  };
}
export const getAllRolesSuccess = (payload) => {
  return {
    type: GET_ALL_ROLES_SUCCESS,
    payload: payload,
  };
}

export const getAllRolesFailure = (error) => {
  return {
    type: GET_ALL_ROLES_FAILURE,
    payload: error,
  };
}

export const addRole = (payload) => {
  return {
    type: ADD_ROLE,
    payload: payload,
  };
}
export const addRoleSuccess = (payload) => {
  return {
    type: ADD_ROLE_SUCCESS,
    payload: payload,
  };
}

export const addRoleFailure = (error) => {
  return {
    type: ADD_ROLE_FAILURE,
    payload: error,
  };
}

export const getRole = (payload) => {
  return {
    type: GET_ROLE,
    payload: payload,
  };
}
export const getRoleSuccess = (payload) => {
  return {
    type: GET_ROLE_SUCCESS,
    payload: payload,
  };
}

export const getRoleFailure = (error) => {
  return {
    type: GET_ROLE_FAILURE,
    payload: error,
  };
}

export const editRole = (payload) => {
  return {
    type: EDIT_ROLE,
    payload: payload,
  };
}
export const editRoleSuccess = (payload) => {
  return {
    type: EDIT_ROLE_SUCCESS,
    payload: payload,
  };
}

export const editRoleFailure = (error) => {
  return {
    type: EDIT_ROLE_FAILURE,
    payload: error,
  };
}

export const deleteRole = (payload) => {
  return {
    type: DELETE_ROLE,
    payload: payload,
  };
}

export const deleteRoleSuccess = (payload) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload: payload,
  };
}

export const deleteRoleFailure = (error) => {
  return {
    type: DELETE_ROLE_FAILURE,
    payload: error,
  };
}