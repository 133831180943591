import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { addAdmin } from "store/actions";
import Loader from "components/shared/Loader";
import { Input } from "components/shared/FormComponents";
import AddButton from "components/shared/AddButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";


const AddAdmin = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.admins);


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  // error 
  useEffect(() => {

    if (error) {

      if (error?.statusCode === 422) {
        error?.errors?.forEach((err) => {

          toastErrorMessage(err.msg, locale);
        })
      } else {
        toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      }
    }

  }, [error, formatMessage, locale, navigate]);


  const onSubmit = (data) => {
    data.phone = data?.phone?.trim();
    dispatch(addAdmin({ data, navigate }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"addAdmin"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* fullName */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="name" name="fullName" register={register} errors={errors} />
                  </Col>
                </Row>
                {/* phone */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="phone" name="phone" placeholder='00966xxxxxxxxx' register={register} errors={errors} />
                  </Col>
                </Row>

                {/* email */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="email" name="email" register={register} errors={errors} />
                  </Col>
                </Row>

                {/* password */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="password" type="password" name="password" register={register} errors={errors} />
                  </Col>
                </Row>

                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdmin;
