// couponActionTypes.js

export const GET_ALL_COUPONS = 'GET_ALL_COUPONS';
export const GET_ALL_COUPONS_SUCCESS = 'GET_ALL_COUPONS_SUCCESS';
export const GET_ALL_COUPONS_FAILURE = 'GET_ALL_COUPONS_FAILURE';

export const GET_COUPON = 'GET_COUPON';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAILURE = 'GET_COUPON_FAILURE';

export const ADD_COUPON = 'ADD_COUPON';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const ADD_COUPON_FAILURE = 'ADD_COUPON_FAILURE';

export const EDIT_COUPON = 'EDIT_COUPON';
export const EDIT_COUPON_SUCCESS = 'EDIT_COUPON_SUCCESS';
export const EDIT_COUPON_FAILURE = 'EDIT_COUPON_FAILURE';

export const DELETE_COUPON = 'DELETE_COUPON';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';

// New action types for Toggle Switch
export const TOGGLE_COUPON = 'TOGGLE_COUPON';
export const TOGGLE_COUPON_SUCCESS = 'TOGGLE_COUPON_SUCCESS';
export const TOGGLE_COUPON_FAILURE = 'TOGGLE_COUPON_FAILURE';
