import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { handleIsoDate } from "helpers/functions";

const Table = (props) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      name: <FormattedMessage id={"plan"} />,
      selector: (row) => {
        const monthsMessage = row?.subscription?.plan > 2 ? "months" : "month";
        // return `${row?.subscription?.plan} ${formatMessage({ id: monthsMessage })}`
        return row?.subscription?.plan ? <Link to={`/subscriptions/${row?._id}`}>{`${row?.subscription?.plan} ${formatMessage({ id: monthsMessage })}`}</Link> : ""
      },
      sortable: true,
    },
    {
      name: <FormattedMessage id={"status"} />,
      selector: (row) => `${row?.status} `,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"startAt"} />,
      selector: (row) => {
        return handleIsoDate(row?.startAt)
      },
      sortable: true,
    },
    {
      name: <FormattedMessage id={"endAt"} />,
      selector: (row) => {
        return handleIsoDate(row?.endAt)
      },
      sortable: true,
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4><FormattedMessage id={"subscriptions"} /> </h4>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={props?.data}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
