import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_CONTACTS,
  DELETE_CONTACTS,
  GET_CONTACTS
} from "./actionTypes";

import { getAllcontactsApi, deleteContactApi, getcontactsApi } from "api/contacts";

import {
  getAllContactsSuccess,
  getAllContactsFailure,
  deleteContactSuccess,
  deleteContactFailure,
  getContactSuccess,
  getContactFailure,

} from "./actions"

// dispatch actions 
function* getAllContacts({ payload }) {
  try {
    const { data: contacts } = yield call(getAllcontactsApi, payload);

    yield put(getAllContactsSuccess(contacts));
  } catch (error) {
    yield put(getAllContactsFailure(error));
  }
}

function* deleteContact({ payload }) {
  try {
    yield call(deleteContactApi, payload);
    yield put(deleteContactSuccess(payload));

  } catch (error) {
    yield put(deleteContactFailure(error));
  }
}

function* getContact({ payload }) {
  try {
    const { data } = yield call(getcontactsApi, payload);


    yield put(getContactSuccess(data));
  } catch (error) {
    yield put(getContactFailure(error));
  }
}


// watchers
export function* watchGetALLContacts() {
  yield takeEvery(GET_ALL_CONTACTS, getAllContacts);
}

export function* watchDeleteContact() {
  yield takeEvery(DELETE_CONTACTS, deleteContact);
}

export function* watchGetContact() {
  yield takeEvery(GET_CONTACTS, getContact);
}


function* contactsSaga() {
  yield all([fork(watchGetALLContacts), fork(watchGetContact), fork(watchDeleteContact)]);
}

export default contactsSaga;
