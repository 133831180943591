import {
  ADD_FAQ,
  ADD_FAQ_FAILURE,
  ADD_FAQ_SUCCESS,
  DELETE_FAQ,
  DELETE_FAQ_FAILURE,
  DELETE_FAQ_SUCCESS,
  EDIT_FAQ,
  EDIT_FAQ_FAILURE,
  EDIT_FAQ_SUCCESS,
  GET_ALL_FAQS,
  GET_ALL_FAQS_FAILURE,
  GET_ALL_FAQS_SUCCESS,
  GET_FAQ,
  GET_FAQ_FAILURE,
  GET_FAQ_SUCCESS,
} from "./actionTypes";

export const getAllFaqs = (payload) => ({
  type: GET_ALL_FAQS,
  payload: payload,
});

export const getAllFaqsSuccess = (payload) => ({
  type: GET_ALL_FAQS_SUCCESS,
  payload: payload,
});

export const getAllFaqsFailure = (error) => ({
  type: GET_ALL_FAQS_FAILURE,
  payload: error,
});

export const addFaq = (payload) => ({
  type: ADD_FAQ,
  payload: payload,
});

export const addFaqSuccess = (payload) => ({
  type: ADD_FAQ_SUCCESS,
  payload: payload,
});

export const addFaqFailure = (error) => ({
  type: ADD_FAQ_FAILURE,
  payload: error,
});

export const getFaq = (payload) => ({
  type: GET_FAQ,
  payload: payload,
});

export const getFaqSuccess = (payload) => ({
  type: GET_FAQ_SUCCESS,
  payload: payload,
});

export const getFaqFailure = (error) => ({
  type: GET_FAQ_FAILURE,
  payload: error,
});

export const editFaq = (payload) => ({
  type: EDIT_FAQ,
  payload: payload,
});

export const editFaqSuccess = (payload) => ({
  type: EDIT_FAQ_SUCCESS,
  payload: payload,
});

export const editFaqFailure = (error) => ({
  type: EDIT_FAQ_FAILURE,
  payload: error,
});

export const deleteFaq = (payload) => ({
  type: DELETE_FAQ,
  payload: payload,
});

export const deleteFaqSuccess = (payload) => ({
  type: DELETE_FAQ_SUCCESS,
  payload: payload,
});

export const deleteFaqFailure = (error) => ({
  type: DELETE_FAQ_FAILURE,
  payload: error,
});
