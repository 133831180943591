import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_ETHIC,
  DELETE_ETHIC,
  EDIT_ETHIC,
  GET_ALL_ETHICS,
  GET_ETHIC,
} from "./actionTypes";
import {
  addEthicApi,
  getAllEthicsApi,
  getEthicApi,
  editEthicApi,
  deleteEthicApi,
} from "api/ethics";
import {
  addEthicFailure,
  addEthicSuccess,
  deleteEthicFailure,
  deleteEthicSuccess,
  editEthicFailure,
  editEthicSuccess,
  getAllEthicsFailure,
  getAllEthicsSuccess,
  getEthicFailure,
  getEthicSuccess,
} from "./actions";

function* getAllEthics() {
  try {
    const { data } = yield call(getAllEthicsApi);
    yield put(getAllEthicsSuccess(data));
  } catch (error) {
    yield put(getAllEthicsFailure(error?.response?.data || error || error));
  }
}

function* addEthic({ payload }) {
  try {
    const { data } = yield call(addEthicApi, payload.data);

    yield put(addEthicSuccess(data));
    payload.navigate("/ethics");
  } catch (error) {
    yield put(addEthicFailure(error?.response?.data || error || error));
  }
}

function* getEthic({ payload }) {
  try {
    const { data } = yield call(getEthicApi, payload);

    yield put(getEthicSuccess(data));
  } catch (error) {
    yield put(getEthicFailure(error?.response?.data || error || error));
  }
}

function* editEthic({ payload }) {
  try {
    const { data } = yield call(editEthicApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editEthicSuccess(data));
    payload.navigate("/ethics");
  } catch (error) {
    yield put(editEthicFailure(error?.response?.data || error || error));
  }
}

function* deleteEthic({ payload }) {
  try {
    yield call(deleteEthicApi, payload);
    yield put(deleteEthicSuccess(payload));
  } catch (error) {
    yield put(deleteEthicFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllEthics() {
  yield takeEvery(GET_ALL_ETHICS, getAllEthics);
}

export function* watchAddEthic() {
  yield takeEvery(ADD_ETHIC, addEthic);
}

export function* watchGetEthic() {
  yield takeEvery(GET_ETHIC, getEthic);
}

export function* watchEditEthic() {
  yield takeEvery(EDIT_ETHIC, editEthic);
}

export function* watchDeleteEthic() {
  yield takeEvery(DELETE_ETHIC, deleteEthic);
}

function* ethicsSaga() {
  yield all([
    fork(watchGetAllEthics),
    fork(watchAddEthic),
    fork(watchGetEthic),
    fork(watchEditEthic),
    fork(watchDeleteEthic),
  ]);
}

export default ethicsSaga;
