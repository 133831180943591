import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage } from "react-intl";
import { deleteCoupon, editCoupon, getAllCoupons } from "store/actions";
import { checkPermissions } from "helpers/authentication/checkPermissions";
import ToggleSwitch from "components/shared/ToggleSwitch/ToggleSwitch";
import { formattedDate } from "helpers/formatDate";
import UsedCoupon from "./Used";

const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [usedCoupon, setUsedCoupon] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subscriptions = queryParams.get("subscriptions");

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [filteredItems, setFilterItems] = useState();

  useEffect(() => {
    let filteredItems = props?.data?.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
        -1
    );
    setFilterItems(filteredItems);
  }, [filterText, props?.data]);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEdit = (id) => {
    navigate(`/coupons/${id}`);
  };

  const handleDelete = (id) => {
    dispatch(deleteCoupon(id));
  };

  const handleToggleActive = (id, status) => {
    dispatch(
      editCoupon({
        id,
        data: { isActive: !status },
        navigate,
        subscription: subscriptions,
      })
    );
    dispatch(getAllCoupons({ params: { subscription: subscriptions } }));
  };

  const columns = [
    {
      name: <FormattedMessage id={"code"} />,
      selector: (row) => (
        <span
          className={`${row?.isActive ? "coupon-code" : "coupon-in_active"} `}
        >
          {row?.code}
        </span>
      ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"used"} />,
      selector: (row) => (
        <div className="btn" onClick={() => {
          setUsedCoupon(row.users)
          setShow(true)}}>
          <span>
            {row?.usageLimit
              ? `${row?.used || 0} / ${row?.usageLimit || "-"}`
              : row?.used}
          </span>
        </div>
      ),
      // sortable: true,
    },
    {
      name: <FormattedMessage id={"amountPer"} />,
      selector: (row) => `%${(row?.amount / 100) * 100}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"expireAt"} />,
      selector: (row) => (row?.expireAt ? formattedDate(row?.expireAt) : "-"),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"activeCoupon"} />,
      selector: (row) => {
        return (
          <ToggleSwitch
            id={row?.id}
            onChange={() => handleToggleActive(row.id, row.isActive)}
            classess={!row?.isActive && "disabled"}
            checked={row?.isActive}
          />
        );
      },
    },
    {
      name: <FormattedMessage id={"activity"} />,
      selector: (row) => (
        <span
          className={`coupon-status ${row?.isActive ? "active" : "in_active"}`}
        >
          <FormattedMessage id={row?.isActive ? "active" : "inActive"} />
        </span>
      ),
    },
    {
      name: <FormattedMessage id={"status"} />,
      selector: (row) => (
        <span
          className={`coupon-status ${
            (row?.expireAt &&
              new Date(props.timesTamps) > new Date(row?.expireAt)) ||
            (row?.usageLimit && row?.usageLimit === row?.used)
              ? "expired"
              : "not_expired"
          }`}
        >
          <FormattedMessage
            id={
              row?.expireAt &&
              new Date(props.timesTamps) > new Date(row?.expireAt)
                ? "expired"
                : row?.usageLimit && row?.usageLimit === row?.used
                ? "limitUsed"
                : "notExpired"
            }
          />
        </span>
      ),
    },
    {
      cell: (row) => (
        <>
          {checkPermissions(["coupons-update", "coupons-delete"]) && (
            <div className="global-dropdown">
              <Dropdown>
                <Dropdown.Toggle>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-three-dots-vertical"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {checkPermissions(["coupons-update"]) && (
                    <Dropdown.Item onClick={(e) => handleEdit(row?.id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="#fff"
                        className="bi bi-pencil edit"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                      </svg>
                      <FormattedMessage id={"edit"} />
                    </Dropdown.Item>
                  )}

                  {checkPermissions(["coupons-delete"]) && (
                    <Dropdown.Item onClick={(e) => handleDelete(row?.id)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="#fff"
                        className="bi bi-trash delete"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path
                          fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                      <FormattedMessage id={"delete"} />
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  console.log('props', props)

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id={"couponsTableShow"} />{" "}
                </h4>
                <p>
                  {" "}
                  <FormattedMessage id={"couponsTableShowAll"} />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
      <UsedCoupon show={show} setShow={setShow} users={usedCoupon} />
    </>
  );
};

export default Table;
