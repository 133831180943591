import {
  GET_ALL_PERMISSIONS, GET_ALL_PERMISSIONS_FAILURE, GET_ALL_PERMISSIONS_SUCCESS,
} from "./actionTypes";



const initialState = {
  permissions: [],
  loading: false,
  error: "",
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all permissions  
    case GET_ALL_PERMISSIONS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_PERMISSIONS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        permissions: action.payload.permissions,
      };
      break;

    case GET_ALL_PERMISSIONS_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
