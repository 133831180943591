import {
  GET_ALL_SUBSCRIBED_USERS,
  GET_ALL_SUBSCRIBED_USERS_FAILURE,
  GET_ALL_SUBSCRIBED_USERS_SUCCESS,

  // ADD_SUBSCRIBED_USER,
  // ADD_SUBSCRIBED_USER_SUCCESS,
  // ADD_SUBSCRIBED_USER_FAILURE,

} from "./actionTypes";



const initialState = {
  subscribedUsers: [],
  loading: false,
  error: "",
  singleSubscribedUser: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all subscribedUsers  
    case GET_ALL_SUBSCRIBED_USERS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_SUBSCRIBED_USERS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        subscribedUsers: action.payload?.userSubscriptions,
      };
      break;

    case GET_ALL_SUBSCRIBED_USERS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // case ADD_SUBSCRIBED_USER:
    //   state = {
    //     ...state,
    //     error: "",
    //     loading: true,
    //   }
    //   break;

    // case ADD_SUBSCRIBED_USER_SUCCESS:
    //   state = {
    //     ...state,
    //     error: "",
    //     loading: false,
    //     singleSubscribedUser: action.payload.user,
    //   }
    //   break;

    // case ADD_SUBSCRIBED_USER_FAILURE:
    //   state = {
    //     ...state,
    //     error: action.payload,
    //     loading: false,
    //     singleSubscribedUser: {},
    //   }
    //   break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
