import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'react-intl';
import { editSubscription, getSubscription } from 'store/actions';
import Loader from 'components/shared/Loader';
import { Input } from 'components/shared/FormComponents';
import SaveChangesButton from 'components/shared/SaveChangesButton';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import FeaturesArray from './featuresArray';

const EditSubscription = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const specialization = queryParams.get('specialization');

  const { loading, error, singleSubscription } = useSelector(
    (state) => state.subscriptions
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'features',
  });

  // TODO
  // effects
  useEffect(() => {
    dispatch(getSubscription(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      price: singleSubscription?.price,
      plan: singleSubscription?.plan,
      discount: singleSubscription?.discount,
      features: singleSubscription?.features,
    });
  }, [locale, reset, singleSubscription]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: 'sendingErrorMessage' }), locale);
      navigate(`/subscriptions`);
    }
  }, [error, formatMessage, locale, navigate, specialization]);

  const onSubmit = (data) => {
    data.specialization = specialization;
    // data.discount = parseFloat(data.discount);

    dispatch(editSubscription({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  console.log(singleSubscription);
  return (
    <>
      <div className='acc-form'>
        <div className='card'>
          <div className='card-head'>
            <h4>
              <FormattedMessage id={'editSubscription'} />
            </h4>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='price'
                      name='price'
                      register={register}
                      errors={errors}
                      hint={<FormattedMessage id={'SAR'} />}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='planOfMonths'
                      type='number'
                      min={1}
                      defaultValue={1}
                      name='plan'
                      register={register}
                      errors={errors}
                      hint={<FormattedMessage id={'pleaseEnterNumber'} />}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <Input
                      langId='discountPercentage'
                      type='number'
                      defaultValue={0}
                      name='discount'
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <FeaturesArray
                  fields={fields}
                  append={append}
                  remove={remove}
                  register={register}
                  errors={errors}
                />
                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSubscription;
