import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = (props) => {
  return (
    <div className="main-wrapper">
      <Header user={props?.user} logo={props?.logo} />
      <Sidebar logo={props?.logo} />
      <div className="content-wrap" id="content-wrapper">
        {props.children}
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
