import ControlArea from 'components/Layout/ControlArea';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCoupons, getAllReviews } from 'store/actions'; // Adjust the import path
import Icon from 'assets/svgs/categories.svg'; // Assuming you have a reviews icon
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import { useLocation, useNavigate } from 'react-router-dom';

const Coupons = () => {
  const { coupons, timesTamps, loading, error } = useSelector(
    (state) => state.coupons
  );
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get the value of the 'subscriptions' query parameter
  const subscriptions = queryParams.get('subscriptions');

  useEffect(() => {
    setIsDispatched(true);
    if (!subscriptions) {
      navigate('/subscriptions');
    } else {
      dispatch(getAllCoupons({ params: { subscription: subscriptions } }));
    }
  }, [dispatch, navigate, subscriptions]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(
        formatMessage({ id: error?.errors?.msg || 'sendingErrorMessage' }),
        locale
      );
    }
  }, [error, formatMessage, locale, isDispatched]);

  console.log('coupons', coupons)

  return (
    <>
      <div className='coupons-wrap'>
        {/* TODO permission */}
        {checkPermissions(['coupons-store']) && (
          <ControlArea
            btnTxt={<FormattedMessage id={`addCoupon`} />}
            cardTxt={<FormattedMessage id={`coupons`} />} // Adjust the id
            icon={Icon}
            url={`/coupons/add?subscriptions=${subscriptions}`}
          />
        )}
        <Table data={coupons} timesTamps={timesTamps} loading={loading} />
      </div>
    </>
  );
};

export default Coupons;
