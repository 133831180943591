import ControlArea from 'components/Layout/ControlArea';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReviews } from 'store/actions'; // Adjust the import path
import Icon from 'assets/svgs/categories.svg'; // Assuming you have a reviews icon
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';

const Reviews = () => {
  const { reviews, loading, error } = useSelector((state) => state.reviews);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllReviews());
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(
        formatMessage({ id: error?.errors?.msg || 'sendingErrorMessage' }),
        locale
      );
    }
  }, [error, formatMessage, locale, isDispatched]);

  console.log(reviews);

  return (
    <>
      <div className='reviews-wrap'>
        {/* TODO permission */}
        {checkPermissions(['reviews-store']) && (
          <ControlArea
            btnTxt={<FormattedMessage id={`addReview`} />}
            cardTxt={<FormattedMessage id={`reviews`} />} // Adjust the id
            icon={Icon}
            url={`/reviews/add`}
          />
        )}
        <Table data={reviews} loading={loading} />
      </div>
    </>
  );
};

export default Reviews;
