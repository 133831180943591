import ControlArea from 'components/Layout/ControlArea';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMedias } from 'store/actions';
import Icon from "assets/svgs/medias.svg";
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';

const Medias = () => {

  const { medias, loading, error } = useSelector((state) => state.medias);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false)

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllMedias());
  }, [dispatch]);

  // error 
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className="medias-wrap">
        {checkPermissions(["medias-store"]) && <ControlArea
          btnTxt={<FormattedMessage id={"addMedia"} />}
          cardTxt={<FormattedMessage id={"medias"} />}
          icon={Icon}
          url="/medias/add"
        />}

        <Table data={medias} loading={loading} />
      </div>


    </>
  )
}

export default Medias

