import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";

import SaveChangesButton from "components/shared/SaveChangesButton";
import { changePassword } from "api/users";
import { toast } from "react-toastify";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { useState } from "react";
import Loader from "components/shared/Loader";


const ChangePassword = () => {
  const { formatMessage, locale } = useIntl();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  const isBiggerTheSixDigit = (data) => {

    return data.length >= 6 ? true : <FormattedMessage id={"passwordMustBeMoreThanSix"} />
  }
  const isEqualToNewPassword = (data) => {
    return data === getValues("newPassword") ? true : <FormattedMessage id={"PasswordsMustMatch"} />
  }


  const onSubmit = async (data) => {
    delete data.confirmNewPassword;
    setLoading(true);
    try {
      await changePassword(data);
      reset();
      setLoading(false);

      toast.success(formatMessage({ id: "passwordHasChanged" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setLoading(false);
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"changePassword"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* newPassword */}
                <Row>
                  <Col lg={6} xs={12}>
                    <div
                      className="form-group required"
                    >
                      <div className="input-head-wrapper">
                        <h5 >
                          <FormattedMessage id={"newPassword"} />
                        </h5>
                      </div>

                      <input
                        className={`form-control form-outline`}
                        type="password"
                        {...register("newPassword", {
                          required: <FormattedMessage id={"requiredField"} />,
                          validate: isBiggerTheSixDigit,
                        })}
                      />
                      {errors.newPassword && (
                        <p className="error-hint"> {errors?.newPassword.message}</p>
                      )}
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>

                    <div
                      className="form-group required"
                    >
                      <div className="input-head-wrapper">
                        <h5 >
                          <FormattedMessage id={"confirmNewPassword"} />
                        </h5>
                      </div>

                      <input
                        className={`form-control form-outline`}
                        type="password"
                        {...register("confirmNewPassword", {
                          required: <FormattedMessage id={"requiredField"} />,
                          validate: isEqualToNewPassword,
                        })}
                      />
                      {errors.confirmNewPassword && (
                        <p className="error-hint"> {errors?.confirmNewPassword.message}</p>
                      )}
                    </div>
                  </Col>
                </Row>

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
