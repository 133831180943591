import server from './server';

export const getAllQuestionsApi = async (payload) => {
  const { searchParams, specialization } = payload;
  const response = await server().get(
    `/questions/?specialization=${specialization}&${searchParams}`
  );

  return response.data;
};

export const addQuestionApi = async (data) => {
  const response = await server().post('/questions', data);

  return response.data;
};

export const getQuestionApi = async (id) => {
  const response = await server().get(`/questions/${id}`);

  return response.data;
};

export const editQuestionApi = async ({ id, data }) => {
  const response = await server().put(`/questions/${id}`, data);

  return response.data;
};

export const deleteQuestionApi = async (id) => {
  const response = await server().delete(`/questions/${id}`);

  return response.data;
};

export const deleteQuestionCommentApi = async ({ id, commentId }) => {
  const response = await server().delete(`/questions/${id}/${commentId}`);

  return response.data;
};
