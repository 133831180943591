// couponApi.js

import server from './server';

export const getAllCouponsApi = async (payload) => {
  console.log('payload', payload.params);
  const response = await server().get(`/coupons`, {
    params: { ...payload.params },
  });
  return response.data;
};

export const addCouponApi = async (data) => {
  const response = await server().post('/coupons', data);

  return response.data;
};

export const getCouponApi = async (id) => {
  const response = await server().get(`/coupons/${id}`);

  return response.data;
};

export const editCouponApi = async ({ id, data }) => {
  const response = await server().put(`/coupons/${id}`, data);

  return response.data;
};

export const toggleCouponApi = async ({ id, data }) => {
  console.log('id', id, 'data', data)
  const response = await server().put(`/coupons/${id}`, data);
  return response.data;
};

export const deleteCouponApi = async (id) => {
  const response = await server().delete(`/coupons/${id}`);

  return response.data;
};
