import {
  GET_ALL_USERS,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_SUCCESS,

  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE

} from "./actionTypes";



const initialState = {
  users: {},
  loading: false,
  error: "",
  singleUser: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all users  
    case GET_ALL_USERS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_USERS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        users: action.payload,
      };
      break;

    case GET_ALL_USERS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_USER:
      state = {
        ...state,
        error: "",
        loading: true,
      }
      break;

    case ADD_USER_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleUser: action.payload.user,
      }
      break;

    case ADD_USER_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleUser: {},
      }
      break;

    case GET_USER:
      state = {
        ...state,
        error: "",
        loading: true,
        singleUser: {}
      }
      break;

    case GET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleUser: action.payload.user
      }
      break;

    case GET_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break;

    // edit user  
    case EDIT_USER:
      state = {
        ...state,
        error: "",
        loading: true,
        singleUser: {}
      };
      break;

    case EDIT_USER_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleUser: action.payload.user,
      };
      break;

    case EDIT_USER_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;

    // delete page  
    case DELETE_USER:
      state = {
        ...state,
        error: action.payload,
        loading: true,
        singlePage: {},
      };
      break;

    case DELETE_USER_SUCCESS:

      const usersAfterDeleteing = [
        ...state?.users?.users?.filter((user) => user?.id !== action.payload),
      ];

      state = {
        ...state,
        error: "",
        loading: false,
        users: {
          metadata: { ...state.users.metadata },
          users: usersAfterDeleteing
        },
      };
      break;

    case DELETE_USER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
