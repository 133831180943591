import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_PAGE,
  DELETE_PAGE,
  EDIT_PAGE,
  GET_ALL_PAGES,
  GET_PAGE,
} from "./actionTypes";
import {
  addPageApi,
  getAllPagesApi,
  getPageApi,
  editPageApi,
  deletePageApi,
} from "api/pages";
import {
  addPageFailure,
  addPageSuccess,
  deletePageFailure,
  deletePageSuccess,
  editPageFailure,
  // editPageSuccess,
  getAllPagesFailure,
  getAllPagesSuccess,
  getPageFailure,
  getPageSuccess,
} from "./actions";

function* getAllPages() {
  try {
    const { data } = yield call(getAllPagesApi);

    yield put(getAllPagesSuccess(data));
  } catch (error) {
    yield put(getAllPagesFailure(error?.response?.data || error || error));
  }
}

function* addPage({ payload }) {
  try {
    const { data } = yield call(addPageApi, payload.data);

    yield put(addPageSuccess(data));
    payload.navigate("/pages");
  } catch (error) {
    yield put(addPageFailure(error?.response?.data || error || error));
  }
}

function* getPage({ payload }) {
  try {
    const { data } = yield call(getPageApi, payload);
    yield put(getPageSuccess(data));
  } catch (error) {
    yield put(getPageFailure(error?.response?.data || error || error));
  }
}

function* editPage({ payload }) {
  try {
    yield call(editPageApi, { data: payload.data, id: payload.id });

    // yield put(editPageSuccess(data));
    payload.navigate("/pages");
  } catch (error) {
    yield put(editPageFailure(error?.response?.data || error || error));
  }
}

function* deletePage({ payload }) {
  try {
    yield call(deletePageApi, payload);
    yield put(deletePageSuccess(payload));
  } catch (error) {
    yield put(deletePageFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllPages() {
  yield takeEvery(GET_ALL_PAGES, getAllPages);
}

export function* watchAddPage() {
  yield takeEvery(ADD_PAGE, addPage);
}

export function* watchGetPage() {
  yield takeEvery(GET_PAGE, getPage);
}

export function* watchEditPage() {
  yield takeEvery(EDIT_PAGE, editPage);
}

export function* watchDeletePage() {
  yield takeEvery(DELETE_PAGE, deletePage);
}

function* pagesSaga() {
  yield all([
    fork(watchGetAllPages),
    fork(watchAddPage),
    fork(watchGetPage),
    fork(watchEditPage),
    fork(watchDeletePage),
  ]);
}

export default pagesSaga;
