import React, { useMemo, useState, forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useNavigate } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";

const Table = (props) => {
  const { locale, formatMessage } = useIntl()

  let navigate = useNavigate();



  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEditPage = (slug) => {
    navigate(`/pages/${slug}`);
  };




  const columns = [
    {
      name: <FormattedMessage id={"name"} />,
      selector: (row) => row?.title?.[locale],
      sortable: true,
    },

    {
      cell: (row) => (
        <>
          {<button className="edit_button__" onClick={(e) => handleEditPage(row?.slug)} title={formatMessage({ id: "edit" })}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="#fff"
              className="bi bi-pencil edit"
              viewBox="0 0 16 16"
            >
              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
            </svg>
          </button >}

        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4><FormattedMessage id={"pagesTableShow"} /> </h4>
                <p> <FormattedMessage id={"pagesTableShowAll"} /></p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
