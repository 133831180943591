import ControlArea from "components/Layout/ControlArea";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Icon from "assets/svgs/questions.svg";
import Table from "./Table1";
import { getAllBlogs } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { toastErrorMessage } from "helpers/toaster";

const Blogs = () => {
  const { blogs, loading, error } = useSelector((state) => state.blogs);
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllBlogs());
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);

  return (
    <div>
      <div className="blogs_wrap">
        <ControlArea
          btnTxt={<FormattedMessage id={"addBlog"} />}
          cardTxt={<FormattedMessage id={"blogs"} />}
          icon={Icon}
          url="/blogs/add"
        />
        <Table data={blogs} loading={loading} />
      </div>
    </div>
  );
};

export default Blogs;
