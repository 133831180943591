import {
  GET_ALL_SUBSCRIBED_USERS,
  GET_ALL_SUBSCRIBED_USERS_FAILURE,
  GET_ALL_SUBSCRIBED_USERS_SUCCESS,
  // ADD_SUBSCRIBED_USER,
  // ADD_SUBSCRIBED_USER_SUCCESS,
  // ADD_SUBSCRIBED_USER_FAILURE,

} from "./actionTypes";


export const getAllSubscribedUsers = (payload) => {
  return {
    type: GET_ALL_SUBSCRIBED_USERS,
    payload: payload,
  };
}
export const getAllSubscribedUsersSuccess = (payload) => {
  return {
    type: GET_ALL_SUBSCRIBED_USERS_SUCCESS,
    payload: payload,
  };
}

export const getAllSubscribedUsersFailure = (error) => {
  return {
    type: GET_ALL_SUBSCRIBED_USERS_FAILURE,
    payload: error,
  };
}

// // add user
// export const addSubscribedUser = (payload) => {
//   return {
//     type: ADD_SUBSCRIBED_USER,
//     payload: payload
//   }
// }

// export const addSubscribedUserSuccess = (payload) => {
//   return {
//     type: ADD_SUBSCRIBED_USER_SUCCESS,
//     payload: payload
//   }
// }

// export const addSubscribedUserFailure = (error) => {
//   return {
//     type: ADD_SUBSCRIBED_USER_FAILURE,
//     payload: error
//   }
// }
