import server from "./server";

export const getAllBlogsApi = async () => {
  const response = await server().get(`/blogs`);

  return response.data;
};

export const addBlogApi = async (data) => {
  const response = await server().post("/blogs", data);

  return response.data;
};

export const getBlogApi = async (id) => {
  const response = await server().get(`/blogs/${id}`);

  return response.data;
};

export const editBlogApi = async ({ id, data }) => {
  const response = await server().patch(`/blogs/${id}`, data);

  return response.data;
};

export const deleteBlogApi = async (id) => {
  const response = await server().delete(`/blogs/${id}`);

  return response.data;
};

export const deleteBlogCommentApi = async ({ id, commentId }) => {
  const response = await server().delete(`/blogs/${id}/${commentId}`);

  return response.data;
};
