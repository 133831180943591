import {
  ADD_MEDIA, ADD_MEDIA_FAILURE, ADD_MEDIA_SUCCESS,
  DELETE_MEDIA, DELETE_MEDIA_FAILURE, DELETE_MEDIA_SUCCESS,
  EDIT_MEDIA, EDIT_MEDIA_FAILURE, EDIT_MEDIA_SUCCESS,
  GET_ALL_MEDIAS, GET_ALL_MEDIAS_FAILURE, GET_ALL_MEDIAS_SUCCESS,
  GET_MEDIA, GET_MEDIA_FAILURE, GET_MEDIA_SUCCESS
} from "./actionTypes";



const initialState = {
  medias: [],
  loading: false,
  error: "",
  singleMedia: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all medias  
    case GET_ALL_MEDIAS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_MEDIAS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        medias: action.payload.medias,
      };
      break;

    case GET_ALL_MEDIAS_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add Media
    case ADD_MEDIA:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_MEDIA_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleMedia: action.payload.media,
      };
      break;

    case ADD_MEDIA_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get Media  
    case GET_MEDIA:
      state = {
        ...state,
        error: "",
        loading: true,
        singleMedia: {}
      };
      break;

    case GET_MEDIA_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleMedia: action.payload.media,
      };
      break;

    case GET_MEDIA_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit Media  
    case EDIT_MEDIA:
      state = {
        ...state,
        error: "",
        loading: true,
        singleMedia: {}
      };
      break;

    case EDIT_MEDIA_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleMedia: action.payload.media,
      };
      break;

    case EDIT_MEDIA_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete Media  
    case DELETE_MEDIA:
      state = {
        ...state,
        error: "",
        loading: true,
        singleMedia: {}
      };
      break;

    case DELETE_MEDIA_SUCCESS:
      const mediasAfterDeleteing = [
        ...state?.medias?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        medias: mediasAfterDeleteing,
      };
      break;

    case DELETE_MEDIA_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
