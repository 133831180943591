import ControlArea from 'components/Layout/ControlArea';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories, getAllSpecializations } from 'store/actions';
import Icon from 'assets/svgs/categories.svg';
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';

const Categories = () => {
  const { categories, loading, error } = useSelector(
    (state) => state.categories
  );
  const { specializations, loading: specializationLoading } = useSelector(
    (state) => state.specializations
  );
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);
  // get the category type from url
  const categoryType = window.location.pathname?.split('/')?.at(-1);

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllSpecializations());
  }, [dispatch]);

  
  useEffect(() => {
    if (specializations && specializations.length > 0) {
      dispatch(getAllCategories({ specialization: specializations[0].id }));
      console.log(categories);
    }
  }, [dispatch, specializations]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(
        formatMessage({ id: error?.errors?.msg || 'sendingErrorMessage' }),
        locale
      );
    }
  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className='categories-wrap'>
        {/* TODO permission */}
        {checkPermissions(['categories-store']) && (
          <ControlArea
            btnTxt={<FormattedMessage id={`addCategory`} />}
            cardTxt={<FormattedMessage id={`${categoryType}Categories`} />}
            icon={Icon}
            url={`/categories/main/add`}
          />
        )}
        <Table
          specializations={specializations}
          specializationLoading={specializationLoading}
          data={categories}
          loading={loading}
          categoryType={categoryType}
        />
      </div>
    </>
  );
};

export default Categories;
