import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import userImage from 'assets/images/user.png';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteQuestionComment } from 'store/actions';

function QuestionComments({ singleQuestion }) {
  const dispatch = useDispatch();

  const handleDeleteComment = (commentId) => {
    // dispatch(deleteQuestionComment({ id: singleQuestion?.id, commentId: commentId }));
  };

  return (
    <>
      {singleQuestion?.comments?.length > 0 ? (
        <div className='question-comments card mt-4'>
          <div className='card-body'>
            {/* <div className='card-head '>
              <h4>
                <FormattedMessage id='comments' />
              </h4>
            </div> */}
            <div className='comments_content__'>
              <Row>
                {singleQuestion?.comments?.map((comment) => {
                  return (
                    <Col lg={6} md={12} className='m-b-15' key={comment?._id}>
                      <div className='comment_block content__block__'>
                        <Link
                          className=' comment_user '
                          to={`/users/show/${comment?.user?.id}`}
                        >
                          <div className='img_block__'>
                            <img
                              src={comment?.user?.image || userImage}
                              alt=''
                            />
                          </div>
                          <div className='desc__'>
                            <p className='name__'>{comment?.user?.fullName}</p>
                            <p className='date__'>
                              {
                                <FormattedDate
                                  value={comment?.createdAt}
                                  day='numeric'
                                  month='long'
                                  year='numeric'
                                />
                              }
                            </p>
                          </div>
                        </Link>
                        <p className='comment-details content_block_details__'>
                          {comment?.comment}
                        </p>
                        <button
                          className='delete_comment_'
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteComment(comment?._id);
                          }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='12'
                            height='12'
                            fill='#fff'
                            className='bi bi-trash delete'
                            viewBox='0 0 16 16'
                          >
                            <path d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z' />
                            <path
                              fillRule='evenodd'
                              d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'
                            />
                          </svg>
                        </button>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center py-4'>
          <h5>
            <FormattedMessage id='noQuestionComments' />
          </h5>
        </div>
      )}
    </>
  );
}

export default QuestionComments;
