// couponSaga.js

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ADD_COUPON,
  DELETE_COUPON,
  EDIT_COUPON,
  GET_ALL_COUPONS,
  GET_COUPON,
  TOGGLE_COUPON,
} from './actionTypes';
import {
  addCouponApi,
  getAllCouponsApi,
  getCouponApi,
  editCouponApi,
  deleteCouponApi,
  toggleCouponApi,
} from 'api/coupons'; // replace 'api/coupons' with your actual path
import {
  addCouponFailure,
  addCouponSuccess,
  deleteCouponFailure,
  deleteCouponSuccess,
  editCouponFailure,
  editCouponSuccess,
  getAllCouponsFailure,
  getAllCouponsSuccess,
  getCouponFailure,
  getCouponSuccess,
  toggleCouponFailure,
  toggleCouponSuccess,
} from './actions';

function* getAllCoupons({ payload }) {
  try {
    const { data } = yield call(getAllCouponsApi, payload);

    yield put(getAllCouponsSuccess(data));
  } catch (error) {
    yield put(getAllCouponsFailure(error?.response?.data || error || error));
  }
}

function* addCoupon({ payload }) {
  try {
    const { data } = yield call(addCouponApi, payload.data);

    yield put(addCouponSuccess(data));
    payload.navigate(`/coupons?subscriptions=${payload.subscription}`);

    // Add any additional logic based on your application's requirements
  } catch (error) {
        console.log('error--------------', error?.response?.data);

    yield put(addCouponFailure(error?.response?.data || error || error));
  }
}

function* getCoupon({ payload }) {
  try {
    const { data } = yield call(getCouponApi, payload);

    yield put(getCouponSuccess(data));
  } catch (error) {
    yield put(getCouponFailure(error?.response?.data || error || error));
  }
}

function* editCoupon({ payload }) {
  try {
    const { data } = yield call(editCouponApi, {
      data: payload.data,
      id: payload.id,
    });
    console.log('data--------------', data);
    yield put(editCouponSuccess(data));

    payload.navigate(`/coupons?subscriptions=${payload.subscription}`);
  } catch (error) {
    yield put(editCouponFailure(error?.response?.data || error || error));
  }
}

function* toggleCoupon({ payload }) {
  try {
    const { data } = yield call(toggleCouponApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(toggleCouponSuccess(data));
    payload.navigate(`/coupons?subscriptions=${payload.subscription}`);
  } catch (error) {
    yield put(toggleCouponFailure(error?.response?.data || error || error));
  }
}


function* deleteCoupon({ payload }) {
  try {
    console.log('payload', payload)
    yield call(deleteCouponApi, payload);
    yield put(deleteCouponSuccess(payload));
  } catch (error) {
    yield put(deleteCouponFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllCoupons() {
  yield takeEvery(GET_ALL_COUPONS, getAllCoupons);
}

export function* watchAddCoupon() {
  yield takeEvery(ADD_COUPON, addCoupon);
}

export function* watchGetCoupon() {
  yield takeEvery(GET_COUPON, getCoupon);
}

export function* watchEditCoupon() {
  yield takeEvery(EDIT_COUPON, editCoupon);
}

export function* watchToggleCoupon() {
  yield takeEvery(TOGGLE_COUPON, toggleCoupon);
}

export function* watchDeleteCoupon() {
  yield takeEvery(DELETE_COUPON, deleteCoupon);
}

function* couponSaga() {
  yield all([
    fork(watchGetAllCoupons),
    fork(watchAddCoupon),
    fork(watchGetCoupon),
    fork(watchEditCoupon),
    fork(watchToggleCoupon),
    fork(watchDeleteCoupon),
  ]);
}

export default couponSaga;
