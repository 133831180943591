import server from "./server";

export const getAllMediasApi = async () => {
  const response = await server().get("/medias");

  return response.data;
}

export const addMediaApi = async (data) => {
  const response = await server().post("/medias", data);

  return response.data;
}


export const getMediaApi = async (id) => {
  const response = await server().get(`/medias/${id}`);

  return response.data;
}

export const editMediaApi = async ({ id, data }) => {
  const response = await server().put(`/medias/${id}`, data);

  return response.data;
}

export const deleteMediaApi = async (id) => {
  const response = await server().delete(`/medias/${id}`);

  return response.data;
}