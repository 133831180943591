import {
  ADD_FAQ,
  ADD_FAQ_FAILURE,
  ADD_FAQ_SUCCESS,
  DELETE_FAQ,
  DELETE_FAQ_FAILURE,
  DELETE_FAQ_SUCCESS,
  EDIT_FAQ,
  EDIT_FAQ_FAILURE,
  EDIT_FAQ_SUCCESS,
  GET_ALL_FAQS,
  GET_ALL_FAQS_FAILURE,
  GET_ALL_FAQS_SUCCESS,
  GET_FAQ,
  GET_FAQ_FAILURE,
  GET_FAQ_SUCCESS,
} from "./actionTypes";

const initialState = {
  faqs: [],
  metadata: {},
  loading: false,
  error: "",
  singleFaq: {},
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_FAQS:
    case ADD_FAQ:
    case GET_FAQ:
    case EDIT_FAQ:
    case DELETE_FAQ:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case GET_ALL_FAQS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        faqs: action.payload.faqs,
        metadata: action.payload.metadata,
      };

    case GET_ALL_FAQS_FAILURE:
    case ADD_FAQ_FAILURE:
    case GET_FAQ_FAILURE:
    case EDIT_FAQ_FAILURE:
    case DELETE_FAQ_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_FAQ_SUCCESS:
    case GET_FAQ_SUCCESS:
    case EDIT_FAQ_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        singleFaq: action.payload.faq,
      };

    case DELETE_FAQ_SUCCESS:
      const faqsAfterDelete = state.faqs.filter(
        (faq) => faq.id !== action.payload
      );
      return {
        ...state,
        error: "",
        loading: false,
        faqs: faqsAfterDelete,
      };

    default:
      return state;
  }
};

export default faqReducer;
