import server from './server';

export const getAllSpecializationsApi = async () => {
  const response = await server().get('/specializations'); // Adjust the endpoint

  return response.data;
};

export const addSpecializationApi = async (data) => {
  const formData = new FormData();

  formData.append('title', data.title);
  formData.append('subTitle', data.subTitle);
  formData.append('image', data.image);

  const response = await server().post('/specializations', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const getSpecializationApi = async (id) => {
  const response = await server().get(`/specializations/${id}`); // Adjust the endpoint

  return response.data;
};

export const editSpecializationApi = async ({ id, data }) => {
  const response = await server().put(`/specializations/${id}`, data); // Adjust the endpoint

  return response.data;
};

export const deleteSpecializationApi = async (id) => {
  const response = await server().delete(`/specializations/${id}`); // Adjust the endpoint

  return response.data;
};
