import {
  ADD_REVIEW,
  ADD_REVIEW_FAILURE,
  ADD_REVIEW_SUCCESS,
  DELETE_REVIEW,
  DELETE_REVIEW_FAILURE,
  DELETE_REVIEW_SUCCESS,
  EDIT_REVIEW,
  EDIT_REVIEW_FAILURE,
  EDIT_REVIEW_SUCCESS,
  GET_ALL_REVIEWS,
  GET_ALL_REVIEWS_FAILURE,
  GET_ALL_REVIEWS_SUCCESS,
  GET_REVIEW,
  GET_REVIEW_FAILURE,
  GET_REVIEW_SUCCESS,
} from './actionTypes';

export const getAllReviews = (payload) => {
  return {
    type: GET_ALL_REVIEWS,
    payload: payload,
  };
};

export const getAllReviewsSuccess = (payload) => {
  return {
    type: GET_ALL_REVIEWS_SUCCESS,
    payload: payload,
  };
};

export const getAllReviewsFailure = (error) => {
  return {
    type: GET_ALL_REVIEWS_FAILURE,
    payload: error,
  };
};

export const addReview = (payload) => {
  return {
    type: ADD_REVIEW,
    payload: payload,
  };
};

export const addReviewSuccess = (payload) => {
  return {
    type: ADD_REVIEW_SUCCESS,
    payload: payload,
  };
};

export const addReviewFailure = (error) => {
  return {
    type: ADD_REVIEW_FAILURE,
    payload: error,
  };
};

export const getReview = (payload) => {
  return {
    type: GET_REVIEW,
    payload: payload,
  };
};

export const getReviewSuccess = (payload) => {
  return {
    type: GET_REVIEW_SUCCESS,
    payload: payload,
  };
};

export const getReviewFailure = (error) => {
  return {
    type: GET_REVIEW_FAILURE,
    payload: error,
  };
};

export const editReview = (payload) => {
  return {
    type: EDIT_REVIEW,
    payload: payload,
  };
};

export const editReviewSuccess = (payload) => {
  return {
    type: EDIT_REVIEW_SUCCESS,
    payload: payload,
  };
};

export const editReviewFailure = (error) => {
  return {
    type: EDIT_REVIEW_FAILURE,
    payload: error,
  };
};

export const deleteReview = (payload) => {
  return {
    type: DELETE_REVIEW,
    payload: payload,
  };
};

export const deleteReviewSuccess = (payload) => {
  return {
    type: DELETE_REVIEW_SUCCESS,
    payload: payload,
  };
};

export const deleteReviewFailure = (error) => {
  return {
    type: DELETE_REVIEW_FAILURE,
    payload: error,
  };
};
