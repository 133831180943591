import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  ADD_FAQ,
  DELETE_FAQ,
  EDIT_FAQ,
  GET_ALL_FAQS,
  GET_FAQ,
} from "./actionTypes";
import {
  addFaqApi,
  getAllFaqsApi,
  getFaqApi,
  editFaqApi,
  deleteFaqApi,
} from "api/faqs";
import {
  addFaqFailure,
  addFaqSuccess,
  deleteFaqFailure,
  deleteFaqSuccess,
  editFaqFailure,
  editFaqSuccess,
  getAllFaqsFailure,
  getAllFaqsSuccess,
  getFaqFailure,
  getFaqSuccess,
} from "./actions";

function* getAllFaqs({ payload }) {
  try {
    const { data } = yield call(getAllFaqsApi, payload);
    yield put(getAllFaqsSuccess(data));
  } catch (error) {
    yield put(getAllFaqsFailure(error?.response?.data || error || error));
  }
}

function* addFaq({ payload }) {
  console.log({ payload });
  try {
    const { data } = yield call(addFaqApi, payload.data);
    yield put(addFaqSuccess(data));
    payload.navigate("/faqs");
  } catch (error) {
    console.log("error", error);
    yield put(addFaqFailure(error?.response?.data || error || error));
  }
}

function* getFaq({ payload }) {
  try {
    const { data } = yield call(getFaqApi, payload);
    yield put(getFaqSuccess(data));
  } catch (error) {
    yield put(getFaqFailure(error?.response?.data || error || error));
  }
}

function* editFaq({ payload }) {
  try {
    const { data } = yield call(editFaqApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editFaqSuccess(data));
    payload.navigate("/faqs");
  } catch (error) {
    yield put(editFaqFailure(error?.response?.data || error || error));
  }
}

function* deleteFaq({ payload }) {
  try {
    yield call(deleteFaqApi, payload);
    yield put(deleteFaqSuccess(payload));
  } catch (error) {
    yield put(deleteFaqFailure(error?.response?.data || error || error));
  }
}

export function* watchGetAllFaqs() {
  yield takeEvery(GET_ALL_FAQS, getAllFaqs);
}

export function* watchAddFaq() {
  yield takeEvery(ADD_FAQ, addFaq);
}

export function* watchGetFaq() {
  yield takeEvery(GET_FAQ, getFaq);
}

export function* watchEditFaq() {
  yield takeEvery(EDIT_FAQ, editFaq);
}

export function* watchDeleteFaq() {
  yield takeEvery(DELETE_FAQ, deleteFaq);
}

function* faqsSaga() {
  yield all([
    fork(watchGetAllFaqs),
    fork(watchAddFaq),
    fork(watchGetFaq),
    fork(watchEditFaq),
    fork(watchDeleteFaq),
  ]);
}

export default faqsSaga;
