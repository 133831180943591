import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import {
  deleteBlog,
  deleteFaq,
  deleteQuestion,
  deleteQuestionComment,
  getQuestion,
} from "store/actions";
import { useState } from "react";
import LightBox from "components/shared/LightBox";
import userImage from "assets/images/user.png";
import quesMark from "assets/images/ques-mark.png";
import Accordion from "react-bootstrap/Accordion";

const ShowBlog = ({ singleBlog }) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const handleBlogDelete = (id) => {
    dispatch(deleteBlog(id));
  };

  console.log({ singleBlog });

  return (
    <>
      <div className='show-question-section card border-0 mt-4'>
        <div className='card-body'>
          <div className='d-flex flex-column align-items-center gap-2'>
            <div
              style={{ width: '300px', height: '200px', textAlign: 'center' }}
            >
              <img
                style={{ height: '100%' }}
                src={singleBlog.featuredImage}
                alt='Featured Image'
              />
            </div>
            <div className='card-head mb-0 question_show_heading_'>
              <p>{singleBlog.title[locale]}</p>
            </div>
          </div>

          <div className='answers-content'>
            <div className={`content__block__ answer-block `}>
              <div
                className='question-content__'
                dangerouslySetInnerHTML={{
                  __html: singleBlog?.content[locale],
                }}
              />
            </div>
          </div>
        </div>
        <div className='question_buttons__ text-start'>
          <button
            className='question_button__'
            onClick={(e) => {
              navigate(`/blogs/${singleBlog.id}`);
            }}
          >
            <>
              <div className='shape__'>
                <svg
                  width='24'
                  height='25'
                  viewBox='0 0 24 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M13.2603 4.10022L5.05034 12.7902C4.74034 13.1202 4.44034 13.7702 4.38034 14.2202L4.01034 17.4602C3.88034 18.6302 4.72034 19.4302 5.88034 19.2302L9.10034 18.6802C9.55034 18.6002 10.1803 18.2702 10.4903 17.9302L18.7003 9.24022C20.1203 7.74022 20.7603 6.03022 18.5503 3.94022C16.3503 1.87022 14.6803 2.60022 13.2603 4.10022V4.10022Z'
                    stroke='white'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11.89 5.5498C12.0996 6.89066 12.7486 8.12394 13.735 9.05605C14.7214 9.98816 15.9894 10.5663 17.34 10.6998M3 22.4998H21'
                    stroke='white'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <FormattedMessage id='edit' />
            </>
          </button>
          <button
            className='question_button__ delete__'
            onClick={(e) => {
              e.preventDefault();
              handleBlogDelete(singleBlog?.id);
            }}
          >
            <div className='shape__'>
              <svg
                width='22'
                height='23'
                viewBox='0 0 22 23'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8.17 14.33L13.83 8.67M13.83 14.33L8.17 8.67M11 21.5C16.5 21.5 21 17 21 11.5C21 6 16.5 1.5 11 1.5C5.5 1.5 1 6 1 11.5C1 17 5.5 21.5 11 21.5Z'
                  stroke='#E80000'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>

            <FormattedMessage id='delete' />
          </button>
        </div>
      </div>
    </>
  );
};

export default ShowBlog;
