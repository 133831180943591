export const GET_ALL_CITIES = "GET_ALL_CITIES";
export const GET_ALL_CITIES_SUCCESS = "GET_ALL_CITIES_SUCCESS";
export const GET_ALL_CITIES_FAILURE = "GET_ALL_CITIES_FAILURE";

export const GET_CITY = "GET_CITY";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";

export const ADD_CITY = "ADD_CITY";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_FAILURE = "ADD_CITY_FAILURE";

export const EDIT_CITY = "EDIT_CITY";
export const EDIT_CITY_SUCCESS = "EDIT_CITY_SUCCESS";
export const EDIT_CITY_FAILURE = "EDIT_CITY_FAILURE";

export const DELETE_CITY = "DELETE_CITY";
export const DELETE_CITY_SUCCESS = "DELETE_CITY_SUCCESS";
export const DELETE_CITY_FAILURE = "DELETE_CITY_FAILURE";