import { getId, handleIsoDate, isObjectEmpty } from 'helpers/functions';
import { useState } from 'react';
import {
  Accordion,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import EditQuestion from './EditQuestion';
import FormLoader from 'components/shared/FormLoader';
import QuestionCommentsTab from './QuestionCommentsTab';
import './questions.css';
import ShowQuestionTab from './ShowQuestionTab';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';
import { addQuestion } from 'store/actions';
import { Link } from 'react-router-dom';
import ShowQuestion from './ShowQuestion';

const Table = ({ data }) => {
  // const dispatch = useDispatch();
  const { locale, formatMessage } = useIntl();
  const { categories } = useSelector((state) => state.categories);

  const [categoryId, setCategoryId] = useState();
  const [allQuestions, setAllQuestions] = useState();
  const [singleQuestion, setSingleQuestion] = useState({
    loading: false,
    question: {},
    view: 'show',
    currentIndex: '',
  });
  const [cloneQuestion, setCloneQuestion] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    setAllQuestions(data);
  }, [data]);

  const renderQuesions = () => {
    return (
      <div className=' questions_list__'>
        {allQuestions?.length > 0 ? (
          allQuestions?.map((question, index) => {
            // set the modifing question to the single quesion in the state to show it after modify
            if (
              !isObjectEmpty(singleQuestion?.question) &&
              singleQuestion?.question?.id === question?.id
            ) {
              question = singleQuestion?.question;
            }

            return (
              <Accordion.Item eventKey={question?.id} key={question?.id}>
                <Accordion.Header
                  className='question_block__ hide'
                  title={question?.title?.replace(/<[^>]*>?/gm, '')}
                  onClick={() =>
                    setSingleQuestion((prevState) => ({
                      ...prevState,
                      question,
                      currentIndex: index,
                    }))
                  }
                >
                  <div
                    className='col__ title__ '
                    dangerouslySetInnerHTML={{
                      __html: question?.title,
                    }}
                  ></div>

                  <div className='col__ category__ category_name'>
                    {question?.category?.category?.category &&
                      `${
                        categories?.find(
                          (e) =>
                            getId(e) == question?.category?.category?.category
                        )?.name?.[locale] || ''
                      } -`}
                    {` ${question?.category?.name?.[locale]}`}
                    {` - ${question?.category?.category?.name?.[locale]}`}
                  </div>
                  <div className='col__ date__ '>
                    <span className='d-flex align-items-center'>
                      {handleIsoDate(question?.createdAt)}
                    </span>
                  </div>
                  <div className='col__ date__ d-flex justify-content-evenly align-content-center gap-2'>
                    <div
                      className='btn btn-blue accordin-btn  flex-nowrap '
                      onClick={(e) => {
                        e.stopPropagation();
                        // setCloneQuestion({ ...question });
                        setCategoryId(question.category._id);
                        setCloneQuestion({ ...question, category: '' });
                        toggleModal();
                      }}
                      title={formatMessage({ id: 'cloneQuestion' })}
                    >
                      <div>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='14'
                          height='14'
                          fill='currentColor'
                          className='bi bi-back'
                          viewBox='0 0 16 16'
                        >
                          <path d='M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z' />
                        </svg>
                      </div>
                      <span>
                        <FormattedMessage id='clone' />
                      </span>
                    </div>
                    {/* <Link
                      to={`/questions/show/${question?.id}`}
                      className='d-flex btn btn-blue '
                    > */}
                    <div
                      className='btn btn-blue accordin-btn d-flex flex-nowrap gap-0'
                      title={formatMessage({ id: 'showDetails' })}
                      onClick={() =>
                        setSingleQuestion((prevState) => ({
                          ...prevState,
                          question,
                          currentIndex: index,
                        }))
                      }
                    >
                      {/* <div> */}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='14'
                        height='14'
                        fill='currentColor'
                        className='bi bi-eye-fill add'
                        viewBox='0 0 16 16'
                      >
                        <path d='M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z' />
                        <path d='M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z' />
                      </svg>
                      <span>
                        <FormattedMessage id='showAllDetails' />
                      </span>
                    </div>
                    {/* </div> */}
                    {/* </Link> */}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {/* <ShowQuestion
                    singleQuestion={singleQuestion.question}
                    currentIndex={singleQuestion.currentIndex}
                    setEditModal={setEditModal}
                  /> */}
                  {singleQuestion?.view === 'edit' &&
                  singleQuestion?.question?.id === question?.id ? (
                    <EditQuestion
                      editModal={editModal}
                      singleQuestion={singleQuestion}
                      setSingleQuestion={setSingleQuestion}
                      setAllQuestions={setAllQuestions}
                      allQuestions={allQuestions}
                    />
                  ) : (
                    <ShowQuestion
                    currentIndex={singleQuestion.currentIndex}
                    question={question}
                    singleQuestion={singleQuestion.question}
                    setSingleQuestion={setSingleQuestion}
                    loading={singleQuestion?.loading}
                  />
                    // <Tabs
                    //   defaultActiveKey='showQuestion'
                    //   id='questions_tabs__'
                    //   className='questions_tabs__'
                    // >
                    //   <Tab
                    //     eventKey='showQuestion'
                    //     title={formatMessage({ id: 'showQuestion' })}
                    //   >
                       
                    //   </Tab>
                    //   <Tab
                    //     eventKey='comments'
                    //     title={formatMessage({ id: 'comments' })}
                    //   ></Tab>
                    // </Tabs>
                  )} 
           

                  {/* <ShowQuestionTab
                    index={index}
                    question={question}
                    setSingleQuestion={setSingleQuestion}
                    loading={singleQuestion?.loading}
                  /> */}
                  {/* <QuestionCommentsTab
                    comments={question?.comments}
                    questionId={question?.id}
                  /> */}
                        {/* {singleQuestion?.question?.id === question?.id && (
                    <EditQuestion
                      showModal={editModal}
                      setShowModal={setEditModal}
                      singleQuestion={singleQuestion}
                      setSingleQuestion={setSingleQuestion}
                      setAllQuestions={setAllQuestions}
                      allQuestions={allQuestions}
                    />
                  )} */}

                  {singleQuestion?.loading ? (
                    <FormLoader fill={'#12c8cb'} />
                  ) : (
                    ''
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })
        ) : (
          <div className='form-group'>
            <h5 className='safari-warn alert alert-warning bg-secondary text-center text-white'>
              <FormattedMessage id='no-questions' />
            </h5>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className='questions_table_content__'>
        <Accordion defaultActiveKey={data?.[0]?.id}>
          {renderQuesions()}
          {modalOpen && (
            <CloneModal
              {...{
                categories,
                cloneQuestion,
                toggleModal,
                categoryId,
              }}
            />
          )}
        </Accordion>
      </div>
    </>
  );
};
const CloneModal = ({ categories, cloneQuestion, toggleModal }) => {
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const mainCategories = categories?.filter((category) => !category?.category);

  const [subCategories, setSubCategories] = useState([]);
  const [subCategoriesChildren, setSubCategoriesChildren] = useState([]);
  const [subCategoriesChild, setSubCategoriesChild] = useState([]);
  const [defaultSubCategoryVal, setDefaultSubCategoryVal] = useState({
    value: "",
    label: "",
  });
  const [selectedCategory, setSelectedCategory] = useState("");


  console.log('clone Questions', cloneQuestion)
  const handleSelectOptions = (options) => {
    console.log('option', options)
    if (options.length < 0) return [];

    return options.map((option) => {
      return {
        value: getId(option),
        label: `${option?.name?.[locale]} ${
          (
            cloneQuestion?.parent?.copycategories ||
            cloneQuestion?.copycategories ||
            []
          )?.includes(getId(option))
            ? `(${formatMessage({ id: "copied" })})`
            : ""
        }`,
        copied: (
          cloneQuestion?.parent?.copycategories ||
          cloneQuestion?.copycategories ||
          []
        )?.includes(getId(option)),
      };
    });
  };

  const onSubmit = () => {
    let data = cloneQuestion;
    data.type = "checkbox";
    data.comments = [];
    data.category = selectedCategory;

    console.log('cloneQuestion', cloneQuestion)
    console.log('selectedCategory', selectedCategory)

    if (
      (data?.parent?.copycategories || data?.copycategories || [])?.includes(
        getId(data?.category)
      )
    )
      return toastErrorMessage(formatMessage({ id: "copiedAlready" }), locale);

    if (!data?.parent) data.parent = getId(data);
    else data.parent = getId(data?.parent) || data?.parent;
    

    // delete data.copycategories;
    delete data.mainCategory;
    
    cloneQuestion.copycategories.push(selectedCategory)

    if (!data?.category)
      return toastErrorMessage(formatMessage({ id: "subCatError" }), locale);

    dispatch(addQuestion({ data, navigate: toggleModal }));
  };

  // return "asdsad";
  return (
    <Modal show onHide={toggleModal}>
      <Modal.Header onHide={toggleModal} closeButton></Modal.Header>
      <Modal.Body className="questions_list__">
        <Row>
          {/* mainCategory */}
          <div className="question_blcok__ col__">
            <FormattedMessage id="clone" /> <FormattedMessage id="question" />
            <div
              className="card col__ title__"
              dir="auto"
              dangerouslySetInnerHTML={{
                __html: cloneQuestion?.title?.replace(/<[^>]*>?/gm, ""),
              }}
            ></div>
          </div>
          <Col lg={6} xs={12}>
            <div className="form-group ">
              <h5 className="input_head_">
                {" "}
                <FormattedMessage id={"mainCategory"} />
              </h5>
              <div>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder={<FormattedMessage id={"mainCategory"} />}
                  options={handleSelectOptions(mainCategories)}
                  onChange={(selectedOption) => {
                    setDefaultSubCategoryVal({
                      value: "",
                      label: "",
                    });
                    setSubCategories([]);
                    setSubCategoriesChildren([]);

                    const subCategories = categories?.filter(
                      (category) =>
                        category?.category?._id === selectedOption?.value
                    );
                    if (!subCategories?.length > 0)
                      toastErrorMessage(
                        formatMessage({ id: "noSubsMainCat" }),
                        locale
                      );

                    setSubCategories(subCategories);
                  }}
                  isRtl={true}
                  isSearchable={false}
                  className="basic-single"
                  classNamePrefix="select"
                />
              </div>
            </div>
          </Col>

          <Col lg={6} xs={12}>
            <div className="form-group ">
              <h5 className="input_head_">
                {" "}
                <FormattedMessage id={"subCategory"} />
              </h5>
              <div>
                <Select
                  isDisabled={
                    !(
                      typeof subCategories !== "undefined" &&
                      subCategories.length > 0
                    )
                  }
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                  placeholder={<FormattedMessage id={"subCategory"} />}
                  options={handleSelectOptions(subCategories)}
                  components={{
                    Option: (props) => {
                      if (props?.data?.copied) {
                        return (
                          <div className="bg-warning bg-opacity-50">
                            <components.Option {...props} />
                          </div>
                        );
                      }
                      return <components.Option {...props} />;
                    },
                  }}
                  onChange={(selectedOption) => {
                    // --------------------------------------------
                    setSubCategoriesChildren(
                      categories?.filter(
                        (category) =>
                          getId(category?.category) === selectedOption?.value
                      )
                    );
                    setSelectedCategory(selectedOption?.value);
                    setSubCategoriesChild({});

                    // ------------------------------------

                    setDefaultSubCategoryVal(selectedOption);
                  }}
                  isRtl={true}
                  isSearchable={false}
                  className="basic-single"
                  classNamePrefix="select"
                  value={defaultSubCategoryVal}
                />
              </div>
            </div>
          </Col>
          {typeof subCategoriesChildren !== "undefined" &&
            subCategoriesChildren.length > 0 && (
              <Col lg={6} xs={12}>
                <div className="form-group ">
                  <h5 className="input_head_">
                    {" "}
                    <FormattedMessage id={"subCategoryChild"} />
                  </h5>
                  <div>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                      }}
                      placeholder={<FormattedMessage id={"subCategoryChild"} />}
                      options={handleSelectOptions(subCategoriesChildren)}
                      components={{
                        Option: (props) => {
                          if (props?.data?.copied) {
                            return (
                              <div className="bg-warning bg-opacity-50">
                                <components.Option {...props} />
                              </div>
                            );
                          }
                          return <components.Option {...props} />;
                        },
                      }}
                      onChange={(selectedOption) => {
                        setSubCategoriesChild(selectedOption);
                        setSelectedCategory(selectedOption?.value);
                      }}
                      isRtl={true}
                      isSearchable={false}
                      className="basic-single"
                      classNamePrefix="select"
                      value={subCategoriesChild}
                    />
                  </div>
                </div>
              </Col>
            )}
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-opacity-25 border-secondary">
        <Button className="btn btn-blue border-0" onClick={onSubmit}>
          <FormattedMessage id={"clone"} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Table;
