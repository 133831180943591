export const GET_ALL_SPECIALIZATIONS = 'GET_ALL_SPECIALIZATIONS';
export const GET_ALL_SPECIALIZATIONS_SUCCESS =
  'GET_ALL_SPECIALIZATIONS_SUCCESS';
export const GET_ALL_SPECIALIZATIONS_FAILURE =
  'GET_ALL_SPECIALIZATIONS_FAILURE';

export const GET_SPECIALIZATION = 'GET_SPECIALIZATION';
export const GET_SPECIALIZATION_SUCCESS = 'GET_SPECIALIZATION_SUCCESS';
export const GET_SPECIALIZATION_FAILURE = 'GET_SPECIALIZATION_FAILURE';

export const ADD_SPECIALIZATION = 'ADD_SPECIALIZATION';
export const ADD_SPECIALIZATION_SUCCESS = 'ADD_SPECIALIZATION_SUCCESS';
export const ADD_SPECIALIZATION_FAILURE = 'ADD_SPECIALIZATION_FAILURE';

export const EDIT_SPECIALIZATION = 'EDIT_SPECIALIZATION';
export const EDIT_SPECIALIZATION_SUCCESS = 'EDIT_SPECIALIZATION_SUCCESS';
export const EDIT_SPECIALIZATION_FAILURE = 'EDIT_SPECIALIZATION_FAILURE';

export const DELETE_SPECIALIZATION = 'DELETE_SPECIALIZATION';
export const DELETE_SPECIALIZATION_SUCCESS = 'DELETE_SPECIALIZATION_SUCCESS';
export const DELETE_SPECIALIZATION_FAILURE = 'DELETE_SPECIALIZATION_FAILURE';
