export const GET_ALL_UNIVERSITIES = "GET_ALL_UNIVERSITIES";
export const GET_ALL_UNIVERSITIES_SUCCESS = "GET_ALL_UNIVERSITIES_SUCCESS";
export const GET_ALL_UNIVERSITIES_FAILURE = "GET_ALL_UNIVERSITIES_FAILURE";

export const GET_UNIVERSITY = "GET_UNIVERSITY";
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS";
export const GET_UNIVERSITY_FAILURE = "GET_UNIVERSITY_FAILURE";

export const ADD_UNIVERSITY = "ADD_UNIVERSITY";
export const ADD_UNIVERSITY_SUCCESS = "ADD_UNIVERSITY_SUCCESS";
export const ADD_UNIVERSITY_FAILURE = "ADD_UNIVERSITY_FAILURE";

export const EDIT_UNIVERSITY = "EDIT_UNIVERSITY";
export const EDIT_UNIVERSITY_SUCCESS = "EDIT_UNIVERSITY_SUCCESS";
export const EDIT_UNIVERSITY_FAILURE = "EDIT_UNIVERSITY_FAILURE";

export const DELETE_UNIVERSITY = "DELETE_UNIVERSITY";
export const DELETE_UNIVERSITY_SUCCESS = "DELETE_UNIVERSITY_SUCCESS";
export const DELETE_UNIVERSITY_FAILURE = "DELETE_UNIVERSITY_FAILURE";