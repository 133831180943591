import server from "./server";

export const getAllUniversitiesApi = async () => {
  const response = await server().get("/universities");

  return response.data;
}

export const addUniversityApi = async (data) => {
  const response = await server().post("/universities", data);

  return response.data;
}


export const getUniversityApi = async (id) => {
  const response = await server().get(`/universities/${id}`);

  return response.data;
}

export const editUniversityApi = async ({ id, data }) => {
  const response = await server().put(`/universities/${id}`, data);

  return response.data;
}

export const deleteUniversityApi = async (id) => {
  const response = await server().delete(`/universities/${id}`);

  return response.data;
}


