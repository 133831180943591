import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_ALL_SETTINGS,
  EDIT_MAIN_SETTINGS,
  EDIT_SOCIALS_SETTINGS,
  EDIT_MAIL_SETTINGS,
} from "./actionTypes";

import { getSettingsBySlug } from "../../helpers/functions"

import {
  getAllSettingsApi,
  editMainSettingsApi,
  editSocialsSettingsApi,
  editMailSettingsApi

} from "api/settings";

import {
  getAllSettingsSuccess,
  getAllSettingsFailure,
  editMainSettingsSuccess,
  editMainSettingsFailure,
  editSocialsSettingsSuccess,
  editSocialsSettingsFailure,
  editMailSettingsSuccess,
  editMailSettingsFailure

} from "./actions"


// api calls 
function* getAllSettingss() {
  try {
    const { data: { settings } } = yield call(getAllSettingsApi);

    const mainSettings = getSettingsBySlug(settings, "main")?.items;
    const socialSettings = getSettingsBySlug(settings, "social")?.items;
    const mailSettings = getSettingsBySlug(settings, "EmailContent")?.items;


    yield put(getAllSettingsSuccess({ settings, mainSettings, socialSettings, mailSettings }));
  } catch (error) {
    yield put(getAllSettingsFailure(error.response?.data?.message || error));
  }
}

function* editMainSettings({ payload }) {

  try {
    const { data: { settings } } = yield call(editMainSettingsApi, payload);

    yield put(editMainSettingsSuccess(settings));
  } catch (error) {
    yield put(editMainSettingsFailure(error.response?.data?.message || error));
  }
}

function* editSocialsSettings({ payload }) {


  try {
    const { data: { settings } } = yield call(editSocialsSettingsApi, payload);

    yield put(editSocialsSettingsSuccess(settings));
  } catch (error) {
    yield put(editSocialsSettingsFailure(error.response?.data?.message || error));
  }
}

function* editMailsSettings({ payload }) {
  try {
    const { data: { settings } } = yield call(editMailSettingsApi, payload);

    yield put(editMailSettingsSuccess(settings));
  } catch (error) {
    yield put(editMailSettingsFailure(error.response?.data?.message || error));
  }
}


// watchers
export function* watchgetAllSettings() {
  yield takeEvery(GET_ALL_SETTINGS, getAllSettingss);
}

export function* watchEditMainSettings() {
  yield takeEvery(EDIT_MAIN_SETTINGS, editMainSettings);
}

export function* watchEditSocialsSettings() {
  yield takeEvery(EDIT_SOCIALS_SETTINGS, editSocialsSettings);
}
export function* watchEditMailSettings() {
  yield takeEvery(EDIT_MAIL_SETTINGS, editMailsSettings);
}


function* settingsSaga() {
  yield all([fork(watchgetAllSettings)]);
  yield all([fork(watchEditMainSettings)]);
  yield all([fork(watchEditSocialsSettings)]);
  yield all([fork(watchEditMailSettings)]);
}

export default settingsSaga;
