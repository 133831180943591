import React from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PageContent from "./pageContent";
import HomeContent from "./HomeContent";


const EditPage = () => {

  const { id } = useParams();


  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4> {id === "home" ? <FormattedMessage id={"editHomePage"} /> : <FormattedMessage id={"editPage"} />}</h4>
          </div>
          <div className="card-body">
            {id === "home" ? <HomeContent id={id} /> : <PageContent id={id} />}

          </div>
        </div>
      </div>
    </>
  );
};

export default EditPage;
