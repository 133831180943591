import ControlArea from 'components/Layout/ControlArea';
// import BootyPagination from 'components/shared/Pagination';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllUsers } from 'store/actions';
import Icon from "assets/svgs/users.svg";
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';

const Users = () => {

  const { users, loading, error } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [searchParams] = useSearchParams();
  const [isDispatched, setIsDispatched] = useState(false)


  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllUsers(searchParams));
  }, [dispatch, searchParams]);

  // error 
  useEffect(() => {

    if (error && isDispatched) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className='users-wrap'>
        {checkPermissions(['users-store']) && (
          <ControlArea
            btnTxt={<FormattedMessage id={'addUser'} />}
            cardTxt={<FormattedMessage id={'users'} />}
            icon={Icon}
            url='/users/add'
          />
        )}
        {/* <div>{users?.users?.withSubscription}</div>
        <div>{users?.users?.withoutSubscription}</div> */}

        <Table data={users?.users?.users} loading={loading} />
        {/* <BootyPagination metaData={users?.metadata} /> */}
      </div>
    </>
  );
}

export default Users

